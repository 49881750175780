import config from "../../../config/constants/config";
import AnnouncementNotice from "../../../assests/images/notices.svg";
import AnnouncementNoticeCuramlife from "../../../assests/images/curamlife/notices.svg";
import { apiService } from "carynhealth-component-library";
import { GetNotifcationsAPIType, NotificationsList } from "./interfaces";
import messages from "../../../config/constants/messages";
import NoticeIcon from "../../../assests/images/notice-icon.svg";
import AnnouncementIcon from "../../../assests/images/notification_announcement_icon.svg";
import { ReturenTransString } from "../../../ReturenTransString";
import { AnnouncementInfo } from "./data";

const brand = config.APP_NAME;

export const data = {
  UHF: {
    image: AnnouncementNotice,
  },
  NETWELL: {
    image: AnnouncementNotice,
  },
  CURAMLIFE: {
    image: AnnouncementNoticeCuramlife,
  },
};

export const getNotifcationsData = data[config.APP_NAME as keyof typeof data];

export const getNotifcationsAPI = async () => {
  try {
    if(config.APP_NAME === config.BRAND_CURAMLIFE){
      const details: any = AnnouncementInfo[brand as keyof typeof AnnouncementInfo];
      return {
        notificationsList: getNoticesAnnouncements(details.notificationDetails),
        count:1,
        errorMessage: "",
      };
    }else{
    const request = {
      URL: config.URLS.baseTwillo + config.URLS.getAnnouncemetDetails,
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    const { notificationDetails, count } = apiResponse.response;
    return {
      notificationsList: getNoticesAnnouncements(notificationDetails),
      count,
      errorMessage: "",
    };
  }
    
  } catch (error: any) {
    console.log(error);
    return Promise.reject({
      errorMessage: ReturenTransString( 'SOMETHING_WRONG'),
    });
  }
};

const notifyImages = {
  NOTICE: NoticeIcon,
  AVISO:NoticeIcon,
  ANNOUNCEMENT: AnnouncementIcon,
};

function getNoticesAnnouncements(
  data: GetNotifcationsAPIType[]
): Array<NotificationsList> {
  return data
    .sort((a, b) => (a.id - b.id))
    .slice(0, 5)
    .sort((a: any, b: any) => {
      const aDate: any = new Date(a.createdDate);
      const bDate: any = new Date(b.createdDate);
      return bDate - aDate;
    })
    .map((list) => {
      return {
        image: notifyImages[list.listType as keyof typeof notifyImages],
        label: list.type,
        labelRight: dateformat(list.createdDate, "medium"),
        description: list.title,
        message: list.message,
        urlPath: list.urlPath,
        type: list.type,
        date: dateformat(list.createdDate, "long"),
      };
    });
}

const formatDate = new Intl.DateTimeFormat("en-us", {
  dateStyle: "short",
});

const formatDateMonth = new Intl.DateTimeFormat("en-US", {
  month: "numeric",
  day: "2-digit",
});

export function dateformat(
  date: string,
  type: "medium" | "full" | "long" | "short"
) {
  if (!date) return "";

  const enrollmentDate = new Date(date);

  const formatDateMonth = new Intl.DateTimeFormat("en-US", { dateStyle: type });
  return formatDateMonth.format(enrollmentDate);
}

function publishDateformat(date: string) {
  const enrollmentDate = new Date(date);
  return formatDate.format(enrollmentDate);
}
