import React, { useMemo, useState } from "react";
import {
  Dialog,
  EnrolledMembers,
  StorageHelpers,
  useCommonLoader,
} from "carynhealth-component-library";
import { styled } from "@mui/system";
import {
  getAgentInfo,
  getClientDetails,
  getEncryptData,
  getSourceID,
  openEnrollLogin,
} from "../handlers";
import config from "../../../config/constants/config";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import messages from "../../../config/constants/messages";
import titles from "../../../config/constants/titles";
import { ReturenTransString } from "../../../ReturenTransString";

const isNetwell = config.APP_NAME === config.BRAND_NETWELL;

const EnrolledMemberStyle = styled("section")(({ theme }) => {
  return {
    ".MuiListItem-root:hover": {
      background: "#fff !important",
      color: "initial !important",
    },
    "&.UHF-Discount": {
      ".MuiTableBody-root td:nth-child(2)": {
        color: "rgb(95, 33, 97)",
      },
      
        ".Mui-disabled": {
          color: "#ccc !important",
          borderColor: "#ccc !important",
        },
    
    
    },
  };
});

const headings = [
  { id: "member-name", label: ReturenTransString('memberName') },
  { id: "member-id", label: ReturenTransString('memberId')},
  { id: "relation-ship", label: ReturenTransString('relationship')},
  { id: "birth-date", label: ReturenTransString('birthDate')},
  { id: "birth-gender", label: ReturenTransString('birthGender')},
  { id: "effective-date", label: ReturenTransString('effectiveDate')},
];

const EnrolledMember: React.FC<{
  data: any;
  agentDetails: any;
  clientDetails: any;
  memberDetails: any;
}> = (props) => {
  const { setLoading, unSetLoading } = useCommonLoader();
  const [popup, setPopup] = useState(false);
  const enrolledDataMember = useMemo(() => {
    const enrolledMember = props.data;
    return (
      enrolledMember
        ?.filter((item: any) => item.status == "AC")
        ?.map((service: any, index: number) => ({
          "member-name": `Adult ${index + 1} ${service.firstName} ${
            service.lastName
          }`,
          "member-id": service.memberId,
          "relation-ship":
            service.relationshipCd === "18"
              ? "Self"
              : service.relationshipCd === "19"
              ? "Child"
              : "Spouse",
          "birth-date": service.dateOfBirth,
          "birth-gender": service.gender == "F" ? "Female" : "Male",
          "effective-date": service?.benefits?.map((dt: any) =>
            new Date(dt?.benefitBegin || "")
              .toDateString()
              .split(" ")
              .splice(1)
              .join(" ")
          ),
        })) || []
    );
  }, [props]);

  const disableUHF_updatehousehold = () => {
    if (isNetwell) return false;

    let isClientExist =
      [6548, 4367, 5540, 4376, 5541, 4377].includes(
        Number(StorageHelpers.getLocalStorage("CLIENT_ID"))
      ) || StorageHelpers.getLocalStorage("SOURCE") === "NEO";
    return isClientExist;
  };

  const updateHouseHold = async () => {
    setLoading();
    if (config.APP_NAME === config.BRAND_NETWELL) {
      if (getLocalStorage("SOURCE") === "Optimed") {
        setPopup(true);
        unSetLoading();
      } else {
        try {
          const { errorModal, ...reqEncrypt } = openEnrollLogin(
            {
              clientId: props.clientDetails?.clientName || "",
              associationId: props.memberDetails?.associationId || "",
              brokerId: props.agentDetails?.brokerId || "",
              fromMember: true,
              subID: props.memberDetails?.subID || "",
              isEditCensus: false,
            },
            true
          );
          unSetLoading();
          if (!errorModal) await getEncryptData(reqEncrypt || {});
          return;
        } catch (error) {
          unSetLoading();
          console.log("updateHouseHold error:: ", error);
        }
      }
    }

    if (disableUHF_updatehousehold()) {
      unSetLoading();
      console.log("Not enable for this client");
      return false;
    }
    const client_response = await getClientDetails();
    const { memberIdSource } = (await getSourceID()) || {
      memberIdSource: "",
    };
    const agentInfo = await getAgentInfo(memberIdSource);
    const { errorModal, ...reqEncrypt } = openEnrollLogin(
      { ...client_response, ...agentInfo, subID: props.memberDetails?.subID },
      true
    );
    unSetLoading();
    if (!errorModal) await getEncryptData(reqEncrypt || {});
  };
  const handleClick = (data: any) => {
    setPopup(false)
  };
  return (
    <>
      <EnrolledMemberStyle
        id="pi-enrolledMember"
        className={`${config.APP_NAME}-Discount`}
      >
        <EnrolledMembers
          headings={headings}
          list={enrolledDataMember}
          updateHouseHold={updateHouseHold}
          updateHouseHoldDisable={disableUHF_updatehousehold()}
        />
      </EnrolledMemberStyle>
      <Dialog
        brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
        show={popup}
        body={() => <p style={{whiteSpace:'pre',fontWeight: 500}}>{messages.netwell.UpdateHousehold}</p>}
        CloseIcon={true}
        buttons={[{
          label: "OK",
          size: "large",
          variant: "text",
          type: "button",
          className: "OK",
        } as CustomButtonProps]}
        title={titles.Message}
        handleCallback={(data: any) => {
          handleClick(data);
        }}
      />
    </>
  );
};

export default EnrolledMember;
