import React from "react";
import titles from "../../../../config/constants/titles";
import { useTranslation } from "react-i18next";

const AgentDetails = ({ agentInfo }: any) => {
  const { t } = useTranslation();
  if (!agentInfo?.agentname) return null;
  return (
    <>
      <div className="contactinfo_label">
        {/* {titles.YOUR_AGENT_DETAILS} */}
        {t('YOUR_AGENT_DETAILS')}
        </div>
      <div className="contactinfo_Value">{agentInfo?.agentname}</div>
      <div className="contactinfo_Value">{agentInfo?.agentno}</div>
      <div className="contactinfo_Value">{agentInfo?.agentemail}</div>
    </>
  );
};

export default React.memo(AgentDetails);
