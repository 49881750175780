import React, { useCallback, useContext } from "react";
import { HeaderTabsComponent } from "carynhealth-component-library";
import title from "../../../config/constants/titles";
import Style from "../style";
import Text from "../../../config/constants/messages";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import { RightDrawer } from "../RightDrawer";
import {
  getIdCardDetails,
  getPlainIData,
  getRxSimpleShareData,
} from "../handler";
import { AuthContext } from "../../../context/AuthenticateContext";
import config from "../../../config/constants/config";
import { PopupContant } from "../PopupContant";
import routePaths from "../../../config/constants/routePaths";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];
const isCuramlife = config.APP_NAME === config.BRAND_CURAMLIFE;

export const HeaderTab = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [yellowPopup, setYellowPopup] = React.useState(false);
  let [memberSSN, setMemberSSN] = React.useState("");
  const [planId, setPlanId] = React.useState("");
  const [pharmaShow, setPharmaShow] = React.useState(true);
  const {t} = useTranslation();

  React.useEffect(() => {
    !isCuramlife && setPopupValue();
  }, [auth.authenticatedInfo.isAuthenticated]);

  const setPopupValue = async () => {
    const email: string | any = getLocalStorage("userMail");
    await getIdCardDetails(email).then((res) => {
      const planId = res.apiResponse.memberIdCardList[0].planId;
      setLocalStorage("planId", planId);
      setPlanId(planId);
      if (!getLocalStorage("popupShow1")) {
        if (getPlainIData(planId)) {
          setLocalStorage("popupShow1", false);
        } else {
          setLocalStorage("popupShow1", true);
        }
      }
      setYellowPopup(getLocalStorage("popupShow1") == "true" ? true : false);

      setMemberSSN(res.apiResponse.memberIdCardList[0].memberSSN);
    });
    getSimpleShareData();
  };
  const getSimpleShareData = () => {
    let data = {
      memberSSN: memberSSN,
      type: "family",
    };
    getRxSimpleShareData(data).then((res) => {
      if (res.apiResponse && Array.isArray(res.apiResponse)) {
        res.apiResponse.map((value) => {
          if (value.addon === "UHS RxSimpleShare" && value.status === "AC") {
            setPharmaShow(true);
          }
        });
      }
    });
  };
  if (
    !auth.authenticatedInfo.isAuthenticated ||
    config.APP_NAME == "NETWELL" ||
    location.pathname !== routes.dashboard
  )
    return null;
  return (
    <HeaderTabsComponent
      data={[
        {
          text:t('employeeServices'),
        },
        {
          text: t('healthWellness'),
          Component: (
            <RightDrawer
              pharmaShow={pharmaShow}
              planID={planId}
              headerTaps={true}
            />
          ),
          PopupComponent:
            location.pathname === routes.dashboard && yellowPopup
              ? PopupContant
              : null,
        },
      ]}
      callBack={(data) => console.log(data)}
    />
  );
};
