import React, { useState } from "react";
import { Modal, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

// Define your video URL

  const languageLinks : any = {
    en: "https://player.vimeo.com/video/884101147?h=7ae4c9760c&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479",
    es: 'https://player.vimeo.com/video/899348464?h=409a327c27&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
    // Add more languages and their respective links as needed
  };

function VideoPopup(props:any) {
  const [open, setOpen] = useState(true);
  const selectedLang :any = getLocalStorage("Language");
  
  const handleClose = () => {
    setOpen(false);
    props.isOpen();
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80vw",
            maxWidth: "800px",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          {/* Close button */}
          <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

          {/* Video player */}
          <iframe
            title="Video"
            width="100%"
            height="400"
            src={languageLinks[selectedLang]}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </div>
  );
}

export default VideoPopup;
