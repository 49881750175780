import {
  getPlainIData,
  getRxSimpleShareData,
} from "../../RightTopMemberTabs/handler";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getMemberHealthIdCard } from "../../ProgramInformation/handlers";
import config from "../../../config/constants/config";

interface MenuRquest {
  planId: string;
  networkName: string;
  clientId: number | string;
  DataArray: object[];
}

const isCuramlife = config.APP_NAME === config.BRAND_CURAMLIFE;

export const getMenuData = async (value: MenuRquest) => {
  let sortedArr = value.DataArray;
  setLocalStorage("PlanIdExist", getPlainIData(value.planId));
  if (getPlainIData(value.planId)) {
    sortedArr = sortedArr.filter((x: any) => x.displayType !== "planId");
  }
  if (!isCuramlife) {
    let isPharama = await getSimpleShareData();
    if (!isPharama) {
      sortedArr = sortedArr.filter((x: any) => x.displayType !== "byPharma");
    }
  }
  return sortedArr;
};

const getSimpleShareData = async () => {
  let isPharama = false;
  isPharama = await getMemberHealthIdCard()
    .then(async (res) => {
      if (res) {
        let data = {
          memberSSN: getLocalStorage("memberSSN"),
          type: "family",
        };
        isPharama = await getRxSimpleShareData(data).then((res) => {
          if (res.apiResponse && Array.isArray(res.apiResponse)) {
            let checkPharma = res.apiResponse.find(
              (x) => x.addon === "UHS RxSimpleShare" && x.status === "AC"
            );
            if (checkPharma) {
              return true;
            }
          }
          return false;
        });
        return isPharama;
      }
      return false;
    })
    .catch(() => {
      return false;
    });
  return isPharama;
};
