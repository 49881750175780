import { useEffect, useState } from "react";
import { TranslationString } from "./TranslationString";
import i18n from "./i18n/i18n";
import { useTranslation } from "react-i18next";

export function ReturenTransString(text1: string){
  let string = <TranslationString text={text1} /> as unknown as string
  return string;
};

 