import { t } from "i18next";
import { TranslationString } from "../../../TranslationString";

export const tilesInfo = {
  UHF: {
    MembershipId: {
      to: "",
      description: "ID Cards",
      image: require("../../../assests/images/uhf/membership-card.svg").default,
      imgAlt: "ID Cards",
      title:<TranslationString text='idCards'/>,
      list: [
        {
          label: <TranslationString text='MembershipCard'/>,
          text:'Membership Card',
          to: "",
        },
        {
          label:<TranslationString text='HealthTools'/>,
          text:'Health Tools'
        },
      ],
    },
    ProgramInformation: {
      to: "/ProgramInformation",
      description: "",
      image: require("../../../assests/images/uhf/program-info.svg").default,
      imgAlt: "Program Information",
      title: <TranslationString text='ProgramInformation'/>,
      list: [],
    },
    MyNeeds: {
      to: "/MyNeeds",
      description: "",
      image: require("../../../assests/images/uhf/my-needs.svg").default,
      imgAlt: "My Needs",
      title: <TranslationString text='myNeeds'/>,
      list: [],
    },
    FindProvider: {
      to: "",
      description: "",
      image: require("../../../assests/images/find-a-provider.svg").default,
      imgAlt: "Find a Provider",
      title: <TranslationString text='FindAProvider'/>,
      list: [],
      href: "http://findprovider.universalhealthfellowship.org/",
    },
    HealthQuestionnaire: {
      to: "/Medical",
      description: "",
      image: require("../../../assests/images/health-questionare.svg").default,
      imgAlt: "Health Questionnaire",
      title: <TranslationString text='healthQueTitle'/>,
      list: [],
    },
    FAQs: {
      to: "/Medical",
      description: "",
      image: require("../../../assests/images/faq.svg").default,
      imgAlt: "FAQs",
      title: <TranslationString text='FAQs'/>,
      list: [],
      href: "https://www.universalhealthfellowship.org/FAQs/",
    },
    MyTransaction: {
      to: "/Transaction",
      description: "",
      image: require("../../../assests/images/my-transactions.svg").default,
      imgAlt: "My Transactions",
      title:  <TranslationString text='myTransaction'/>,
      list: [],
    },
  },
  CURAMLIFE: {
    MembershipId: {
      to: "",
      description: "ID Cards",
      image: require("../../../assests/images/curamlife/membership.svg").default,
      imgAlt: "ID Cards",
      title:<TranslationString text='idCards'/>,
      list: [
        {
          label: <TranslationString text='EmployeeCard'/>,
          text:'CuramLife IDCard',
          to: "",
        },
      ],
    },
    ProgramInformation: {
      to: "/ProgramInformation",
      description: "",
      image: require("../../../assests/images/curamlife/program-info.svg").default,
      imgAlt: "Program Information",
      title: <TranslationString text='ProgramInformation'/>,
      list: [],
    },
    // MyNeeds: {
    //   to: "/MyNeeds",
    //   description: "",
    //   image: require("../../../assests/images/uhf/my-needs.svg").default,
    //   imgAlt: "My Needs",
    //   title: <TranslationString text='myNeeds'/>,
    //   list: [],
    // },
    // FindProvider: {
    //   to: "",
    //   description: "",
    //   image: require("../../../assests/images/find-a-provider.svg").default,
    //   imgAlt: "Find a Provider",
    //   title: <TranslationString text='FindAProvider'/>,
    //   list: [],
    //   href: "http://findprovider.universalhealthfellowship.org/",
    // },
    FAQs: {
      to: "/Medical",
      description: "",
      image: require("../../../assests/images/curamlife/faq.svg").default,
      imgAlt: "FAQs",
      title: <TranslationString text='FAQs'/>,
      list: [],
      href: "https://curamlife-prod.s3.us-east-2.amazonaws.com/CuramLife_Employee_FAQs.pdf",
    },
  },
  NETWELL: {
    MembershipId: {
      to: "",
      description: "ID Cards",
      image: require("../../../assests/images/netwell/membership.png"),
      imgAlt: "ID Cards",
      title: "ID Cards",
      list: [
        {
          label: "Membership Card",
          text:'Membership Card',
          to: "",
        },
      ],
    },
    ProgramInformation: {
      to: "/ProgramInformation",
      description: "",
      image: require("../../../assests/images/netwell/program-info.svg")
        .default,
      imgAlt: "Program Information",
      title: "Program Information",
      list: [],
    },
    MyNeeds: {
      to: "/MyNeeds",
      description: "",
      image: require("../../../assests/images/netwell/my-needs.svg").default,
      imgAlt: "Share Requests",
      title: "Share Requests",
      list: [],
    },
    MyTransaction: {
      to: "/Transaction",
      description: "",
      image: require("../../../assests/images/my-transactions.svg").default,
      imgAlt: "My Transactions",
      title: "My Transactions",
      list: [],
    },
    FindProvider: {
      to: "",
      description: "",
      image: require("../../../assests/images/netwell/find-a-provider.svg")
        .default,
      imgAlt: "Find a Provider",
      title: "Find a Provider",
      list: [],
      href: "https://www.multiplan.com/webcenter/portal/ProviderSearch?ProviderSearchConfig=ClientSite&SiteUrlSuffix=netwell.network",
    },
    Hipaa: {
      to: "/Hipaa",
      description: "",
      image: require("../../../assests/images/netwell/hippa.svg").default,
      imgAlt: "HIPAA Privacy Authorization",
      title: "HIPAA Privacy Authorization",
      list: [],
    },
  },
};
