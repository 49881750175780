// Libraries
import React, { Suspense, useEffect, useContext, useState } from "react";

// Internal Components
import DashboardHeader from "../../components/Dashboard/Header";
import MyNotifications from "../../components/Dashboard/MyNotification";
import Documents from "../../components/Dashboard/Documents";
import ContactInformation from "../../components/Dashboard/Contact-Information";
import ProductTiles from "../../components/Dashboard/ProductTile";
import config from "../../config/constants/config";
import { DashboardStyleWrapper } from "./style/dashboardStyle";
import { HealthinfoPopup } from "../../components/Dashboard/HealthinfoPopup";
import { getLocalStorage, setLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getPlainIData } from "../../components/RightTopMemberTabs/handler";
import { AuthContext } from "../../context/AuthenticateContext";
import { useTranslation } from "react-i18next";
import { GetEsignData, getMemberDetailsById } from "./handler";
import { Dialog } from "carynhealth-component-library";
import TermsConditions from "./TermsConditions";
import VideoPopup from "./VideoPopup";
const UHFCURAM = React.lazy(
  () => import("../../components/Dashboard/UHFCURAM")
);
const Announcements = React.lazy(
  () => import("../../components/Dashboard/Announcements")
);
const brand = config.APP_NAME;

const Dashboard = () => {
  const auth = useContext(AuthContext);
  const [RenderComponents, setRenderComponents] = useState([]);
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    // if (brand === config.BRAND_CURAMLIFE && !getLocalStorage('e-sign')) {
    //   getEsign();
    // } 
    getBigCards();
  }, [auth.authenticatedInfo.isAuthenticated]);

  const getEsign = async () =>{
  await GetEsignData(localStorage.getItem("member-id"))
   .then((res) => {
    if (res.apiResponse.response && res.apiResponse.code == 200) {
      setPopup(false);
    } else {
      setPopup(true);
    }
  });
  }

  async function getBigCards() {
    let sortedComponant: any = [
      MyNotifications,
      Documents,
      Announcements,
      ContactInformation,
      ProductTiles,
    ].filter(Boolean);
    const planId: any = getLocalStorage("planId");
    let isPlaid = await getPlainIData(planId);

    if (!isPlaid && brand == config.BRAND_UHF) {
      sortedComponant = [
        MyNotifications,
        Documents,
        UHFCURAM,
        ContactInformation,
        Announcements,
        ProductTiles,
      ].filter(Boolean);
    }else if(brand == config.BRAND_CURAMLIFE){
      sortedComponant = [
        MyNotifications,
        Documents,
      Announcements,
      ContactInformation,
      ProductTiles,
      ].filter(Boolean);
    }
    setRenderComponents(sortedComponant);
  }
  const handleOpen = async () => {
    setOpen(false);
    await getMemberDetails();
  };
  const getMemberDetails = async () => {
    const memberId = getLocalStorage("member-id") || 'CURM100061';
    let data = await getMemberDetailsById(memberId);
    console.log(data);
    setLocalStorage("curamLifeMemberPackages", JSON.stringify(data.curamLifeMemberPackages) );
    setLocalStorage('fullName',`${data.firstName} ${data.lastName}`)
    setLocalStorage('memebr-details',JSON.stringify(data));
    setLocalStorage('e-sign',true);
  };

  const handleDisable = () => {
    setPopup(false);
    setOpen(true);
  };
  
  return (
    <DashboardStyleWrapper id="dashboard">
      <HealthinfoPopup />
      <Dialog
        show={popup}
        body={() => <TermsConditions closePopup={handleDisable} />}
        title={t("TermsAndConditions")}
        titleBackgroundColor={{ backgroundColor: "rgb(51, 175, 176)" }}
        buttons={[]}
        handleCallback={(data: any) => {
          setPopup(false);
          setOpen(true);
        }}
      />
      {open && <VideoPopup isOpen={handleOpen} />}
      <DashboardHeader />
      <div id="dashboard-notifications" className={`notifications-${brand}`}>
        {RenderComponents.map((Component: any) => (
          <Suspense fallback={<div>loading...</div>}>
            <Component />
          </Suspense>
        ))}
      </div>
    </DashboardStyleWrapper>
  );
};

export default Dashboard;
