import React, { useEffect } from "react";
import { Grid, MobileStepper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import Sample from "../TextField/sampleTextField";
import { LifeStyleQuestion } from "./LifeStyleQuestion";
import { ComponentWrapper } from "./style";
import { HealthQuestion } from "./HealthQuestion";
import { CurrentHealthcare } from "./CurrentHealthcare";
import { PrivacyPolicy } from "./PrivacyPolicy";
import moment from "moment";
import { Dialog } from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import messages from "../../config/constants/messages";
import { useTranslation } from "react-i18next";

export interface ComponentProps {
  onClick: (data: any) => void;
  familyData: familyData;
  instData: any;
  questionList: object[];
  age?: any;
  viewMode?: boolean;
  authorize?: boolean;
  privacyPolicy?: boolean;
  fullName: string;
  Index: number;
  disableNext: (data: boolean) => void;
  disableFinsh?: (data: boolean, count: number | 0) => void;
  ViewMode: boolean;
}

export interface familyData {
  id: number;
  subscriberUniqueId: string;
  memberUniqueId: string;
  firstName: string;
  lastName: string;
  dob: number;
  gender: string;
  completionStatus: number;
  lastUpdatedDate: string;
  authorize: boolean;
  privacyPolicy: boolean;
  email: string;
  fullName: string;
}

export const PersonalQues: React.FunctionComponent<ComponentProps> = (
  props: ComponentProps
) => {
  const { ...rest } = props;
  const [date, setDate] = React.useState<Dayjs | any>(props?.familyData?.dob);
  const [familyData, setFamilyDate] = React.useState(props?.familyData);
  const [showPopup, setShowPopup] = React.useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    var newDate = moment(Number(props?.familyData?.dob)).format("MM/DD/yyyy");
    console.log("date", newDate);
    setDate(newDate);
  }, []);

  const updateMail = (val: string) => {
    familyData.email = val;
    setFamilyDate({ ...familyData });
    props.familyData.email = val;
  };

  return (
    <ComponentWrapper {...rest}>
      <text className="username">{` ${familyData.firstName} ${familyData.lastName}`}</text>
      <MobileStepper
        variant="progress"
        steps={5}
        activeStep={props.Index}
        className="QuesProgressBar"
        sx={{
          maxWidth: 400,
          flexGrow: 1,
          ".MuiLinearProgress-bar": { backgroundColor: "#fec465" },
        }}
        backButton={undefined}
        nextButton={undefined}
      />
      {props.Index === 0 ? (
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <div className="custom-subtitle">
              {t(props?.instData[props.Index]?.title)}
            </div>
            <Grid container spacing={5}>
              <Grid item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Birth Date *"
                    disabled={true}
                    value={date}
                    onChange={(newValue: any) => {
                      setDate(newValue);
                    }}
                    toolbarFormat="MM/dd/yyyy"
                    toolbarTitle=""
                    className="datePicker"
                    renderInput={(params: any) => (
                      <TextField variant="filled" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item className="SelectGender" xs={5}>
                <Sample
                  label={"Birth Gender"}
                  value={props?.familyData?.gender == "M" ? "MALE" : "FEMALE"}
                  disable={true}
                  reqFlag={true}
                  fieldType={"select"}
                  helperMsg={""}
                  List={["MALE", "FEMALE"]}
                  // parentDetails={{ label: 'adultSelected' }}
                  required={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className="EmailInput">
              <Grid item xs={10}>
                <Sample
                  setChild={(val) => updateMail(val)}
                  name={"adultEmail"}
                  isValidFullName={true}
                  reqFlag={
                    familyData?.memberUniqueId == familyData?.subscriberUniqueId
                  }
                  label={"Email"}
                  value={familyData?.email}
                  disable={
                    familyData?.memberUniqueId ==
                      familyData?.subscriberUniqueId || props.ViewMode
                  }
                  length={120}
                  fieldType={"email"}
                  errMsg={"Enter valid email"}
                  helperMsg={""}
                ></Sample>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid item xs={10}>
              <div className="custom-subtitle" style={{ marginTop: "10px" }}>
                {" "}
               {t('whyNeedThis')}
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: t(props.instData[props.Index].description),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : props.Index === 1 ? (
        <LifeStyleQuestion
          instData={props.instData[props.Index]}
          questionList={props.questionList}
          disableNext={props.disableNext}
          ViewMode={props.ViewMode}
        />
      ) : props.Index === 2 ? (
        <HealthQuestion
          instData={props.instData[props.Index]}
          questionList={props.questionList}
          disableNext={props.disableNext}
          ViewMode={props.ViewMode}
        />
      ) : props.Index === 3 ? (
        <CurrentHealthcare
          instData={props.instData[props.Index]}
          questionList={props.questionList}
          disableNext={props.disableNext}
          ViewMode={props.ViewMode}
        />
      ) : (
        <PrivacyPolicy
          instData={props.instData[props.Index]}
          questionList={props.questionList}
          disableFinsh={props.disableFinsh}
          disableNext={props.disableNext}
          ViewMode={props.ViewMode}
          familyData={props.familyData}
        />
      )}
    </ComponentWrapper>
  );
};
