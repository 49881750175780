import { styled } from "@mui/system";
import { Dialog } from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import { useEffect, useState } from "react";
import { getHealthqnInfo } from "./handlers";
import Text from "../../../config/constants/messages";
import { useCommonLoader } from "carynhealth-component-library";
import { useNavigate } from "react-router-dom";
import { getRoutePaths } from "../../../config/constants/routePaths";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import config from "../../../config/constants/config";
import { useTranslation } from "react-i18next";

const StyleWrapper = styled("div")(({ theme }) => ({
  ".MuiDialogActions-root": {
    justifyContent: "center",
  },
}));

export const Components = {
  NETWELL: (props: any) => {
    return <></>;
  },
  CURAMLIFE: (props: any) => {
    return <></>;
  },
  UHF: (props: any) => {
    const [msgBody, setMsgBody] = useState("");
    const [isHippaSent, setIsHippaSent] = useState<boolean>(false);
    const { setLoading, unSetLoading } = useCommonLoader();
    const navigate = useNavigate();
    const {t} = useTranslation()
    useEffect(() => {
      getHelthQuesModuleInfo();
    }, []);
    const getHelthQuesModuleInfo = () => {
      if (!getLocalStorage("skip")) {
        getHealthqnInfo(props.id).then((res: any) => {
          setLoading();
          if (res.apiResponse.response && res.apiResponse.response.length > 0) {
            let counter = 0;
            res.apiResponse.response.forEach((item: any) => {
              counter = item.completionStatus == 4 ? counter + 1 : counter;
            });
            if (counter !== res.apiResponse.response.length) {
              setLocalStorage("skip", "false");
              setIsHippaSent(true);
              let questionaryIncomplete = t('questionaryIncomplete');
              setMsgBody(questionaryIncomplete);
            }
          }
          unSetLoading();
        });
      }
    };
    const handleClick = (data: any) => {
      if (data.className === "YES") {
        HelthQuesOk();
      } else {
        HelthQuesSkip();
      }
    };

    const HelthQuesSkip = () => {
      setLocalStorage("skip", "true");
      setIsHippaSent(false);
    };

    const HelthQuesOk = () => {
      setIsHippaSent(false);
      navigate(getRoutePaths.healthQuestionary);
    };

    return (
      <StyleWrapper>
        {isHippaSent ? (
          <Dialog
            brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
            show={true}
            body={() => <p>{msgBody}</p>}
            CloseIcon={true}
            buttons={[
              {
                label: t('yes'),
                size: "small",
                variant: "text",
                type: "button",
                className: "YES",
              } as CustomButtonProps,
              {
                label: t('skip'),
                size: "small",
                variant: "text",
                type: "button",
                className: "SKIP",
              } as CustomButtonProps,
            ]}
            titleBackgroundColor={{ backgroundColor: "rgb(51, 175, 176)" }}
            handleCallback={(data: any) => {
              handleClick(data);
            }}
          />
        ) : (
          <></>
        )}
      </StyleWrapper>
    );
  },
};
