import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BackArrow, ProductCardWrapper } from "carynhealth-component-library";
import { ProductTileInfo } from "./data";
import { styled } from "@mui/material";
import config from "../../config/constants/config";
import { getRoutePaths } from "../../config/constants/routePaths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
const isCuramLife = config.APP_NAME === config.BRAND_CURAMLIFE;

const ProgramStyle = styled("section")(({ theme }) => {
  return {
    ".product-wrapper_footer-chat": {
      display: isNetwell ? "none" : "block",
    },
    "&#program_information": {
      maxWidth: "90%",
      margin: "0px auto",
      paddingTop: 10,
      paddingBottom: 50,
      ".MuiTabs-flexContainer": {
        justifyContent: "start",
      },
      ".MuiCardContent-root .MuiButtonBase-root": {
        textTransform: "capitalize",
        minWidth: "13%",
        background: "transparent !important",
        color: "#162242",
      },
      ".MuiTabs-indicator": {
        backgroundColor: "#162242",
      },
      ".MuiTabs-scroller": {
        borderBottom: "1px solid #999999",
      },
      ".new_back_page": {
        color: isNetwell ? "var(--NETWELL-btn-color)" : "var(--UHF-theme-color)"
      },
      /* List should be visible good so adding css for 1024px below screens */
      
      [theme.breakpoints.down(1024)]: {
        ".MuiButtonBase-root": {
          width: "auto !important"
        },
        ".MuiTableRow-root th": {
          "&:nth-of-type(1)":{
            minWidth: "25vw"
          },
          "&:nth-of-type(2)":{
            minWidth: "17vw"
          },
          "&:nth-of-type(4)":{
            minWidth: "18vw"
          },
          "&:nth-of-type(5)":{
            minWidth: "20vw"
          },
          "&:nth-of-type(6)":{
            minWidth: "20vw"
          }
        }
      },

      [theme.breakpoints.down(426)]: {
        "&#program_information": {
          maxWidth: "100%",
          margin: 0,
          paddingTop: 0,
          paddingBottom:0,
            ".MuiCardContent-root":{
              padding:0
            }
          
        },
        '.MuiTabs-root .MuiTabScrollButton-root':{
          minWidth: "10% !important"
        },
        ".MuiButtonBase-root": {
          minWidth: "10% !important"
        },
        ".MuiTableRow-root th": {
          "&:not(:nth-of-type(3))":{
            minWidth: "32vw"
          },
          "&:nth-of-type(1)":{
            minWidth: "40vw"
          }
        }
      },
      [theme.breakpoints.down(321)]: {
        ".MuiTableRow-root th": {
          "&:not(:nth-of-type(3))":{
            minWidth: "42vw"
          },
          "&:nth-of-type(1)":{
            minWidth: "50vw"
          }
        }
      },

      [theme.breakpoints.down(768)]: {
        ".product-wrapper_title": {
          display: "none",
        },
        ".new_back_page": {
          display: "none",
        },
      },
    },
  };
});

const ProgramInformationWrapper = () => {
  const navigate = useNavigate();
  const {t,i18n } = useTranslation();
  const [productinfoData,setProductinfoData] = useState(ProductTileInfo);
  const navigateToDashboard = useCallback(() => {
    navigate(getRoutePaths.dashboard)
  }, [])

  useEffect(()=>{
    let temp = {...ProductTileInfo};
    temp.footerText = t(temp.footerText)
    if(isCuramLife){
      const dataJSON = getLocalStorage('memebr-details') || '';
      const data = JSON.parse(dataJSON);
      if(isEmpty(data.curamLifeAddons)){
        temp.tabs = temp.tabs.filter((obj) => obj.id !== 'add-ons');
      }
    }
    setProductinfoData({...temp})
  },[i18n.language])
  
  function isEmpty(objOrArr:any) {
    if (Array.isArray(objOrArr)) {
      return objOrArr.length === 0;
    } 
    return false;
  }

  return (
    <ProgramStyle id="program_information">
      <BackArrow
            brand={config.APP_NAME}
            onCallback={navigateToDashboard}
          />

      <ProductCardWrapper
        {...productinfoData}
        openChatMethod={window.pureChat}
        
      />
    </ProgramStyle>
  );
};

export default ProgramInformationWrapper;
