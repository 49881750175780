import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AccordianTransaction,
  CardWrapper,
} from "carynhealth-component-library";
import { ProductTileInfo, TransactionAccordian } from "./data";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material";
import config from "../../config/constants/config";
import { useMediaQuery, useTheme } from "@mui/material";
import StyleWrapper from "./Style";
import { useTranslation } from "react-i18next";

const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
declare global {
  interface Window {
    pureChat: any;
  }
}

const StyleBackWrapper = styled(Link)(() => {
  return {
    color: isNetwell ? "var(--NETWELL-btn-color)" : "var(--UHF-theme-color)",
    textDecoration: "none",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: 1.14,
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    svg: {
      marginRight: 5,
    }
  };
});

const TransactionStyle = styled("section")(({ theme }) => {
  return {
    ".MuiCardActions-root.product-wrapper_footer": {
      padding: 20
    },
    "&#program_information": {
      maxWidth: "90%",
      margin: "0px auto",
      paddingTop: 10,
      paddingBottom: 50,
      ".MuiTabs-flexContainer": {
        justifyContent: "space-around",
      },
      ".MuiCardContent-root .MuiButtonBase-root": {
        minWidth: "13%",
        background: "#fff !important",
        color: "#162242",
      },
      ".MuiTabs-indicator": {
        backgroundColor: "#162242",
      },
      ".MuiTabs-scroller": {
        borderBottom: "1px solid #999999",
      },
    },
  };
});

const MyTransaction = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const {t} = useTranslation();
  const [productinfoData,setProductinfoData] = useState(ProductTileInfo);
  useEffect(()=>{
    let temp = ProductTileInfo;
    temp.footerText = t(temp.footerText)
    setProductinfoData({...temp})
  },[])
  return (
    <StyleWrapper>
      {isSmallScreen ? (
              <div className="webDisplay">
              <TransactionStyle id="program_information">
                <StyleBackWrapper to="/dashboard" className="new_back_page">
                  <ArrowBackIcon />
                  {t('BACK')}
                </StyleBackWrapper>
                <CardWrapper
                  {...productinfoData}
                  openChatMethod={window.pureChat}
                />
              </TransactionStyle>
            </div>
      ) : (
        <div className="mobileDisplay">
        <AccordianTransaction {...TransactionAccordian} />
      </div>
      )}
    </StyleWrapper>
  );
};

export default MyTransaction;
