const config = Object.freeze({
  BRAND_NETWELL: "NETWELL",
  BRAND_UHF: "UHF",
  BRAND_CURAMLIFE: "CURAMLIFE",
  APP_NAME: process.env.REACT_APP_ENV_NAME || "",
  ENV_SETUP: process.env.REACT_APP_ENV_SETUP || "",
  MODE: process.env.MODE?.toLowerCase() === "dev" ? "dev" : "prod",
  DEFAULT_VALUES: {
    NETWELL_TILES_ID: process.env.REACT_APP_PRODUCT_TILE_ID || "",
  },
  URLS: {
    baseTwillo: process.env.REACT_APP_TWILLO_V8 || "",
    baseAPI: process.env.REACT_APP_API_BASE_URL || "",
    baseV7API: process.env.REACT_APP_API_V7_BASE_URL || "",

    getClientBaseApi: process.env.REACT_APP_CLIENT_ID_DETAILS || "",
    loginEnrollment: process.env.REACT_APP_LOGIN_ENROLLMENT || "",

    getBaseNotificationURL: process.env.REACT_APP_MY_NOTIFICATION || "",
    dashboardPlanInfo: process.env.REACT_APP_PLAN_INFO || "",
    getAnnouncemetDetails: process.env.REACT_APP_GET_NOTIFICATIONS || "",
    memberPortal: process.env.REACT_APP_MEMBER_PORTAL || "",
    getEnrolledMemberData: process.env.REACT_APP_CSR_GET_TEMP_EMP_ID || "",
    getHippaAuth: process.env.REACT_APP_GET_MEMBER_PORTAL_HIPPA || "",
    memberPortalMainURL: process.env.REACT_APP_MEMBER_PORTAL_MAIN_URL || "",

    getUHFPrgrmInfo: process.env.REACT_APP_GET_UHF_PROGRAM_INFO || "",
    getNetwellPrgrmInfo: process.env.REACT_APP_GET_NETWELL_PROGRAM_INFO || "",
    getEnrollmentClient: process.env.REACT_APP_ENROLLMENT_GETCLINET || "",
    getSoureId: process.env.REACT_APP_GET_SOURCE_ID || "",
    getAgentDetails: process.env.REACT_APP_GET_AGENT_DETAILS || "",
    getEnrollMemberInfoById:
      process.env.REACT_APP_GET_ENROLL_MEMBER_INFO_BY_ID || "",
    encryptData: process.env.REACT_APP_ENCRYPT_DATA || "",
    getMemberCard: process.env.REACT_APP_GET_CARD || "",
    hippaAuthorization: process.env.REACT_APP_HIPPA_AUTHORIZATION || "",
    sendHippaAuthentication:
      process.env.REACT_APP_SEND_HIPPA_AUTHENTICATION || "",
    saveHippaAuthentication:
      process.env.REACT_APP_SAVE_HIPPA_AUTHENTICATION || "",
    getMemberIdCard: process.env.REACT_APP_MEBER_IDCARD || "",
    getCSRAddOns: process.env.REACT_APP_CSR_GET_ADDONS || "",
    getCsrTempEmpId: process.env.REACT_APP_CSR_GET_TEMP_EMP_ID || "",
    caseCreation: process.env.REACT_APP_CASE_CREATION || "",
    getMytransactionReport: process.env.REACT_APP_MY_TRANSACTION_REPORT || "",
    getMytransactionHistory: process.env.REACT_APP_MY_TRANSACTION_HISTORY || "",
    getMemberAmount: process.env.REACT_APP_PAYMENT_DETAILS || "",
    getLast4AccountNumber: process.env.REACT_APP_LAST_DIGIT_ACC || "",
    getRecurringDate: process.env.REACT_APP_RECURRING_DATE || "",
    getQuestion: process.env.REACT_APP_GET_QUESTIONS || "",
    getUserQuestionState: process.env.REACT_APP_GET_USER_QUSTION_STATE || "",
    netwellMemberRefactor: process.env.REACT_APP_NETWELL_MEMBER_REFACTOR || "",
    gethipaaAuthById: process.env.REACT_APP_GET_HIPPAAUTH_BY_ID || "",
    getGeoLocation: process.env.REACT_APP_GEO_LOCATION_DB || "",
    getUploadImage: process.env.REACT_APP_UPLOAD_IMAGE || "",
    saveMemberEsign: process.env.REACT_APP_SAVE_MEMEBER_ESIGN || "",
    getEsignByMember : process.env.REACT_APP_API_GET_ESIGN_BY_MEMBER || "",
    hippaEsignPdf: process.env.REACT_APP_HIPPA_ESIGN_PDF || "",
    loginForhipaa: process.env.REACT_APP_API_LOGIN || "",
    getHeathInfoPopup: process.env.REACT_APP_API_HEALTHINFO || "",
    memberNetwork: process.env.REACT_APP_API_MEMBER_NETWORK,
    getCuramLife: process.env.REACT_APP_GET_CURAM_LIFE || "",
    getDocumentByPlanId: process.env.REACT_APP_API_FIND_DOCUMENT_BY_PLAN || "",
    getSaveHealthQues: process.env.REACT_APP_API_SAVE_HEALTH_QUES || "",
    getMemberDetails : process.env.REACT_APP_GET_MEMBERDETAILS || "",
  },
});

export default config;

export interface CONFIG {
  BRAND_NETWELL: string;
  BRAND_UHF: string;
  APP_NAME: "NETWELL" | "UHF" | "CURAMLIFE"| string;
  ENV_SETUP: string;
  MODE: string;
  DEFAULT_VALUES: {
    NETWELL_TILES_ID: string;
  };
  URLS: {
    baseTwillo: string;
    baseAPI: string;
    baseV7API: string;
    getClientBaseApi: string;
    loginEnrollment: string;

    getBaseNotificationURL: string;
    dashboardPlanInfo: string;
    getAnnouncemetDetails: string;
    memberPortal: string;
    getEnrolledMemberData: string;
    getHippaAuth: string;
    memberPortalMainURL: string;
    getUHFPrgrmInfo: string;
    getNetwellPrgrmInfo: string;
    getEnrollmentClient: string;
    getSoureId: string;
    getAgentDetails: string;
    getEnrollMemberInfoById: string;
    encryptData: string;
    getMemberCard: string;
    getMemberIdCard: string;
    getCSRAddOns: string;
    getCsrTempEmpId: string;
    caseCreation: string;
    getMytransactionReport: string;
    getMytransactionHistory: string;
    getMemberAmount: string;
    getLast4AccountNumber: string;
    getRecurringDate: string;
    hippaAuthorization: string;
    sendHippaAuthentication: string;
    saveHippaAuthentication: string;
    getQuestion: string;
    getUserQuestionState: string;
    netwellMemberRefactor: string;
    gethipaaAuthById: string;
    getEsignByMember:string;
    getGeoLocation: string;
    getUploadImage: string;
    saveHippaPrivacyAuth: string;
    hippaEsignPdf: string;
    loginForhipaa: string;
    getHeathInfoPopup: string;
    getCuramLife: string;
    getDocumentByPlanId: string;
    getSaveHealthQues: string;
  };
}
