import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";
import config from "../../config/constants/config";
import routePaths from "../../config/constants/routePaths";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];

export default styled("div")(({ theme }) => {
  const location = useLocation();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  return {
    position: "sticky",
    width: "100%",
    top: 0,
    zIndex: 4,
    header: {
      height:
        location.pathname !== routes.dashboard && showText
          ? "56px !important"
          : "80px",
      alignItems: "center",
      display: "flex",
    },
    ".headerMain": {
      boxShadow: "none !important",
      ".header_middle-section": {
        img: {
          height: 46,
        },
      },
    },
    ".mobile_headerNetWell": {
      width: "100%",
      height: "36px",
      padding: "0 5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ".mobile_header_titleNetWell": {
      flex: "1 1",
      margin: "0 20px",
      fontSize: "20px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.6",
      letterSpacing: ".5px",
      color: "#000",
      textTransform: "capitalize",
    },
    ".header-containers": {
      width: "100%",
      height: "60px",
      backgroundColor: "#533278",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ".fixed-top": {
      position: "fixed",
      right: 0,
      left: 0,
      zIndex: 1030,
    },
    ".logo": {
      width: "18%",
      marginLeft: "8px",
    },
    ".header_right-section":{
      ".header_right-section_icon":{
        pointerEvents: 'none'
      }
    },
    ".caption": {
      width: "54.33333%",
      fontFamily: "Tungsten",
      fontSize: "14px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: "1.44px",
      textAlign: "center",
      color: "#fff",
      textTransform: "uppercase",
    },
    ".languageSelect": {
      display: "flex",
      justifyContent: "end",
      ".MuiFormControl-root": {
        width: 200,
        marginTop: 10,
        position: 'absolute',
        zIndex: 10,
        right: 80,
        top: 10
      },
    },
  };
});
