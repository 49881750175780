import React, { useEffect, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  CustomButtonProps,
} from "carynhealth-component-library/dist/cjs/components/Button";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import StyleWrapper from "../../pages/Login/style";
import TextSignature from "../HippaCard/TextSignature";
import SampleTextField from "../../components/TextField/sampleTextField";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { getUploadImage } from "../HippaCard/handlers";
import { SetSaveMemberEsign } from "./handler";
import { useCommonLoader } from "carynhealth-component-library";

const TermsConditions = React.memo((props: any) => {
  const { t } = useTranslation();
  const signPad = React.useRef<any>();
  const [canvasChanged, setCanvasChanged] = useState(true);
  const [canvas, setCanvas] = useState<string | undefined>(undefined);
  const [fullName, setFullname] = useState("");
  const [disable, setDisable] = useState(true);
  const options = [t("UseStylizedScript"), t("LetMeDraw")];
  const [signatureValue, setSignatureValue] = React.useState(
    t("UseStylizedScript")
  );
  const { setLoading, unSetLoading } = useCommonLoader();

  const clearData = () => {
    signPad?.current.clear();
    setCanvasChanged(true);
    setDisable(true);
    setCanvas("");
  };

  const handleSignDone = () => {
    let url = signPad?.current?.getTrimmedCanvas().toDataURL("image/png");
    setCanvas(url);
    setCanvasChanged(true);
    setCanvas(url);
    return url;
  };

  const nameChangeHandler = (val: any) => {
    setFullname(val);
    setDisable(val ? false : true);
  };

  const signatureHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSignatureValue((event.target as HTMLInputElement).value);
    setCanvas("");
    setFullname("");
    setDisable(true);
    setCanvasChanged(true);
  };

  const handleDisable = async () => {
    await handleSignDone();
    saveData();
    props.closePopup();
  };

  const onSignatureChange = () => {
    setDisable(false);
    setCanvasChanged(false);
  };

  const textSignatureToImage = () => {
    let node: any = document.getElementById("signTextContent");
    return html2canvas(node).then((canvas) => {
      return canvas.toDataURL("image/png");
    });
  };

  const uploadImage = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file, `${new Date().getTime()}.png`);

    return await getUploadImage(formData).then((res: any) => {
      return res?.apiResponse?.response;
    });
  };

  const dataURItoBlob = (dataURI: any) => {
    let byteString = atob(dataURI.split(",")[1]);

    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const saveData = async () => {
    setLoading();
    let imageUrl: any = "";

    if (signatureValue === "Use stylized script" || signatureValue === "Usar escritura estilizada") {
      const img = await textSignatureToImage();
      imageUrl = await uploadImage(dataURItoBlob(img));
    } else {
      imageUrl = await uploadImage(dataURItoBlob(handleSignDone()));
    }

    let data = {
      memberId: localStorage.getItem("member-id"),
      email: localStorage.getItem("userMail"),
      esign: imageUrl,
      authName: localStorage.getItem("fullName"),
    };

    console.log("datatatata - -", data);
    SetSaveMemberEsign(data)
      .then((res) => {
        if (res.apiResponse.code == 200) {
          unSetLoading();
        } else {
          unSetLoading();
        }
      })
      .catch((err) => {
        unSetLoading();
      });
  };

  return (
    <StyleWrapper>
      <div className="Popup-content">
        <p>
          Terms and Conditions
          <Grid container className="signatureInput">
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={signatureHandleChange}
                value={signatureValue}
              >
                {options.map((item: any) => (
                  <>
                    <FormControlLabel
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  </>
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {signatureValue === t("UseStylizedScript") ? (
            <>
              <Grid container className="FullNameInput">
                <SampleTextField
                  setChild={(val) => nameChangeHandler(val)}
                  name={"FullName"}
                  reqFlag={true}
                  label={t("AuthorizeName")}
                  value={fullName}
                  disable={false}
                  length={50}
                  errMsg={"Enter valid full name"}
                  helperMsg={""}
                  fieldType={"holderName"}
                />
              </Grid>
              <TextSignature text={fullName} />
            </>
          ) : (
            <>
              <SignatureCanvas
                canvasProps={{
                  width: 485,
                  height: 100,
                  className: "sigCanvas",
                }}
                ref={signPad}
                onEnd={onSignatureChange}
              />
            </>
          )}
        </p>
      </div>
      <Stack className="bottomBtn" direction="row" spacing={2}>
        {signatureValue === t("LetMeDraw") && (
          <Button
            label={t("clear").toUpperCase()}
            disabled={canvasChanged}
            onClick={() => clearData()}
          />
        )}
        {/* <Button
                  label={"DONE"}
                  disabled={canvasChanged}
                  onClick={() => handleSignDone()}
                /> */}
        <div className="acceptBtn">
          <Button
            label={t("Accept").toUpperCase()}
            disabled={disable}
            variant="outlined"
            onClick={handleDisable}
          />
        </div>
      </Stack>
    </StyleWrapper>
  );
});

export default TermsConditions;
