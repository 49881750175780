import { t } from "i18next";
import { ReturenTransString } from "../../../../ReturenTransString";

export const headCells = [
  { id: "arrow", numeric: true, disablePadding: false, label: "" },
  {
    id: "transactionId",
    numeric: true,
    disablePadding: false,
    label: ReturenTransString('parent.TransactionID'),
  },
  {
    id: "createdDate",
    numeric: true,
    disablePadding: false,
    label: ReturenTransString('parent.TransactionDate'),
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: ReturenTransString('parent.PaymentMethod'),
  },
  {
    id: "transactionAmount",
    numeric: true,
    disablePadding: false,
    label: ReturenTransString('parent.TotalAmount'),
  },
  {
    id: "authorizationMessage",
    numeric: true,
    disablePadding: false,
    label: ReturenTransString('parent.Status'),
  },
];
