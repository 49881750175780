import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgramInfoSummary from "./Summary";
import {
  getAgentInfo,
  getClientDetails,
  getEncryptData,
  getEnrolledMemberData,
  getEnrollMemberInfoById,
  getIsCardEnableInfo,
  getMemberHealthIdCard,
  getProgramInfo,
  getRxSimpleShareData,
  getSourceID,
  jumpToAddons,
  openEnrollLogin,
} from "./handlers";
import { getRoutePaths } from "../../config/constants/routePaths";
import config from "../../config/constants/config";
import EligibleServices from "./EligibleService";
import ExpenseLimits from "./ExpenseLimits";
import AddonsServices from "./AddOns";
import { StorageHelpers, useCommonLoader } from "carynhealth-component-library";
import EnrolledMember from "./EnrolledMembers";
import { styled } from "@mui/system";
import VisitFees from "./VisitFees";
import ShareLimits from "./ShareLimits";
import DiscountPrgrm from "./DiscountPrograms";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

const isUHF = config.APP_NAME === config.BRAND_UHF;
const isCuramLife = config.APP_NAME === config.BRAND_CURAMLIFE;

const ProgramInformationStyle = styled("section")(({ theme }) => {
  return {
    [theme.breakpoints.up(375)]: {
      marginLeft: 30,
      marginTop: 30,
    },
    "#pi-addons": {
      marginTop: 30,
    },
    ".CURAMLIFE-addons": {
      maxHeight: 250,
      overflowY: 'auto',
      // ".ip_addon": {
      //   display: "flex",
      //   // flexDirection: "row",
      //   // overflowX: "auto",
      //   maxWidth: "80vw",
      // },
      ".ip_addon": {
        maxHeight: "300px",
        overflowY: "auto",
        display: "contents",
      },
      ".addon-title": {
        fontSize: 20,
      },
    },
    [theme.breakpoints.down(1024)]: {
      ".change-program": {
        marginTop: 30,
      },
      "&#program_information": {
        overflow: "auto",
      },
    },
    [theme.breakpoints.down(426)]: {
      ".change-program": {
        marginTop: 0,
      },
    },
    ".MuiListItem-root:hover": {
      color: "initial !important",
    },
  };
});

const ProgramInformation: React.FC = (props: any) => {
  const [addOnsInfo, SetAddOnsInfo] = useState({});
  const [enrolledDataMember, setEnrolledDataMember] = useState([]);
  const [progrmInfo, setProgrmInfo] = useState<any>({});
  const [clientDetails, setClientDetails] = useState({});
  const [agentDetails, setAgentDetails] = useState({});
  const [memberDetails, setMemberDetails] = useState({});
  const [enrollLoginModal, setEnrollLoginModal] = useState({});

  console.log(" ---- Program Information ---- ", {
    addOnsInfo,
    enrolledDataMember,
    progrmInfo,
    clientDetails,
    agentDetails,
    memberDetails,
    enrollLoginModal,
  });

  const navigate = useNavigate();
  const { setLoading, unSetLoading } = useCommonLoader();

  // Navigate to dashboard "if" response is false
  const navigateHomeIf = async () => {
    try {
      const isEnabled = await getIsCardEnableInfo("ProgramInformation");
      !isEnabled && navigate(getRoutePaths.dashboard);
    } catch (error) {}
  };

  const get_Healthcard_RxSimpleShareData = async () => {
    try {
      const memberSSN = await getMemberHealthIdCard();
      const addOnsData = await getRxSimpleShareData({
        type: "family",
        memberSSN,
      });
      SetAddOnsInfo(addOnsData);
    } catch (error) {
      console.error("get_Healthcard_RxSimpleShareData", error);
    }
  };

  const get_Enroll_Member = async () => {
    try {
      const enrollData = await getEnrolledMemberData();
      setEnrolledDataMember(enrollData);
    } catch (error) {}
  };

  const get_program_info = async () => {
    try {
      setLoading({ message: "Fetching data..." });
      if (config.APP_NAME === config.BRAND_CURAMLIFE) {
        let data = getLocalStorage("curamLifeMemberPackages") || "";
        setProgrmInfo(JSON.parse(data));
      } else {
        const program_response = await getProgramInfo();
        setProgrmInfo(program_response);
        if (config.APP_NAME === config.BRAND_NETWELL) {
          setEnrolledDataMember(program_response?.memberDetails || []);
        }
      }

      unSetLoading();
    } catch (error) {
      unSetLoading();
    }
  };

  const get_client_details = async () => {
    try {
      const client_response = await getClientDetails();
      setClientDetails(client_response);
      // Agent information is required for "update household"
      if (client_response.isNotNEOsource || isUHF) {
        let memberIdSourceVal =
          StorageHelpers.getLocalStorage("sourceid") || "";
        if (!memberIdSourceVal) {
          const { memberIdSource } = (await getSourceID()) || {
            memberIdSource: "",
          };
          memberIdSourceVal = memberIdSource;
        }
        const agentInfo = await getAgentInfo(memberIdSourceVal);
        setAgentDetails(agentInfo);
        const memberInfo = await getEnrollMemberInfoById(memberIdSourceVal);
        setMemberDetails(memberInfo);

        if (!isUHF) return;

        // Below for UHF

        const isAddOn = jumpToAddons();
        const { errorModal, ...reqEncrypt } = openEnrollLogin(
          { ...client_response, ...agentInfo },
          isAddOn === true
        );
        !errorModal && setEnrollLoginModal(errorModal);
        if (!errorModal) await getEncryptData(reqEncrypt || {});
        isAddOn === "No Params" &&
          navigate(getRoutePaths.programInformation, { replace: true });
      }
    } catch (error) {
      console.log("get_client_details ERROR:: ", error);
    }
  };

  useEffect(() => {
    if (isUHF) {
      navigateHomeIf();
      get_Healthcard_RxSimpleShareData();
      get_Enroll_Member();
    }
    if (isCuramLife) {
      const dataJSON = getLocalStorage("memebr-details") || "";
      const data = JSON.parse(dataJSON);
      console.log(data.curamLifeAddons);
      SetAddOnsInfo(data.curamLifeAddons);
    } else {
      get_client_details();
    }
    get_program_info();
  }, []);

  const { tabInfo } = props;
  return (
    <ProgramInformationStyle
      id="program_information"
      className="program_information"
    >
      {tabInfo.id === "summary" ? (
        <ProgramInfoSummary data={progrmInfo} />
      ) : null}
      {tabInfo.id === "consultation-fees" ? (
        <EligibleServices data={progrmInfo} />
      ) : null}
      {tabInfo.id === "expense-limits" ? (
        <ExpenseLimits data={progrmInfo} />
      ) : null}
      {tabInfo.id === "add-ons" ? (
        <AddonsServices
          data={addOnsInfo}
          agentDetails={agentDetails}
          clientDetails={clientDetails}
          memberDetails={memberDetails}
        />
      ) : null}
      {tabInfo.id === "enrolled-members" ? (
        <EnrolledMember
          data={enrolledDataMember}
          agentDetails={agentDetails}
          clientDetails={clientDetails}
          memberDetails={memberDetails}
        />
      ) : null}
      {tabInfo.id === "visit-fees" ? <VisitFees data={progrmInfo} /> : null}
      {tabInfo.id === "sharing-limits" ? (
        <ShareLimits data={progrmInfo} />
      ) : null}
      {tabInfo.id === "discount-programs" ? (
        <DiscountPrgrm data={progrmInfo} />
      ) : null}
    </ProgramInformationStyle>
  );
};

export default React.memo(ProgramInformation);
