import { apiService, StorageHelpers } from "carynhealth-component-library";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";
import dummyUserInfo from "../../dummyData/dummyUserInfo";
import dummyProgramInfoData from "../../dummyData/ProgramInfo.json";

const isLocalEnv = window.location.href.includes("localhost");

export const getIsCardEnableInfo = async (cardTitle: string) => {
  try {
    const client_id = StorageHelpers.getLocalStorage("CLIENT_ID");
    const URL = `${config.URLS.baseAPI}${config.URLS.getMemberCard}${client_id}/${cardTitle}`;
    const { data: apiResponse } = await apiService.default.get({
      URL,
    });
    return apiResponse.response.enable;
  } catch (error: any) {
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getMemberHealthIdCard = async () => {
  try {
    const eMail = StorageHelpers.getLocalStorage("userMail");
    const request = { email: eMail };
    const URL = `${config.URLS.baseAPI}${config.URLS.getMemberIdCard}`;
    const { data: apiResponse } = await apiService.default.post({
      URL,
      data: request,
    });
    const MEMBER_SSN = apiResponse?.memberIdCardList[0]?.memberSSN || "";
    StorageHelpers.setLocalStorage("memberSSN", MEMBER_SSN);
    return MEMBER_SSN;
  } catch (error: any) {
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getRxSimpleShareData = async (data: object) => {
  try {
    const URL = `${config.URLS.baseAPI}${config.URLS.getCSRAddOns}`;
    const { data: apiResponse } = await apiService.default.post({
      URL,
      data,
    });

    let counterHealthTool = 0;
    let counterRxSimpleShare = 0;
    let isAddonToolExists: any = [
      "UHS Health Tools",
      "Advanced Health Tools",
      "Health Tools",
    ].map((tool: string) => tool?.toLowerCase());
    let isShareExists: any = [];
    let BoolisAddonToolExists = false;
    apiResponse.forEach((item: any) => {
      isShareExists = ["UHS RxSimpleShare", "RxSimpleShare"].map(
        (tool: string) => tool?.toLowerCase()
      );
      BoolisAddonToolExists = false;
      if (isAddonToolExists.includes(item?.addon?.toLowerCase())) {
        BoolisAddonToolExists = true;
      }
      isShareExists = isShareExists.includes(item?.addon?.toLowerCase());
      isAddonToolExists && counterHealthTool++;
      isShareExists && counterRxSimpleShare++;
    });

    const findRxSimpleData = apiResponse.find(
      (obj: any) =>
        ["UHS RxSimpleShare", "RxSimpleShare"].includes(obj.addon) &&
        obj.status === "AC"
    );
    const findHealToolData = apiResponse.find(
      (obj: any) =>
        ["UHS Health Tools", "Advanced Health Tools", "Health Tools"].includes(
          obj.addon
        ) && obj.status === "AC"
    );

    return {
      rxSimpleShareActive: Boolean(findRxSimpleData),
      counterRxSimpleShare,
      healthToolAddonActive: Boolean(findHealToolData),
      counterHealthTool,
      addOns: apiResponse,
    };
  } catch (error: any) {
    console.log("Error: getRxSimpleShareData ", error);
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getEnrolledMemberData = async () => {
  try {
    const request = {
      subscriberIdSource: StorageHelpers.getLocalStorage("sourceid"),
    };
    const URL = `${config.URLS.baseAPI}${config.URLS.getCsrTempEmpId}`;
    const { data: apiResponse } = await apiService.default.post({
      URL,
      data: request,
    });
    return apiResponse.map((res: any) => ({
      ...res,
      dateOfBirth: formatDate(res.dateOfBirth),
    }));
  } catch (error: any) {
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getProgramInfo = async () => {
  try {
    const eMail = StorageHelpers.getLocalStorage("userMail");
    const getProgramURL =
      config.URLS[
        config.APP_NAME === config.BRAND_NETWELL
          ? "getNetwellPrgrmInfo"
          : "getUHFPrgrmInfo"
      ];
    const URL = config.URLS.baseAPI + getProgramURL + eMail;
    const { data: apiResponse } = await apiService.default.get({
      URL,
    });

    /*Calculation*/

    const percentascm =
      (apiResponse?.acsm?.met / apiResponse?.acsm?.remaining) * 100;
    const percentnsa =
      (apiResponse?.nsa?.met / apiResponse?.nsa?.remaining) * 100;

    return {
      ...getProgramNames(apiResponse),
      ...apiResponse.programInfo,
      eligibleServices: apiResponse.planInfo,
      expenseLimits: apiResponse.expenseLimits,
      visitFee: apiResponse?.visitFee || [],
      sharingLimit: apiResponse?.sharingLimit || [],
      memberDetails: updateMemberDetails(apiResponse?.memberDetails),
      discountPrograms: apiResponse.discountPrograms || [],
      // UHF STARTS
      acsmmet: apiResponse?.acsm?.met,
      acsmTotal: apiResponse?.acsm?.total,
      acsmremain: apiResponse?.acsm?.remaining,
      nsamet: apiResponse?.nsa?.met,
      nsaremain: apiResponse?.nsa?.remaining,
      // UHF ENDS
      barRange: percentnsa,
      barRangeYellow: percentascm,
    };
  } catch (error: any) {
    if (isLocalEnv) {
      return Promise.resolve({
        ...dummyProgramInfoData.programInfo,
        eligibleServices: dummyProgramInfoData.planInfo,
        expenseLimits: dummyProgramInfoData.expenseLimits,
        discountPrograms: dummyProgramInfoData.discountPrograms,
      });
    }
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getClientDetails = async () => {
  try {
    const request = {
      URL: config.URLS.getClientBaseApi + config.URLS.getEnrollmentClient,
      data: {
        clientId: StorageHelpers.getLocalStorage("CLIENT_ID"),
      },
    };
    const { data: apiResponse } = await apiService.default.post(request);
    const isNotNEOsource = StorageHelpers.getLocalStorage("SOURCE") !== "NEO";
    return {
      clientID: apiResponse.response?.clientId || "",
      associationId: apiResponse.response?.association || "",
      clientName: apiResponse.response?.clientName || "",
      isNotNEOsource,
    };
  } catch (error: any) {
    console.error("ERROR getClientDetails:: ", error);
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getSourceID = async () => {
  try {
    const eMail = StorageHelpers.getLocalStorage("userMail");
    const URL = config.URLS.baseAPI + config.URLS.getSoureId + eMail;
    const { data: apiResponse } = await apiService.default.get({
      URL,
    });
    return {
      memberIdSource: apiResponse.memberIdSource,
    };
  } catch (error: any) {
    console.log("getSourceID:::: ", error);
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getAgentInfo = async (memberIdSource: string) => {
  try {
    const URL =
      config.URLS.baseAPI + config.URLS.getAgentDetails + memberIdSource;
    const { data: apiResponse } = await apiService.default.get({
      URL,
    });
    return {
      agentemail: apiResponse.response.email,
      agentname: apiResponse.response.name,
      agentno: apiResponse.response.phone,
      brokerId: apiResponse.response.brokerId,
    };
  } catch (error: any) {
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const getEnrollMemberInfoById = async (memberIdSource: string) => {
  try {
    const URL =
      config.URLS.baseAPI +
      config.URLS.getEnrollMemberInfoById +
      memberIdSource;
    const { data: apiResponse } = await apiService.default.get({
      URL,
    });
    return {
      subID: apiResponse?.response?.subId,
      associationId: apiResponse?.response?.associationId,
    };
  } catch (error: any) {
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const jumpToAddons = () => {
  const url = window.location.href;
  if (url.split("ProgramInformation?").length > 1) {
    const queryString = url.split("ProgramInformation?")[1];
    const queryParams = new URLSearchParams(queryString);
    const addons = decodeURI(queryParams.get("change_addons") || "");
    return addons === "true";
    // openEnrollLogin(state, addons === "true");

    // return {
    //   navigateTo:
    //     routePaths[config.APP_NAME as keyof typeof routePaths]
    //       .programInformation,
    // };
  }
  return "No Params";
};

export const openEnrollLogin = (state: any, updateHousehold: boolean) => {
  if (config.APP_NAME === config.BRAND_UHF && !state.subID)
    return { errorModal: true };

  const obj: any = {
    clientId: state.clientName,
    associationId: state.associationId,
    brokerId: state.brokerId,
    fromMember: true,
    user_subId: StorageHelpers.getLocalStorage("userMail"),
    memberId: StorageHelpers.getLocalStorage("sourceid"),
    subID: state.subID,
  };

  if (updateHousehold) {
    obj.isHouseholdUpdate = true;
  } else {
    obj.isEditCensus = false;
    obj.isSelectProgram = true;
  }

  return { errorModal: false, ...obj };
};

export const getEncryptData = async (reqObj: any) => {
  try {
    const URL = config.URLS.baseAPI + config.URLS.encryptData;
    const { data: apiResponse } = await apiService.default.post({
      URL,
      data: reqObj,
    });

    const windowReference = window.open() || { location: "" };
    const refURL =
      config.URLS.loginEnrollment + "login#state=" + apiResponse.response;
    windowReference.location = refURL;
  } catch (error: any) {
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

const getProgramNames = (apiResponse: any) => {
  const prgrm_names = { programname: "", programname2: "" };
  if (config.APP_NAME === config.BRAND_UHF) {
    const program_name = apiResponse.programInfo.programName;
    const isSymbolExists = program_name.includes("+");

    const program_names = program_name.split(" ");
    prgrm_names.programname = isSymbolExists
      ? program_names[0]
      : apiResponse.programInfo.programName;
    prgrm_names.programname2 = isSymbolExists
      ? " with " + program_names[2]
      : "";
    return prgrm_names;
  } else if (config.APP_NAME === config.BRAND_NETWELL) {
    const { fieldValue = "", planId = "" } = apiResponse.planInfo?.[0] || {};
    return { programname: fieldValue, programname2: planId };
  }
  return prgrm_names;
};

export const updateMemberDetails = (data: any) => {
  if (!data || !Array.isArray(data)) return [];
  return data.map((detail) => ({
    ...detail,
    dateOfBirth: formatDate(detail.dateOfBirth),
  }));
};

export const formatDate = (dateOfBirth: number) => {
  if (!dateOfBirth) return "";
  return new Date(dateOfBirth).toDateString().split(" ").splice(1).join(" ");
};
