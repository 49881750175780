import { t } from "i18next";
import PrgrmInfo from "../../components/ProgramInformation";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";
import titles from "../../config/constants/titles";
import { ReturenTransString } from "../../ReturenTransString";

const tabs = {
  NETWELL: [
    { label: titles.summary, id: "summary", style: { width: "20%" } },
    { label: titles.visitFees, id: "visit-fees", style: { width: "20%" } },
    {
      label: titles.sharingLimits,
      id: "sharing-limits",
      style: { width: "20%" },
    },
    {
      label: titles.enrolledMembers,
      id: "enrolled-members",
      style: { width: "20%" },
    },
    {
      label: titles.discountPrograms,
      id: "discount-programs",
      style: { width: "20%" },
    },
  ],
  UHF: [
    { label: ReturenTransString('summary'), id: "summary", style: { width: "20%" } },
    {
      label: ReturenTransString('consultationFees'),
      id: "consultation-fees",
      style: { width: "20%" },
    },
    {
      label: ReturenTransString('expenseLimits'),
      id: "expense-limits",
      style: { width: "20%" },
    },
    { label: ReturenTransString('addOns'), id: "add-ons", style: { width: "20%" } },
    {
      label: ReturenTransString('enrolledMembers'),
      id: "enrolled-members",
      style: { width: "20%" },
    },
  ],
  CURAMLIFE: [
    { label: ReturenTransString('summary'), id: "summary", style: { width: "20%" } },
    { label: ReturenTransString('addOns'), id: "add-ons", style: { width: "20%" } },
  ]
};

const footerText = {
  NETWELL: messages.netwell.NEEDHELP_MSG,
  UHF: 'NEEDHELP_MSG',
  CURAMLIFE:'NEEDHELP_MSG_CURAMLIFE'
};

export const ProductTileInfo = {
  title: ReturenTransString('ProgramInformation'),
  tabs: tabs[config.APP_NAME as keyof typeof tabs],
  Component: PrgrmInfo,
  footerText: footerText[config.APP_NAME as keyof typeof tabs],
};
