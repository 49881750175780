import { styled } from "@mui/system";
import WelcomeImg from "../../../assests/images/uhf/welcome-image.svg";
import WelcomeBack from "../../../assests/images/uhf/welcomeBack.svg";
import { useTranslation } from "react-i18next";

const StyleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  lineHeight: " .9",
  ".netwell-label_container": {
    zIndex: 0,
    display: "flex",
  },
  ".sub-div_container": {
    margin: "25px 36px 26px 36px"
  },
  ".welcome-text": {
    fontSize: 30,
    fontWeight: 400,
    color: "#7e7e7e",
    fontFamily: 'Lato',
    marginBottom: 3
  },
  ".netwell-username": {
    fontSize: 50,
    fontWeight: 800,
    color: " #000",
    fontFamily: 'Lato',
  },
  "&.UHF": {
    marginBottom: "42vh",
  },
  ".uhf-label_container": {
    zIndex: 0,
    position: "absolute",
    display: "flex",
  },

  ".welcome_image": {
    marginLeft: "-19vw",
    width: "92vw",
    height: "58vh",
    maxWidth: "100%",
    marginTop: "-10vh",
    zIndex: 0,
  },
  ".welcome-label_container": {
    alignSelf: "center",
    marginLeft: "-29vw",
    zIndex: 0,
  },
  ".welcome_back": {
    marginBottom: " -1.2vw",
    maxWidth: "120%",
    width: "15vw",
  },
  ".uhf-username": {
    textTransform: "uppercase",
    width: " 35vw",
    height: "70px",
    fontSize: "4vw",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.1,
    fontFamily: "Anton,sans-serif",
    letterSpacing: "normal",
    color: "#000",
    position: "relative",
    zIndex: "-1",
  },
  [theme.breakpoints.down(1024)]: {
    display: "none",
  },
}));

export const Components = {
  NETWELL: (props: any) => {
    return (
      <StyleWrapper>
        <div className="netwell-label_container">
          <div className="sub-div_container">
            <div className="welcome-text">Welcome</div>
            <div className="netwell-username">{props.username} !</div>
          </div>
        </div>
      </StyleWrapper>
    );
  },
  CURAMLIFE: (props: any) => {
    const {t} = useTranslation();
    return <StyleWrapper>
    <div className="netwell-label_container">
      <div className="sub-div_container">
        <div className="welcome-text">{t('Welcome')}</div>
        <div className="netwell-username">{props.username} !</div>
      </div>
    </div>
  </StyleWrapper>;
  },
  UHF: (props: any) => {
    return (
      <StyleWrapper className="UHF">
        <div className="uhf-label_container">
          <img className="welcome_image" src={WelcomeImg} />
          <div className="welcome-label_container">
            <img className="welcome_back" src={WelcomeBack} />
            <div className="uhf-username">{props.username} !</div>
          </div>
        </div>
      </StyleWrapper>
    );
  },
};
