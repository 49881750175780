import React from "react";
import { Checkbox, FormGroup, Grid, styled } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SampleTextField from "../TextField/sampleTextField";
import MuiPhoneNumber from "material-ui-phone-number";
import { useTranslation } from "react-i18next";

const CssPhone = styled(MuiPhoneNumber)(() => {
  return {
    ".MuiInputBase-root": {
      borderRadius: "4px 4px 0 0 !important",
    },
    ".MuiFormLabel-root": {
      color: "#533278",
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "2px solid #533278",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "2px solid #533278",
        color: "#533278",
      },
    },
  };
});

export const CurrentHealthcare = (props: any) => {
  const { t } = useTranslation();
  const [questionData, setQuestionData] = React.useState<any[]>([]);
  const [phoneValue, setPhoneValue] = React.useState("");
  React.useEffect(() => {
    getQuestionData();
  }, []);

  const getQuestionData = () => {
    const filterValue = "CURRENT";
    const result = props?.questionList.filter((val: any) =>
      val?.questionTypCode.includes(filterValue)
    );
    setQuestionData(result);
    checkDisable(result);
  };

  const checkDisable = async (result: any) => {
    let disableProp = await setDisableNext(result);
    props.disableNext(disableProp);
  };
  const setDisableNext = (result: any) => {
    let allRsp = result.some((x: any) => x.response == "");
    if (!allRsp) {
      if (
        result[2].response.toLowerCase() == "yes" &&
        result[2].reltdQstnID[0].response == ""
      ) {
        return true;
      } else if (
        result[0].response.toLowerCase() == "yes" &&
        result[0].reltdQstnID[0].response == ""
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    questionData[index].response = (event.target as HTMLInputElement).value;
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = (event.target as HTMLInputElement).value;
    if (questionData[1].response.indexOf(value) === -1) {
      if (value.toLowerCase() === "none") {
        questionData[1].response = [];
        questionData[1].response.push(value);
      } else {
        if (questionData[1].response.indexOf("None") !== -1) {
          let arr = [];
          arr.push(value);
          questionData[1].response = arr;
        }
        if (Array.isArray(questionData[1].response)) {
          questionData[1].response.push(value);
        } else {
          let arr = questionData[1].response.split(",");
          arr.push(value);
          questionData[1].response = arr;
        }
      }
    } else {
      questionData[1].answer.splice(questionData[1].response.indexOf(value), 1);
    }
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };

  const nameChangeHandler = (val: any, index: number, respIndex: number) => {
    questionData[index].reltdQstnID[respIndex].response = val;
    questionData[index].reltdQstnID[respIndex].id =
      questionData[index].reltdQstnID[respIndex].id || "";
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };
  const validPhoneNumber = (val: any, index: number, respIndex: number) => {
    if (val.match(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/)) {
      nameChangeHandler(val, index, respIndex);
    } else {
      if (val.includes("+")) {
        setPhoneValue(val);
      } else {
        setPhoneValue(`+${val}`);
      }
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item lg={7}>
        <div className="custom-subtitle">{t(props?.instData?.title)}</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {questionData[2]?.questionDesc}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionData[2]?.response}
                onChange={(event) => handleChange(event, 2)}
              >
                {questionData[2]?.options.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.option}
                    control={
                      <Radio
                        disabled={props.ViewMode}
                        checked={questionData[2]?.response === item.option}
                      />
                    }
                    label={item.option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {questionData[2]?.response === "No" && (
              <Grid className="multilineInput">
                <SampleTextField
                  setChild={(val) => nameChangeHandler(val, 2, 2)}
                  name={"otherPhy"}
                  reqFlag={false}
                  label={questionData[2]?.reltdQstnID[2]?.questionDesc}
                  value={questionData[2]?.reltdQstnID[2]?.response}
                  disable={props.ViewMode}
                  length={120}
                  fieldType={"text"}
                  errMsg={"Enter Valid Other Physician"}
                  helperMsg={""}
                  multiline={true}
                  rows={4}
                />
              </Grid>
            )}
            {questionData[2]?.response === "Yes" && (
              <Grid className="PhoneNumberInput">
                <SampleTextField
                  name={"pCarePhy"}
                  setChild={(val) => nameChangeHandler(val, 2, 0)}
                  reqFlag={true}
                  label={questionData[2]?.reltdQstnID[0]?.questionDesc}
                  value={questionData[2]?.reltdQstnID[0]?.response}
                  disable={props.ViewMode}
                  length={50}
                  fieldType={"any"}
                  errMsg={"Primary Physician required"}
                  helperMsg={"Primary Physician required"}
                />
                <CssPhone
                  name="phone"
                  id="phone-number"
                  label="Phone No."
                  data-cy="user-phone"
                  variant="filled"
                  defaultCountry="us"
                  autoComplete="off"
                  style={{ backgroundColor: "#fff", width: "100%" }}
                  value={phoneValue}
                  sx={{
                    ".MuiFilledInput-underline:after": {
                      borderBottomColor: "#533278",
                    },
                  }}
                  onChange={(e: any) => validPhoneNumber(e, 2, 1)}
                  disableDropdown
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {questionData[0]?.questionDesc}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionData[0]?.response}
                onChange={(event) => handleChange(event, 0)}
              >
                {questionData[0]?.options.map((item: any) => (
                  <FormControlLabel
                    value={item.option}
                    control={
                      <Radio
                        disabled={props.ViewMode}
                        checked={questionData[0]?.response === item.option}
                      />
                    }
                    label={item.option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {questionData[0]?.response === "Yes" && (
              <Grid className="multilineInput">
                <SampleTextField
                  setChild={(val) => nameChangeHandler(val, 0, 0)}
                  reqFlag={true}
                  label={"List conditions or diseases"}
                  value={questionData[0]?.reltdQstnID[0]?.response}
                  disable={props.ViewMode}
                  length={120}
                  errMsg={"Enter Valid List of Condition/Diseases"}
                  helperMsg={
                    questionData[0]?.reltdQstnID[0]?.response
                      ? ""
                      : "List of Condition/Diseases required"
                  }
                  fieldType={"text"}
                  multiline={true}
                  rows={4}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {questionData[1]?.questionDesc}
              </FormLabel>
              <div className="healthCheckbox">
                <FormGroup aria-labelledby="demo-row-radio-buttons-group-label">
                  {questionData[1]?.options.map((item: any) => (
                    <FormControlLabel
                      value={item.option}
                      key={item.id}
                      sx={{
                        height: 32,
                        ".MuiTypography-root": {
                          fontWeight: 100,
                          fontFamily: "Roboto",
                          fontSize: 15,
                        },
                      }}
                      control={
                        <Checkbox
                          checked={
                            questionData[1]?.response?.indexOf(item.option) !==
                            -1
                          }
                          disabled={props.ViewMode}
                          onChange={handleChangeCheck}
                          name={item.option}
                        />
                      }
                      label={item.option}
                    />
                  ))}
                </FormGroup>
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={5}>
        <Grid item xs={10}>
          <div className="custom-subtitle" style={{ marginTop: "10px" }}>
            {" "}
            {t('whyNeedThis')}
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: t(props.instData?.description),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
