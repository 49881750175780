import TransactionView from "./Desktop/TransactionView";
import config from "../../config/constants/config";
import titles from "../../config/constants/titles";
import AccordianTransactionTable from "./Mobile/AccordianTransaction";
import moment from "moment";
import PaymentInformationMobile from "./Mobile/PaymentInformation";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import messages from "../../config/constants/messages";
import { t } from "i18next";
import { ReturenTransString } from "../../ReturenTransString";

const tabs = {
  NETWELL: [],
  UHF: [],
};

export const access = {
  control:
    process.env.REACT_APP_ENV_NAME === "UHF"
      ? {
          showTransaction: true,
          showPaymentInformation: true,
          showchangePaymentMethod: true,
          showChangeBillingDate: true,
          changePaymentAccess: true,
          showPaymentType: true,
          showNextPaymentDueDate: true,
          type: "UHF",
        }
      : process.env.REACT_APP_ENV_NAME === "NETWELL"
      ? {
          showTransaction: true,
          showPaymentInformation: false,
          showchangePaymentMethod: true,
          showChangeBillingDate: false,
          changePaymentAccess: true,
          showPaymentType: true,
          showNextPaymentDueDate: true,
          type: "NETWELL",
        }
      : {},
};
const footerText = {
  NETWELL: messages.netwell.NEEDHELP_MSG,
  UHF: 'NEEDHELP_MSG',
  CURAMLIFE:'NEEDHELP_MSG_CURAMLIFE'
};

export const ProductTileInfo = {
  title: ReturenTransString('myTransaction'),
  Component: TransactionView,
  footerText: footerText[config.APP_NAME as keyof typeof tabs],
  showChatIcon: process.env.REACT_APP_ENV_NAME !== "NETWELL",
};
export const TransactionAccordian = {
  titleOne: titles.recentTransaction,
  ComponentOne: AccordianTransactionTable,
  titleTwo: titles.paymentInfo,
  ComponentTwo: PaymentInformationMobile,
};

const handleParent = () =>{
  let parentAarry = [];
  parentAarry.push(ReturenTransString('parent.TransactionID'));
  parentAarry.push(ReturenTransString('parent.TransactionDate'));
  parentAarry.push(ReturenTransString('parent.PaymentMethod'));
  parentAarry.push(ReturenTransString('parent.TotalAmount'));
  parentAarry.push(ReturenTransString('parent.Status'));
  return parentAarry;
}

const handleChild = () =>{
  let childAarry = [];
  childAarry.push(ReturenTransString('child.MonthlyShareContribution'));
  childAarry.push(ReturenTransString('child.UHFMonthlyMembershipFee'));
  childAarry.push(ReturenTransString('child.ApplicationFee'));
  childAarry.push(ReturenTransString('child.RefundAmount'));
  return childAarry;
}

export const tableHeader = {
  heading:
    process.env.REACT_APP_ENV_NAME === "UHF"
      ? {
          parent: handleParent(),
          child: handleChild(),
        }
      : process.env.REACT_APP_ENV_NAME === "NETWELL"
      ? {
          parent: [
            "Transaction ID",
            "Transaction Date",
            "Payment Method",
            "Total Amount",
            "Status",
          ],
          child: [
            "Monthly Share Contribution",
            "Monthly Membership Fee",
            "Application Fee",
            "Refund Amount",
          ],
        }
      : {
          parent: [],
          child: [],
        },
};
export const getDate = (date: any) => {
  const isDateValid = date ? date : false;
  if (isDateValid) {
    return moment(date).format("MMMM D, YYYY");
  }
  return date;
};

export const numberWithCommas = (x: any) => {
  return Number(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const floatconversion = (x: any) => {
  if (x.toString().startsWith("$")) {
    let substr = x.substr(1, x.length);
    return "$" + parseFloat(substr).toFixed(2).toString();
  } else {
    return x.toFixed(2);
  }
};
