import React from "react";
import config from "../../config/constants/config";
import { ReactComponent as DashboardIconActiveNetwell } from "../../assests/images/dashboard_ icon_active.svg";
import { ReactComponent as DashboardIconNetwell } from "../../assests/images/dashboard_icon.svg";
import { ReactComponent as DashboardIconActiveUhf } from "../../assests/images/dashboard_icon_wh_active.svg";
import { ReactComponent as DashboardIconUhf } from "../../assests/images/dashboard_icon_wh.svg";
import { ReactComponent as DocumentsIconUhf } from "../../assests/images/documents_icon_wh.svg";
import { ReactComponent as DocumentsIconActiveUhf } from "../../assests/images/documents_icon_wh_active.svg";
import { ReactComponent as DocumentsIconNetwell } from "../../assests/images/documents_icon.svg";
import { ReactComponent as DocumentsIconActiveNetwell } from "../../assests/images/documents-icon-active.svg";
import { ReactComponent as MyNeedsIconUhf } from "../../assests/images/needs_icon_wh.svg";
import { ReactComponent as MyNeedsIconActiveUhf } from "../../assests/images/needs_icon_wh_active.svg";
import { isMobile } from "react-device-detect";
import i18n from 'i18next';
import { TranslationString } from "../../TranslationString";
import moment from "moment";


export const AiChatIcon = () => {
  return isMobile && process.env.REACT_APP_ENV_NAME === "UHF";
};

const footerData = {
  UHF: {
    // unauthenticated screens
    login: {
      label: "abcd",
      style: {
        padding: "2vw",
        color: "rgb(33, 37, 41)",
        fontFamily: ["Roboto", "sans-serif"].join(","),
      },
      middleSectionStyle: {
        fontSize: 12,
      },
      rightSectionStyle: {
        fontSize: 12,
      },
      middleSectionText: <TranslationString text="middleSectionTextUHF"/>,
      rightSectionText: <TranslationString text='versionUhf' />
    },
    // AFter login screens
    afterLogin: {
      label: "abc",
      style: {
        backgroundColor: "rgb(66, 73, 81)",
        color: "rgb(229, 231, 234)",
        fontFamily: ["Roboto", "sans-serif"].join(","),
      },
      leftSectionStyle: {
        whiteSpace: "normal",
      },
      leftSectionText:<TranslationString text='leftSectionTextUHF' />,
      rightSectionIconList: [
        {
          image: require("../../assests/images/uhf/footer/linkedin.svg")
            .default,
          alt: "Link image",
          link: "https://www.linkedin.com/company/universal-healthshare/",
        },
        {
          image: require("../../assests/images/uhf/footer/facebook.png"),
          alt: "facebook.png",
          link: "https://www.facebook.com/universalhealthshare",
        },
        {
          image: require("../../assests/images/uhf/footer/instagram.png"),
          alt: "instagram.png",
          link: "https://www.instagram.com/universalhealthshare/?hl=en",
        },
        {
          image: require("../../assests/images/uhf/footer/youtube.svg").default,
          alt: "youtube.svg",
          link: "https://www.youtube.com/channel/UCRok91gnhqFQMUt9ATnjf3A",
        },
      ],
      bottomNavgation: {
        bottomNavCallBack(data: any) {
          console.log(data);
        },
        selectedIndex: 0,
        styleBottomNav: {
          fontSize: " 2rem !important",
          ".MuiBottomNavigationAction-label": {
            marginTop: "1vw",
          },
          svg: {
            padding: 0,
          },
        },
        bottomIconList: [
          {
            icon: <DashboardIconUhf />,
            activeIcon: <DashboardIconActiveUhf />,
            label: "Dashboard",
          },
          {
            icon: <DocumentsIconUhf />,
            activeIcon: <DocumentsIconActiveUhf />,
            label: "Documents",
          },
          {
            icon: <MyNeedsIconUhf />,
            activeIcon: <MyNeedsIconActiveUhf />,
            label: "My Needs",
          },
        ],
      },
    },
  },
  NETWELL: {
    login: {
      label: "abc",
      style: {
        color: "#212529",
        padding: "2vw",
        fontFamily: ["Roboto", "sans-serif"].join(","),
      },
      middleSectionStyle: {
        fontSize: 12,
      },
      rightSectionStyle: {
        fontSize: 12,
      },
      middleSectionText:
        `Copyright © ${moment().format("YYYY")} netWell. All rights reserved. | Powered by CarynHealth.com`,
      rightSectionText: "Version 1.1.0",
    },
    afterLogin: {
      label: "abc",
      style: {
        backgroundColor: "rgb(227, 227, 227)",
        color: "rgb(126, 126, 126)",
        fontFamily: ["Roboto", "sans-serif"].join(","),
      },
      leftSectionStyle: {
        whiteSpace: "normal",
      },
      leftSectionText:
        `Copyright © ${moment().format("YYYY")} netWell. All rights reserved. | Powered by CarynHealth.com Version 1.1.0`,
      rightSectionIconList: [
        {
          image:
            require("../../assests/images/netwell/footer/linkedinBlack.svg")
              .default,
          alt: "Link image",
          link: "https://www.linkedin.com/company/netwell?original_referer=https%3A%2F%2Fwww.google.com%2F",
        },
        {
          image:
            require("../../assests/images/netwell/footer/facebookBlack.svg")
              .default,
          alt: "facebook.png",
          link: "https://www.facebook.com/mynetwell",
        },
        {
          image:
            require("../../assests/images/netwell/footer/instagramBlack.svg")
              .default,
          alt: "instagram.png",
          link: "https://www.instagram.com/mynetwell/",
        },
        {
          image: require("../../assests/images/netwell/footer/youtubeBlack.svg")
            .default,
          alt: "youtube.svg",
          link: "https://www.youtube.com/channel/UCRok91gnhqFQMUt9ATnjf3A",
        },
      ],
      bottomNavgation: {
        bottomNavCallBack: (data: any) => {
          console.log(data);
        },
        selectedIndex: 0,
        styleBottomNav: {
          fontSize: " 2rem !important",
          ".MuiBottomNavigationAction-label": {
            marginTop: "1vw",
          },
          svg: {
            padding: 0,
          },
        },
        bottomIconList: [
          {
            icon: <DashboardIconNetwell />,
            activeIcon: <DashboardIconActiveNetwell />,
            label: "Dashboard",
          },
          {
            icon: <DocumentsIconNetwell />,
            activeIcon: <DocumentsIconActiveNetwell />,
            label: "Documents",
          },
        ],
      },
    },
  },
  CURAMLIFE: {
    login: {
      label: "abcd",
      style: {
        padding: "2vw",
        color: "rgb(33, 37, 41)",
        fontFamily: ["Roboto", "sans-serif"].join(","),
      },
      middleSectionStyle: {
        fontSize: 12,
      },
      rightSectionStyle: {
        fontSize: 12,
      },
      middleSectionText: <TranslationString text="middleSectionTextCuramlife"/>,
      rightSectionText: <TranslationString text='versionCuramlife' />
    },
    // AFter login screens
    afterLogin: {
      label: "abc",
      style: {
        backgroundColor: "#e5e1e6",
        color: "rgb(126, 126, 126)",
        fontFamily: ["Roboto", "sans-serif"].join(","),
      },
      leftSectionStyle: {
        whiteSpace: "normal",
        color:'#000000'
      },
      leftSectionText:<TranslationString text='leftSectionTextCuramlife' />,
      rightSectionIconList: [
        {
          image: require("../../assests/images/curamlife/footer/linkedinBlack.svg")
            .default,
          alt: "Link image",
          link: "https://www.linkedin.com/company/carynhealth",
        },
        {
          image: require("../../assests/images/curamlife/footer/facebookBlack.svg").default,
          alt: "facebook.png",
          link: "https://www.facebook.com/CarynHealth/",
        },
        // {
        //   image: require("../../assests/images/curamlife/footer/instagramBlack.svg").default,
        //   alt: "instagram.png",
        //   link: "",
        // },
        // {
        //   image: require("../../assests/images/curamlife/footer/youtubeBlack.svg").default,
        //   alt: "youtube.svg",
        //   link: "",
        // },
      ],
      bottomNavgation: {
        bottomNavCallBack(data: any) {
          console.log(data);
        },
        selectedIndex: 0,
        styleBottomNav: {
          fontSize: " 2rem !important",
          ".MuiBottomNavigationAction-label": {
            marginTop: "1vw",
          },
          svg: {
            padding: 0,
          },
        },
        bottomIconList: [
          {
            icon: <DashboardIconUhf />,
            activeIcon: <DashboardIconActiveUhf />,
            label: "Dashboard",
          },
          {
            icon: <DocumentsIconUhf />,
            activeIcon: <DocumentsIconActiveUhf />,
            label: "Documents",
          },
        ],
      },
    },
  },
};

export const footerProps = (isLoggedIn: boolean, isThroughEmail: boolean) => {
  const data =
    footerData[(config.APP_NAME as keyof typeof footerData) || "NETWELL"];

  return data[isLoggedIn || isThroughEmail ? "afterLogin" : "login"];
};
