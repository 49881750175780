import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Addons, Button, StorageHelpers } from "carynhealth-component-library";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import { getEncryptData } from "../handlers";
import { getPlainIData } from "../../RightTopMemberTabs/handler";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import messages from "../../../config/constants/messages";
import { useTranslation } from "react-i18next";
import config from "../../../config/constants/config";

const isCuramLife = config.APP_NAME === config.BRAND_CURAMLIFE;

const AddonsStyle = styled(Grid)(({ theme }) => {
  return {
    ".manage-addons": {
      marginTop: 20,
      "&.disabled .MuiButton-root ": {
        color: "#ccc !important",
        borderColor: "#ccc",
      },
      ".MuiButtonBase-root": {
        width: 190,
        borderRadius: 30,
        border: "2px solid #41b5c2",
        fontFamily: "Oswald",
        fontSize: 20,
        fontWeight: 600,
        textAlign: "center",
        color: "#41b5c2 !important",
        backgroundColor: "#fff",
        padding: "1px 4px",
      },
    },
    ".addon_desc": {
      margin: "16px 16px 16px 0",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontWeight: "300",
      color: "#212529",
    },
    ".MontlyFee": {
      display: isCuramLife ? "none" : "auto",
    },
    
    [theme.breakpoints.down(426)]: {
      ".manage-addons": {
        ".MuiButtonBase-root": {
          padding: "3px 10px",
        },
      },
    },
  };
});

const AddonsServices: React.FC<{
  data: any;
  agentDetails: any;
  clientDetails: any;
  memberDetails: any;
}> = (props) => {
  const manageAddons = useCallback(async () => {
    try {
      const reqEncrypt = {
        clientId: props.clientDetails?.clientName || "",
        associationId: props.clientDetails?.associationId || "",
        brokerId: props.agentDetails?.brokerId || "",
        fromMember: true,
        isSelectProgram: true,
        user_subId: localStorage.getItem("userMail"),
        memberId: localStorage.getItem("sourceid"),
        subID: props.memberDetails?.subID || "",
      };
      await getEncryptData(reqEncrypt || {});
    } catch (error) {
      console.log("manageAddons Error:: ", error);
    }
  }, []);

  const isDiabled = useMemo(() => {
    return (
      [6548, 4367, 5540, 4376, 5541, 4377].includes(
        Number(StorageHelpers.getLocalStorage("CLIENT_ID"))
      ) || StorageHelpers.getLocalStorage("SOURCE") === "NEO"
    );
  }, []);
  console.clear();
  console.log(props);

  const rxSimpleShare = {
    membersRolled: props?.data?.addOns
      ?.map((val: any) => {
        return (val.addon == "UHS RxSimpleShare" ||
          val.addon == "RxSimpleShare") &&
          val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">{val.firstName + " " + val.lastName}</p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    effectiveDate: props?.data?.addOns
      ?.map((val: any) => {
        return (val.addon == "UHS RxSimpleShare" ||
          val.addon == "RxSimpleShare") &&
          val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">
              {moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}
            </p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    fee: (props?.data?.counterRxSimpleShare || 0) * 25,
  };

  const healthTools = {
    membersRolled: props?.data?.addOns
      ?.map((val: any) => {
        return [
          "UHS Health Tools",
          "Advanced Health Tools",
          "Health Tools",
        ].includes(val.addon) && val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">{val.firstName + " " + val.lastName}</p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
    effectiveDate: props?.data?.addOns
      ?.map((val: any) => {
        return [
          "UHS Health Tools",
          "Advanced Health Tools",
          "Health Tools",
        ].includes(val.addon) && val.status == "AC" ? (
          <div className="row" style={{ marginLeft: "0px" }}>
            <p className="AddOnsValue">
              {moment(val.addOnEffectiveDate).format("MMM, DD YYYY")}
            </p>
          </div>
        ) : null;
      })
      ?.filter(Boolean),
  };
  const [healthTitle, setHealthTitle] = useState("HealthTools");
  const name: string = getLocalStorage("fullName") || "";
  const { t } = useTranslation();
  const [healthDesc, setHealthDesc] = useState(
    t("ADDON_DESCRIPTIOON.HealthTools")
  );

  useEffect(() => {
    const planID: any = getLocalStorage("planId");
    if (getPlainIData(planID)) {
      setHealthTitle("AdvancedHealthTools");
      setHealthDesc(t("ADDON_DESCRIPTIOON.AdvancedHealthTools"));
    }
  }, []);

  return (
    <AddonsStyle
      id="pi-addons"
      className={`${config.APP_NAME}-addons`}
      container
    >
      <Grid className="ip_addon">
        {isCuramLife ? (
          <>
            {props?.data &&
              props?.data?.map((value: any, index: number) => (
                <Grid item xs={12} md={6} className="addon_grid" key={index}>
                  <Addons
                    active={true}
                    title={t(value.addonName)}
                    effectiveDate={[value.benefitBegin]}
                    membersRolled={[name]}
                    brand = 'curamlife'
                  />
                  <p className="addon_desc"></p>
                </Grid>
              ))}
          </>
        ) : (
          <>
            <Grid item xs={12} md={6} className="tetsting">
              <Addons
                title={t("RxSimpleShare")}
                active={props?.data?.rxSimpleShareActive}
                {...rxSimpleShare}
              />
              <p className="addon_desc">
                {t("ADDON_DESCRIPTIOON.RxSimpleShare")}
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <Addons
                title={t(healthTitle)}
                active={props?.data?.healthToolAddonActive}
                {...healthTools}
              />
              <p className="addon_desc">{healthDesc}</p>
            </Grid>
          </>
        )}
      </Grid>
      {!isCuramLife && (
        <Grid
          item
          xs={12}
          className={`manage-addons ${isDiabled ? "disabled" : ""}`}
        >
          <Button
            disabled={isDiabled}
            label={t("ManageAddOns")}
            variant="outlined"
            onClick={manageAddons}
          />
        </Grid>
      )}
    </AddonsStyle>
  );
};

export default AddonsServices;
