import { Grid } from "@mui/material";
import React from "react";
import { ComponentWrapper } from "./style";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SampleTextField from "../TextField/sampleTextField";
import { useTranslation } from "react-i18next";

export const HealthQuestion = (props: any) => {
  const { t } = useTranslation();
  const [questionData, setQuestionData] = React.useState<any[]>([]);

  React.useEffect(() => {
    getQuestionData();
  }, []);

  const getQuestionData = () => {
    const filterValue = "HEALTH";
    const result = props.questionList.filter((val: any) =>
      val.questionTypCode.includes(filterValue)
    );
    setQuestionData(result);
    checkDisable(result);
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    questionData[index].response = (event.target as HTMLInputElement).value;
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };
  const nameChangeHandler = (val: any, index: number) => {
    questionData[index].response = val;
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };
  const reltedChangeHandler = (val: any, index: number, respIndex: number) => {
    questionData[index].reltdQstnID[respIndex].response = val;
    questionData[index].reltdQstnID[respIndex].id =
      questionData[index].reltdQstnID[respIndex].id || "";
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };
  const checkDisable = async (result: any) => {
    let disableProp = await setDisableNext(result);
    props.disableNext(disableProp);
  };
  const setDisableNext = (result: any) => {
    let allRsp = result.some((x: any) => x.response == "");
    if (!allRsp) {
      if (
        result[2].response.toLowerCase() == "yes" &&
        result[2].reltdQstnID[0].response == ""
      ) {
        return true;
      } else if (
        result[3].response.toLowerCase() == "yes" &&
        result[3].reltdQstnID[0].response == ""
      ) {
        return true;
      }
      return false;
    }
    return true;
  };
  // const checkForNext = (data: any) => {
  //   let allRsp = data.some((x: any) => x.response == "");
  //   props.disableNext(allRsp);
  // };
  return (
    <Grid container spacing={2}>
      <Grid item lg={7}>
        <div className="custom-subtitle">{t(props?.instData?.title)}</div>
        <Grid container spacing={2} style={{ marginTop: "0" }}>
          <Grid container item xs={6} spacing={4}>
            <Grid item lg={9}>
              <div className="form-label">{questionData[0]?.questionDesc}</div>
            </Grid>
            <Grid item lg={3}>
              <SampleTextField
                setChild={(val) => nameChangeHandler(val, 0)}
                reqFlag={true}
                label={""}
                value={questionData[0]?.response}
                disable={props.ViewMode}
                length={2}
                errMsg={"No. Required"}
                helperMsg={
                  questionData[0]?.response != "" ? "" : "No. Required"
                }
                fieldType="num"
              />
            </Grid>
          </Grid>
          <Grid container item xs={6} spacing={4}>
            <Grid item lg={9}>
              <div className="form-label">{questionData[1]?.questionDesc}</div>
            </Grid>
            <Grid item lg={3}>
              <SampleTextField
                setChild={(val) => nameChangeHandler(val, 1)}
                reqFlag={true}
                label={""}
                value={questionData[1]?.response}
                disable={props.ViewMode}
                length={2}
                errMsg={"No. Required"}
                helperMsg={
                  questionData[1]?.response != "" ? "" : "No. Required"
                }
                fieldType="num"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "0" }}>
          <Grid item xs={6} spacing={4}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {questionData[2]?.questionDesc}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionData[2]?.response}
                onChange={(event) => handleChange(event, 2)}
              >
                {questionData[2]?.options.map((item: any) => (
                  <FormControlLabel
                    value={item.option}
                    control={
                      <Radio
                        disabled={props.ViewMode}
                        checked={questionData[2]?.response === item.option}
                      />
                    }
                    label={item.option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {questionData[2]?.response === "Yes" && (
              <Grid lg={6} className="multilineInput">
                <SampleTextField
                  setChild={(val) => reltedChangeHandler(val, 2, 0)}
                  reqFlag={true}
                  label={"List medication"}
                  value={questionData[2]?.reltdQstnID[0]?.response}
                  disable={props.ViewMode}
                  length={120}
                  errMsg={"Enter Valid Medication"}
                  helperMsg={
                    questionData[2]?.reltdQstnID[0]?.response
                      ? ""
                      : "Medication Required"
                  }
                  fieldType={"text"}
                  multiline={true}
                  rows={4}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={6} spacing={4}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {questionData[3]?.questionDesc}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionData[3]?.response}
                onChange={(event) => handleChange(event, 3)}
              >
                {questionData[3]?.options.map((item: any) => (
                  <FormControlLabel
                    value={item.option}
                    control={
                      <Radio
                        disabled={props.ViewMode}
                        checked={questionData[3]?.response === item.option}
                      />
                    }
                    label={item.option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {questionData[3]?.response === "Yes" && (
              <Grid lg={6} className="multilineInput">
                <SampleTextField
                  setChild={(val) => reltedChangeHandler(val, 3, 0)}
                  reqFlag={true}
                  label={"List Medical Equipment"}
                  value={questionData[3]?.reltdQstnID[0]?.response}
                  disable={props.ViewMode}
                  length={120}
                  errMsg={"Enter Valid Medical Equipment"}
                  helperMsg={
                    questionData[2]?.reltdQstnID[0]?.response
                      ? ""
                      : "Medical equipment Required"
                  }
                  fieldType={"text"}
                  multiline={true}
                  rows={4}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={5}>
        <Grid item xs={10}>
          <div className="custom-subtitle" style={{ marginTop: "10px" }}>
            {" "}
            {t('whyNeedThis')}
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: t(props.instData?.description),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
