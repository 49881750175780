import { apiService } from "carynhealth-component-library";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";

export const getMemberDetailsById = async (memberId: string) => {
  try {
    const URL = `${config.URLS.baseAPI}${config.URLS.getMemberDetails}${memberId}`;
    const { data: apiResponse } = await apiService.default.get({
      URL,
    });
    return apiResponse;
  } catch (error: any) {
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};

export const SetSaveMemberEsign = async (data: any) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.saveMemberEsign,
      brand: config.APP_NAME,
      data: data,
    };
    const { data: apiResponse } = await apiService.default.post(request);
    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);
    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const GetEsignData = async (data: any) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getEsignByMember + data,
      brand: config.APP_NAME,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);
    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};