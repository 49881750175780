import { useState } from "react";
import { Dialog, IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import config from "../../config/constants/config";

const StyleWrapper = styled("div")(() => {
  return {
    ".MuiCard-root": {
      marginBottom: 0,
      minWidth: 400,
    },
    ".MuiDivider-root": {
      border: "none",
    },
    ".NETWELL .MuiCardContent-root": {
      backgroundColor: "#4782c4",
    },
    ".UHF .MuiCardContent-root": {
      backgroundColor: "rgba(234,232,219,.4)",
    },
    ".cursor-hand-hover:hover": {
      backgroundColor: "#5f2161",
      ".only-description": {
        color: "#fff",
      },
    },
  };
});

export const DocumentList = (props:any) => {
  const [open, setOpen] = useState(props.open);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props?.onClick();
    setOpen(false);
  };

  return (
    <Dialog aria-describedby="alert-dialog-slide-description" open={open}>
      <StyleWrapper className={config.APP_NAME}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 3,
            top: 3,
          }}
        >
          <CloseIcon />
        </IconButton>
        {<props.Component />}
      </StyleWrapper>
    </Dialog>
  );
};
