import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {
  BackArrow,
  CardWrapper,
  InteractiveList,
  UpdatesCard,
} from "carynhealth-component-library";
import { getNotifcationsAPI, getNotifcationsData } from "./handler-data";
import TablePagination from "@mui/material/TablePagination";
import { DataType } from "./interfaces";
import { getRoutePaths } from "../../../config/constants/routePaths";
import config from "../../../config/constants/config";
import messages from "../../../config/constants/messages";
import { useTheme, useMediaQuery, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReturenTransString } from "../../../ReturenTransString";
import { getMemberDetailsById } from "../../../UI/dashboard/handler";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";

declare global {
  interface Window {
    pureChat: any;
  }
}
const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
const isCuramlife = config.APP_NAME === config.BRAND_CURAMLIFE;
const footerText = isNetwell
  ? messages.netwell.NEEDHELP_MSG
  : isCuramlife
  ? "NEEDHELP_MSG_CURAMLIFE"
  : "NEEDHELP_MSG";

const MyNotifications = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState<DataType>({
    notificationsList: [],
    count: 0,
    errorMessage: "",
  });
  const { t } = useTranslation();

  const notificationRequest = async () => {
    try {
      setLoading(true);
      // API CALL
      const notificationData = await getNotifcationsAPI();
      // Update the state
      setData(notificationData);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setData({ ...data, ...error });
    }
  };

  useEffect(() => {
    notificationRequest();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (window.location.href.includes(getRoutePaths.myNotifications)) {
    return (
      <Grid
        container
        display={showText ? "block" : "flex"}
        spacing={2}
        justifyContent="center"
      >
        <Grid xs={showText ? 12 : 10} sx={{ paddingTop: "20px" }}>
          <BackArrow
            brand={config.APP_NAME}
            onCallback={() => navigate(getRoutePaths.dashboard)}
          />
          <CardWrapper
            Component={() => (
              <>
                {data.errorMessage ? (
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      "@media screen and (max-width: 770px)": {
                        paddingTop: "20%",
                        marginLeft: "5px",
                      },
                      paddingTop: "10%",
                    }}
                  >
                    <p>{t(data.errorMessage)}</p>
                  </Grid>
                ) : data.notificationsList.length ? (
                  <>
                    <InteractiveList
                      onClick={console.log}
                      list={data.notificationsList}
                    />
                    <TablePagination
                      component="div"
                      count={data.notificationsList.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[]}
                      sx={{
                        ".Mui-disabled": {
                          color: "rgba(0, 0, 0, 0.26) !important",
                        },
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
            showChatIcon={process.env.REACT_APP_ENV_NAME !== "NETWELL"}
            title={t("MyNotifications")}
            openChatMethod={window.pureChat}
            footerText={t(footerText)}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <UpdatesCard
      image={getNotifcationsData.image}
      loading={loading}
      errorMessage={t(data.errorMessage)}
      title={t("MyNotifications")}
      href={getRoutePaths.myNotifications}
      viewBtnDisplay={true}
      Component={() => (
        <>
          <InteractiveList
            onClick={console.log}
            list={data.notificationsList}
          />
        </>
      )}
    />
  );
};

export default MyNotifications;
