import React from "react";
import { UpdatesCard } from "carynhealth-component-library";
import curamLife from "../../../assests/images/uhf/curam-life.svg";
import StyleWrapper from "./style";
import { getMemberHealthIdCard } from "../../ProgramInformation/handlers";
import { getCuramLife } from "./handler";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import moment from "moment";
import messages from "../../../config/constants/messages";
import { useMediaQuery, useTheme } from "@mui/material";
import routePaths from "../../../config/constants/routePaths";
import config from "../../../config/constants/config";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];

export interface CuramLifeCardobject {
  memberSSN: any;
  type: string;
}

const UHFCURAMDescription = () => {
  const [subscribed, setSubscribed] = React.useState(false);
  let [memberDetails, setMemberDetails] = React.useState([]);
  const { t } = useTranslation();
  
  React.useEffect(() => {
    getMemberSSN();
  }, []);

  const getMemberSSN = async () => {
    console.log(getLocalStorage("memberSSN"));

    if (
      getLocalStorage("memberSSN") !== "" &&
      getLocalStorage("memberSSN") !== null
    ) {
      getCuramLifeCard({
        memberSSN: getLocalStorage("memberSSN"),
        type: "family",
      });
    } else {
      await getMemberHealthIdCard().then((res) => {
        if (res) {
          getCuramLifeCard({
            memberSSN: getLocalStorage("memberSSN"),
            type: "family",
          });
        }
      });
    }
  };

  const getCuramLifeCard = (obj: CuramLifeCardobject) => {
    getCuramLife(obj).then((res) => {
      if (res.apiResponse.message == "Invalid Member SSN") {
        setSubscribed(false);
      } else {
        setMemberDetails([...memberDetails]);
        let subs =
          res.apiResponse?.length > 0 &&
          res.apiResponse.filter((x: any) => x.status == "Active - Billable")
            .length > 0
            ? true
            : false;
        setSubscribed(subs);
      }
    });
  };
  return (
    <StyleWrapper>
      <div className="The-UHF-Curam-Wellness-Center-1">
        {/* {messages.uhf.uhfCuram_first} */}
        {t('uhfCuram_first')}
        <span
          className="text-style-2"
          onClick={() =>
            window.open(
              "https://www.carynhealth.com/curamlife/"
            )
          }
        >
          {t('onlineApp')}
        </span>
        {/* {messages.uhf.uhfCuram_secound} */}
        {t('uhfCuram_secound')}
        <span
          className="text-style-2"
          onClick={() =>
            window.open(
              "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Curam-Wellness-Center-Promo-Collateral.pdf"
            )
          }
        >
          {t('here')}
        </span>
        <div className="text-style-1">
          {/* {messages.uhf.uhfCuram_Ques} */}
          {t('uhfCuram_Ques')}
          </div>
        <ul>
          <li className="unfListItem">
            {/* {messages.uhf.unfCuram_Ques_sub} */}
            {t('unfCuram_Ques_sub')}
            <span className="text-style-2">
              <a href="tel:888-282-8726">
                {/* {messages.uhf.unfCuram_AT_CURAM} */}
                {t('unfCuram_AT_CURAM')}
                <span className="CURAM_sub">
                  {/* {messages.uhf.unfCuram_AT_CURAM_sub} */}
                  {t('unfCuram_AT_CURAM_sub')}
                </span>
              </a>
            </span>
          </li>
          <li className="unfListItem">
            Email&nbsp;
            <span className="text-style-2">
              <a href="mailto: uhfmembers@curamlife.com">
                {/* {messages.uhf.unfCuram_Email} */}
                {t('unfCuram_Email')}
              </a>
            </span>
          </li>
          <li className="unfListItem">
            {/* {messages.uhf.unfCuram_call} */}
            {t('unfCuram_call')}
            <span className="text-style-2">
              <a
                href="https://www.universalhealthfellowship.org/enroll-curam-wellness-center/?mname=usttutella%20test&amp;memail=usttutella@testfor.33mail.com"
                target="_blank"
              >
                {t('here')}
              </a>
            </span>
          </li>
        </ul>
      </div>
    </StyleWrapper>
  );
};

const UHFCURAM = () => {
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { t } = useTranslation();
  
  return (
    <>
      {showText && location.pathname === routes.uhfCuram ? (
        <UHFCURAMDescription />
      ) : (
        <UpdatesCard
          image={curamLife}
          title={t('uhfCuram')}
          Component={() => <UHFCURAMDescription />}
        />
      )}
    </>
  );
};

export default React.memo(UHFCURAM);
