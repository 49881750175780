import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const TranslationString = (props: { text: string }) => {
  const { t } = useTranslation();
  // Replace YYYY with the current year
  const currentYear = moment().format("YYYY");
  const translatedText = t(props?.text).replace("YYYY", currentYear);

  return <text>{translatedText}</text>;
};
