import React, { useEffect, useState } from "react";
import {
  getLastDigitAccountNumber,
  getPaymentTpye,
  getReccuringDate,
  changeMyBillingDate,
} from "../handlers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { access } from "../data";
import { ApiLoader, Dialog } from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import messages from "../../../config/constants/messages";
import titles from "../../../config/constants/titles";
import StyleWrapper from "../Style";
import config from "../../../config/constants/config";
import { useTranslation } from "react-i18next";

const showHideNeedHelpFooter = (display = "block") => {
  //showHideFooter
  [".progInfoFooter", "#AI-chat_icon", "footer"].forEach((ref: string) => {
    const element = document.querySelector(ref) as HTMLDivElement;
    if (element?.style) element.style.display = display;
  });
};

const PaymentInformationMobile = (props: any) => {
  const navigate = useNavigate();
  const [CardDeatils, setCardDetails] = useState("");
  const [accountNo, setAccountno] = useState("");
  const [recurringDate, setRecurringDate] = useState(null);
  const [openModalChangePayment, setOpenModalchangePayment] = useState(false);
  const [changeBillingDate, setChangeBillingDate] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    showHideNeedHelpFooter("block");
    getpaymentMethod();
    getLastDigitAccount();
  }, []);
  const getLastDigitAccount = () => {
    setLoader(true);
    getLastDigitAccountNumber()
      .then((res) => {
        setLoader(false);
        setAccountno(
          res.data.response.replaceAll("*", "X").replaceAll("null", "****")
        );
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const getpaymentMethod = () => {
    setLoader(true);
    getPaymentTpye()
      .then((res) => {
        setLoader(false);
        setCardDetails(res.data.response.type);
        getReccuringDate().then((res) => {
          setRecurringDate(res.data.response.recurringDate);
        });
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  const changeBillingMethod = () => {
    setLoader(true);
    changeMyBillingDate()
      .then((res) => {
        setErrMessage(true);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const changePayementScreen = () => {
    showHideNeedHelpFooter("none");
    // Navigation
    navigate("/ChangePaymentScreen", { state: accountNo });
  };

  return (
    <div>
      <StyleWrapper>
        {loader && <ApiLoader />}
        {access.control.showPaymentInformation ? (
          <div style={{ padding: 32, backgroundColor: "#fff", paddingTop: 56 }}>
            <span className="Title">{titles.currentPaymentMethod}</span>
            <br />
            <span className="numberPaymentMethod">
              {access.control.showPaymentType ? CardDeatils : "N/A"}
            </span>
            <br />
            <div style={{ marginTop: "63px" }}>
              <table className="Last_4_digit">
                <td className="td_style1">{t('AccountNumberOnFile')}</td>
                <td className="td_style" style={{ verticalAlign: "middle" }}>
                  {accountNo ? accountNo.replaceAll("*", "X") : "****"}
                </td>
              </table>
            </div>
            <br />
            <button
              type="button"
              className="newblueActionBtn border-0 mr-2 text-uppercase"
              onClick={changePayementScreen}
            >
              {t('changePaymentMethod')}
            </button>
            <hr />
            {access.control.showChangeBillingDate && (
              <div>
                <span className="Title">{t('NextPaymentDueDate')}</span>
                <br />
                <span className="numberPaymentMethod">
                  {access.control.showNextPaymentDueDate
                    ? recurringDate &&
                      moment(recurringDate).format("MMM DD YYYY")
                    : "N/A"}
                </span>
                <div>
                  <br />
                  <button
                    type="button"
                    onClick={() => setChangeBillingDate(true)}
                    className="newblueActionBtn border-0 mr-2 text-uppercase"
                    style={{ marginTop: "10px" }}
                  >
                    {t('CHANGEBILLINGDATE')}
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{ padding: "180px 20px", textAlign: "center" }}
            className="NoPaymentInfoAvaible"
          >
            {messages.NoPaymentInfoAvaible}
          </div>
        )}
        {changeBillingDate && (
          <Dialog
            brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
            show={changeBillingDate}
            title={"Message"}
            body={() => <p>{t('ChangeBillingDateMessage')}</p>}
            CloseIcon={true}
            buttons={[
              {
                label: t('Proceed').toUpperCase(),
                value:"PROCEED",
                size: "large",
                variant: "text",
                type: "button",
                className: "PROCEED",
              } as CustomButtonProps,
              {
                label: t('Cancel').toUpperCase(),
                value:"CANCEL",
                size: "large",
                variant: "text",
                type: "button",
                className: "CANCEL",
              } as CustomButtonProps,
            ]}
            handleCallback={(data: any) => {
              if (data.value === "CANCEL") {
                setChangeBillingDate(false);
              } else if (data.value === "PROCEED") {
                changeBillingMethod();
                setChangeBillingDate(false);
              } else {
                setChangeBillingDate(false);
              }
            }}
          />
        )}
        {errMessage && (
          <Dialog
            brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
            show={errMessage}
            title={"Message"}
            body={() => <p>{t('SubmittedRequest')}</p>}
            CloseIcon={true}
            buttons={[
              {
                label: "OK",
                size: "large",
                variant: "text",
                type: "button",
                className: "OK",
              } as CustomButtonProps,
            ]}
            handleCallback={(data: any) => {
              setChangeBillingDate(false);
            }}
          />
        )}
      </StyleWrapper>
    </div>
  );
};
export default PaymentInformationMobile;
