import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import { ReturenTransString } from "../../ReturenTransString";

export const buttonProps = {
  QuesStep: [
    {
      label: ReturenTransString('BACK'),
      value:'BACK',
      size: "large",
      variant: "text",
      type: "button",
      className: "BACK",
    } as CustomButtonProps,
    {
      label: ReturenTransString('NEXT'),
      value:'NEXT',
      size: "large",
      variant: "text",
      type: "button",
      disabled: false,
      className: "NEXT",
    } as CustomButtonProps,
    {
      label:ReturenTransString('FinishLater'),
      value:"Finish Later",
      size: "large",
      variant: "text",
      type: "button",
      disabled: false,
      className: "FinishLater",
    } as CustomButtonProps,
  ],
  ViewStep: [
    {
      label: ReturenTransString('BACK'),
      value:'BACK',
      size: "large",
      variant: "text",
      type: "button",
      className: "BACK",
    } as CustomButtonProps,
    {
      label: ReturenTransString('NEXT'),
      value:'NEXT',
      size: "large",
      variant: "text",
      type: "button",
      disabled: false,
      className: "NEXT",
    } as CustomButtonProps,
  ],
  DataStep: [
    {
      label: ReturenTransString('BACK'),
      value:'BACK',
      size: "large",
      variant: "text",
      type: "button",
      className: "BackToDashboard",
    } as CustomButtonProps,
  ],
};
