import React, { useEffect, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CustomStyle from "./stylesheet_UHS";
import { TextFieldSample } from "carynhealth-component-library";
export interface SampleTextFieldProps {
  label?: string;
  name?: string;
  value?: string;
  disable?: boolean;
  reqFlag?: boolean;
  isValidFullName?: boolean;
  length?: number;
  fieldType: string;
  List?: Array<string | number | object>;
  renderList?: React.ComponentType;
  helperMsg?: string;
  errMsg?: string;
  required?: boolean;
  setChild?: (val: any) => void;
  error?: boolean;
  multiline?: boolean;
  rows?: number;
  id?: string;
}

const SampleTextField: React.FC<SampleTextFieldProps> = (props) => {
  const [error, setError] = useState(props.error || false);
  const [value, setValue] = useState("");
  const [errorText, setErrorText] = useState(props?.helperMsg);
  const [list, SetList] = useState(props.List);

  useEffect(() => {
    if (props?.List?.[0] instanceof Object) {
      let result = props.List?.map(
        (obj: any) => `${obj.firstName} ${obj.lastName}`
      );
      SetList(result);
    } else {
      SetList(props.List);
    }
  }, []);
 
  const onChangeHandler = (val: any) => {
    switch (props.fieldType.toLocaleLowerCase()) {
      case "any":
        if (val) {
          setValue(val);
          props.setChild && props.setChild(val);
          updateErrorMsg("", false);
        } else {
          setValue(val);
          props.setChild && props.setChild("");
          updateErrorMsg("", true);
        }
        break;
      case "select":
        setValue(val);
        props.setChild && props.setChild(val);
        break;

      case "email":
        if (
          val.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
          )
        ) {
          setValue(val);
          props.setChild && props.setChild(val);
          updateErrorMsg("", false);
        } else {
          setValue(val);
          props.setChild && props.setChild("");
          updateErrorMsg("Enter valid email", true);
        }
        break;
      case "holdername": {
        if (val.match(/^[a-zA-Z -_]{1,120}$/)) {
          setValue(val);
          props.setChild && props.setChild(val);
          updateErrorMsg("", false);
        } else {
          setValue(val);
          props.setChild && props.setChild("");
          checkRequiredError(val, "");
        }
        break;
      }
      case "fullname": {
        if (val.match(/^[a-zA-Z ]*$/)) {
          setValue(val);
          props.setChild && props.setChild(val);
          updateErrorMsg("", false);
        } else {
          setValue(val);
          props.setChild && props.setChild("");
          checkRequiredError(
            val,
            props.errMsg ? props.errMsg : "Enter Valid Value"
          );
        }
        break;
      }
      case "text": {
        if (val.match(/^[0-9a-zA-Z \n\-,]+$/)) {
          setValue(val);
          props.setChild && props.setChild(val);
          updateErrorMsg("", false);
        } else {
          setValue(val);
          props.setChild && props.setChild("");
          checkRequiredError(
            val,
            props.errMsg ? props.errMsg : "Enter Valid Value"
          );
        }
        break;
      }
      case "num": {
        if (val.match(/^[0-9]*$/)) {
          setValue(val);
          props.setChild && props.setChild(val);
          updateErrorMsg("", false);
        } else {
          setValue(val);
          props.setChild && props.setChild("");
          checkRequiredError(
            val,
            props?.errMsg ? props?.errMsg : "Enter Valid No."
          );
        }
        break;
      }
      case "phone": {
        if (val.match(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/)) {
          setValue(val);
          props.setChild && props.setChild(val);
          updateErrorMsg("", false);
        } else {
          if (val.includes("+")) {
            setValue(val);
          } else {
            setValue(`+${val}`);
          }
          props.setChild && props.setChild("");
          checkRequiredError(
            val,
            props?.errMsg ? props?.errMsg : "Enter Valid No."
          );
        }
        break;
      }
    }
  };

  const checkRequiredError = (val: string, msg: string) => {
    if (val === "") {
      if (props.reqFlag) {
        updateErrorMsg(msg, true);
      } else {
        updateErrorMsg("", false);
      }
    } else {
      updateErrorMsg(msg, true);
    }
  };

  const updateErrorMsg = (msg: string, valid: boolean) => {
    setErrorText(msg);
    setError(valid);
  };

  return (
    <CustomStyle>
      <TextFieldSample
        fieldType={props.fieldType.toLowerCase() === "select" ? "select" : "text"}
        InputProps={
          <InputAdornment position="end">
            <AccountCircle />
          </InputAdornment>
        }
        error={error}
        label={props.label}
        name={props.name}
        value={props.value && props.value !== "" ? props.value : value}
        helperText={errorText ? errorText : props.helperMsg}
        onChange={(e) => onChangeHandler(e.target.value)}
        disable={props.disable}
        reqFlag={props.reqFlag}
        InputLabelProps={{
          style: {
            color: value === "" && error ? "#FA1515" : "",
          },
        }}
        inputProps={{
          maxLength: props.length,
        }}
        List={list}
        length={props.length}
        multiline={props?.multiline ? props?.multiline : false}
        rows={props?.rows ? props?.rows : 1}
      />
    </CustomStyle>
  );
};

export default SampleTextField;
