import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import RoutesComponent from "./config/routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { awsConfig, parseJwt } from "./data";
import config from "./config/constants/config";
import { AuthContext } from "./context/AuthenticateContext";
import "./App.css";
import "carynhealth-component-library/dist/cjs/assests/css/common.css";
import "carynhealth-component-library/dist/cjs/assests/css/uhf.css";
import "carynhealth-component-library/dist/cjs/assests/css/netwell.css";
import 'carynhealth-component-library/dist/cjs/assests/css/curamlife.css';
import { RightTabs } from "./components/RightTopMemberTabs/RightTabs";
import { HeaderTab } from "./components/RightTopMemberTabs/HeaderTab";
import { useNavigate, useLocation } from "react-router-dom";
import routeConfig from "./config/routes/routeConfig";
import "./i18n/i18n";

Amplify.configure(awsConfig);
const brand = config.APP_NAME;

function App() {
  const [loading, setLoading] = useState(true);
  const auth = useContext(AuthContext);
  const theme = useTheme();
  const isMoblie = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();


  useEffect(() => {
    Auth.currentSession()
      .then((session: any) => {
        const authInfo = parseJwt(session.idToken.jwtToken);
        auth.updateAuthenticateInfo(authInfo);
        
        setLoading(false);
      })
      .catch((error: any) => {
        let url = window.location.href;
        let email: any = localStorage.getItem("userMail");
        const authNotReq = routeConfig.find(
          ({ authRequired, path }) =>
            path === location.pathname && !authRequired
        );
       if (!authNotReq) auth.resetAuthenticateInfo();
        else{
          localStorage.clear();
          sessionStorage.clear();
        }
        setLoading(false);
      });
  }, []);

  const GetHeaderTabs = () => {
    if (isMoblie) {
      return <HeaderTab />;
    }
    return <></>;
  };

  return (
    <>
      <div className={`${brand}`}>
        <Header />
        <GetHeaderTabs />
        <RightTabs />
        {loading ? null : <RoutesComponent />}
        <Footer />
      </div>
    </>
  );
}

export default App;
