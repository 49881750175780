import React, { useEffect, useState } from "react";
import MyTransactionTable from "./View/MyTransactionTable";
import {
  getEmployerAndHouseholdDetails,
  getPaymentTpye,
  getUserTransactionDetails,
} from "../handlers";
import PaymentInformationScreen from "./View/PaymentInformation";
import { access } from "../data";
import { ApiLoader } from "carynhealth-component-library";
import StyleWrapper from "../Style";

const TransactionView = (props: any) => {
  const [transactionData, setTransaction] = useState([]);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [CardDeatils, setCardDetails] = useState("");
  const [AccessControl, setAccessControl] = useState(access);
  useEffect(() => {
    setLoader(true);
    getTransactionDetails();
    getpaymentMethod();
    getAccessControlPaymentInfo();
  }, []);

  const getAccessControlPaymentInfo = async () => {
    let Access = AccessControl;
    AccessControl.control.type === "NETWELL" &&
      getEmployerAndHouseholdDetails().then((res) => {
        if (res.data.response.programType == "household") {
          Access.control.showPaymentInformation = true;
          setAccessControl(Access);
        }
      });
  };
  const getTransactionDetails = () => {
    getUserTransactionDetails()
      .then((response) => {
        if (response.data.response) {
          setTransaction(response.data.response);
          if (response.data.response.length == 0) {
            setMessage("My Transactions Data Not Available");
          }
        } else {
          setTransaction(response.data.pageList);
          if (response.data.pageList.length == 0) {
            setMessage("My Transactions Data Not Available");
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        setMessage("My Transactions Data Not Available");
        setLoader(false);
      });
  };
  const getpaymentMethod = () => {
    setLoader(true);
    getPaymentTpye()
      .then((res) => {
        setLoader(false);
        setCardDetails(res.data.response.type);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <>
      <StyleWrapper>
        {loader && <ApiLoader />}
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ width: "100%" }}>
            {transactionData.length > 0 ? (
              <MyTransactionTable
                {...props}
                transactionData={transactionData}
              />
            ) : (
              <div className="data_not_found" style={{ width: "100%" }}>
                <h5 className="noneeds_msg_display">{message}</h5>
              </div>
            )}
          </div>
          {AccessControl.control.showPaymentInformation && (
            <div style={{ width: "33%", zIndex: 0 }}>
              <PaymentInformationScreen
                {...props}
                cardDeatils={CardDeatils}
                resetPaymentMethod={() => getpaymentMethod()}
              />
            </div>
          )}
        </div>
      </StyleWrapper>
    </>
  );
};

export default TransactionView;
