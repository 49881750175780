import React, { useState, useTransition } from "react";
import { styled, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  BackArrow,
  MyReimbursementUpdate,
  MyShareRequests,
  ProductCardWrapper,
} from "carynhealth-component-library";
import MyNeedsTabs from "../../components/MyNeedsTabs";
import config from "../../config/constants/config";
import { getRoutePaths } from "../../config/constants/routePaths";
import messages from "../../config/constants/messages";
import titles from "../../config/constants/titles";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const dev = "dev";
declare global {
  interface Window {
    pureChat: any;
  }
}
const MyNeedsStyle = styled("div")(({ theme }) => {
  return {
    ".MuiCardContent-root .MuiButtonBase-root": {
      zIndex: "0"
    },
    ".MuiTabs-flexContainer > .MuiButtonBase-root": {
      zIndex: "0"
    },
    ".MuiTableBody-root .MuiSvgIcon-root": {
      zIndex: ""
    },
    [theme.breakpoints.down(768)]: {
      "#product-wrapper_component": {
        display: "block !important"
      },
      ".upperText": {
        fontSize: 12
      },
      ".product-wrapper_footer .btn-wrapper .MuiButtonBase-root": {
        fontSize: 12,
        padding: 10
      },
    },
    ".MuiTabs-flexContainer .MuiButtonBase-root": {
      zIndex: "0"
    },
    ".MuiTablePagination-actions .Mui-disabled.MuiButtonBase-root": {
      color: "#ccc"
    },
    ".table-bottom": {
      display: "flex",
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
    ".MuiTableCell-root.Pending": {
      backgroundColor: "transparent",
    },
    "&#myneeds-wrapper table th": {
      "&:nth-child(1)": {
        minWidth: 120,
      },
      "&:nth-child(2)": {
        minWidth: 200,
      },
      "&:nth-child(3)": {
        minWidth: 150,
      },
      "&:nth-child(4)": {
        minWidth: 120,
      },
      "&:nth-child(5)": {
        minWidth: 150,
      },
      "&:nth-child(6)": {
        minWidth: 150,
      },
      "&:nth-child(7)": {
        minWidth: 200,
        zIndex: "2"
      },
      "&:nth-child(8)": {
        minWidth: 200,
        zIndex: "2"
      },
      "&:nth-child(9)": {
        minWidth: 80,
      },
    },
    ".btn-wrapper > .MuiButtonBase-root": {
      fontWeight: 100,
      wordWrap: "break-word",
      padding: 5,
      maxWidth: 200,
      borderRadius: 28,
      margin: 6,
      lineHeight: 1.5,
      backgroundColor: "#4285f4",
      fontFamily: "inherit",
      fontSize: "1.1vw",
      boxShadow:
        "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important",
      transition:
        "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
      ":hover": {
        boxShadow:
          "0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15) !important",
      },
    },
    ".MuiPaper-root": {
      overflow: "scroll"
    }
  };
});

const MyNeeds = () => {
  const [defaultTab, setDefaultTab] = useState(0);
  const [selectedReference, setSelectedReference] = useState<any>(null);
  const theme = useTheme();
  const isMoblie = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const {t} = useTranslation();

  const setRef = (refData: any) => {
    const data =
      refData &&
      Object.keys(refData).reduce((acc, keyName) => {
        let valueOfKeyName: any = refData[keyName];
        if (valueOfKeyName === "NA") {
          valueOfKeyName = "";
        } else if (
          typeof valueOfKeyName === "string" &&
          valueOfKeyName.includes("$")
        ) {
          valueOfKeyName = valueOfKeyName.replace("$", "");
        } else if (valueOfKeyName && keyName === "serviceDate") {
          valueOfKeyName = moment(valueOfKeyName).format("YYYY-MM-DD"); //(new Date(valueOfKeyName));
          console.log("valueOfKeyName: ", valueOfKeyName);
        } else if (keyName === "data") {
          return { ...acc, ...valueOfKeyName };
        }
        return { ...acc, [keyName]: valueOfKeyName };
      }, {});
    data && setSelectedReference(data);
  };

  const resetRef = React.useCallback(() => {
    selectedReference && setDefaultTab(1);
    setSelectedReference(null);
  }, [selectedReference]);
  const openNewRequest = React.useCallback(() => setSelectedReference({}), []);
  const goBack = React.useCallback(() => navigate(getRoutePaths.dashboard), []);

  return (
    <section>
      <BackArrow
        brand={config.APP_NAME}
        onCallback={selectedReference ? resetRef : goBack}
      />
      <MyNeedsStyle id="myneeds-wrapper">
        {config.APP_NAME === config.BRAND_UHF ? (
          <>
            <ProductCardWrapper
              title={t('myNeeds')}
              footerText={t('NEEDHELP_MSG')}
              tabs={[]}
              Component={() => (
                <MyShareRequests
                  brand={
                    (config.APP_NAME.toLowerCase() || "netwell") as
                      | "netwell"
                      | "uhf"
                  }
                  mode={config.MODE}
                />
              )}
            />
          </>
        ) : (
          <>
            {selectedReference ? (
              <MyReimbursementUpdate
                selectedReference={selectedReference}
                onCallback={resetRef}
              />
            ) : (
              <ProductCardWrapper
                defaultTab={defaultTab}
                Component={(data) => <MyNeedsTabs setRef={setRef} {...data} />}
                footerText={messages.netwell.NEEDHELP_MSG}
                showChatIcon={false}
                tabs={[
                  {
                    label: "MY SHARE REQUESTS",
                    id: "my-share-requests",
                    style: { minWidth: "50%", fontSize: isMoblie ? "2.3vw" : "1.8vw" },
                  },
                  {
                    label: "MY REIMBURSEMENT REQUESTS",
                    id: "my-reimbursement-request",
                    style: { minWidth: "50%", fontSize: isMoblie ? "2.3vw" : "1.8vw" },
                  },
                ]}
                title=""
                buttons={[
                  {
                    label: "REIMBURSEMENT REQUEST",
                    onClick: openNewRequest,
                  },
                ]}
                openChatMethod={window.pureChat}
              />
            )}
          </>
        )}
      </MyNeedsStyle>
    </section>
  );
};

export default MyNeeds;
