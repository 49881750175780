import React from "react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoPopup from "../dashboard/VideoPopup";

const StyleWrapper = styled("div")(({ theme }) => {
  return {
    ".label-right": {
      textTransform: "none",
    },
    ".MuiListItem-root:hover": {
      background: "none !important",
      ".MuiListItemText-root .MuiListItemText-secondary span": {
        color: "rgb(78, 78, 78) !important",
      },
    },
    ".AnnouncInnerPage": {
      ".MuiListItem-root:hover": {
        background: "#eee !important",
        ".MuiListItemText-root .MuiListItemText-secondary span": {
          color: "rgb(78, 78, 78) !important",
        },
      },
    },
    ".AnnuocmentPopup": {
      borderRadius: "30px!important",
      border: "2px solid #41b5c2!important",
      backgroundColor: "#41b5c2!important",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      padding: "6px 16px",
      fontSize: "0.875rem",
      minWidth: 64,
      fontWeight: 500,
      lineHeight: 1.75,
      width: "13vw",
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
      color: "#fff",
    },
    [theme.breakpoints.down(475)]: {
      ".AnnuocmentPopup": {
        width: "40vw",
      },
    },
  };
});

const DialogPopup = ({ isShow, popupData, handleClose, handleOk }: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(false);
  };

  const openLink = () => {
    setOpen(true);
  };
  return (
    <Dialog open={isShow} maxWidth="xs">
      <DialogTitle
        sx={{
          color: "#5f2161",
          fontFamily: "Roboto",
          fontSize: 18,
          fontWeight: "500",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1,
          letterSpacing: 0.15,
        }}
      >
        {t(popupData?.description)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 5,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <div style={{ fontWeight: "bold" }}>{popupData?.date}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: popupData?.message ? t(popupData?.message) : "",
          }}
        />

        <div
          className="linkView"
          style={{
            cursor: "pointer",
            color: " rgb(65, 181, 194)",
            fontWeight: "400",
            marginTop: 26,
          }}
          onClick={openLink}
        >
          {t("ViewOnboardingVideo")}
        </div>

        {open && <VideoPopup isOpen={handleOpen} />}
      </DialogContent>
      <StyleWrapper>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button className="AnnuocmentPopup" autoFocus onClick={handleClose}>
            {t("Ok")}
          </Button>
          <Button className="AnnuocmentPopup" onClick={handleOk}>
            {t("ViewDocuments")}
          </Button>
        </DialogActions>
      </StyleWrapper>
    </Dialog>
  );
};

export default DialogPopup;
