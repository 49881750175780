import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  BackArrow,
  CardWrapper,
  InteractiveList,
  UpdatesCard,
} from "carynhealth-component-library";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import { getNotifcationsAPI, getNotifcationsData } from "./handler-data";
import { DataType, NotificationsList } from "./interfaces";
import config from "../../../config/constants/config";
import messages from "../../../config/constants/messages";
import { getRoutePaths } from "../../../config/constants/routePaths";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import DialogPopup from "../../../UI/DialogPopup";
import data from "../../Header/data";
import VideoPopup from "../../../UI/dashboard/VideoPopup";


const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
const isCuramlife = config.APP_NAME === config.BRAND_CURAMLIFE;
const headerData = { ...data[config.APP_NAME as keyof typeof data] };

declare global {
  interface Window {
    pureChat: any;
  }
}
const footerText = isNetwell
  ? messages.netwell.NEEDHELP_MSG
  : isCuramlife
  ? "NEEDHELP_MSG_CURAMLIFE"
  : "NEEDHELP_MSG";
/** ---- Points ----
 *
 * Not making API request for netwell
 */
const StyleWrapper = styled("div")(({ theme }) => {
  return {
    ".label-right": {
      textTransform: "none",
    },
    ".MuiListItem-root:hover": {
      background: "none !important",
      ".MuiListItemText-root .MuiListItemText-secondary span": {
        color: "rgb(78, 78, 78) !important",
      },
    },
    ".AnnouncInnerPage": {
      ".MuiListItem-root:hover": {
        background: "#eee !important",
        ".MuiListItemText-root .MuiListItemText-secondary span": {
          color: "rgb(78, 78, 78) !important",
        },
      },
    },
    ".AnnuocmentPopup": {
      borderRadius: "30px!important",
      border: "2px solid #41b5c2!important",
      backgroundColor: "#41b5c2!important",
      boxShadow:
        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
      padding: "6px 16px",
      fontSize: "0.875rem",
      minWidth: 64,
      fontWeight: 500,
      lineHeight: 1.75,
      width: "12vw",
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
      color: "#fff",
    },
    [theme.breakpoints.down(475)]: {
      ".AnnuocmentPopup": {
        width: "40vw",
      },
    },
  };
});
const Announcements = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType>({
    notificationsList: [],
    count: 0,
    errorMessage: t("NO_ANNOUNCEMENTS"),
  });
  const [isShow, setIsShow] = useState(false);
  const [popupData, setPopupData] = useState<NotificationsList>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const [sideBarObj, setSideBarObj] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);

  const notificationRequest = async () => {
    try {
      if (isNetwell) return;

      setLoading(true);

      // API CALL
      const notificationData = await getNotifcationsAPI();
      // Update the state
      notificationData.notificationsList?.length &&
        setData({ ...(notificationData || data), errorMessage: "" });

      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setData({ ...data, ...error });
      setLoading(false);
    }
  };

  const handleClick = useCallback((event: SyntheticEvent, data: any) => {
    if(data.message !== "ViewOnboardingVideo"){
      setIsShow(true);
      setPopupData(data);
    }else{
      setOpen(true);
    }
   
  }, []);

  useEffect(() => {
    notificationRequest();
  }, []);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setIsShow(false);
  };

  const handleOk = () => {
    setIsShow(false);
    let popupObj = headerData?.sideBarData?.listOfSideBar[3];
    setSideBarObj({ ...popupObj });
  };

  const handleOpen = () => {
    setOpen(false);
  };

  if (window.location.href.includes(getRoutePaths.announcements)) {
    
    if(sideBarObj?.component){
      const Comp = sideBarObj.component
      sideBarObj.component = React.cloneElement(Comp,{onClick:()=>closePopup()})
    }

    return (
      <Grid
        container
        display={showText ? "block" : "flex"}
        spacing={2}
        justifyContent="center"
      >
        <Grid xs={showText ? 12 : 10} sx={{ paddingTop: "20px" }}>
          <BackArrow
            brand={config.APP_NAME}
            onCallback={() => navigate(getRoutePaths.dashboard)}
          />

          <CardWrapper
            Component={() => (
              <>
                {data.errorMessage ? (
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      "@media screen and (max-width: 770px)": {
                        paddingTop: "20%",
                        marginLeft: "5px",
                      },
                      paddingTop: "10%",
                    }}
                  >
                    <p>{data.errorMessage}</p>
                  </Grid>
                ) : (
                  <StyleWrapper>
                    <span className="AnnouncInnerPage">
                      <InteractiveList
                        onClick={handleClick}
                        list={data.notificationsList}
                      />
                      <TablePagination
                        component="div"
                        count={data.notificationsList.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[]}
                        sx={{
                          ".Mui-disabled": {
                            color: "rgba(0, 0, 0, 0.26) !important",
                          },
                        }}
                      />
                    </span>
                  </StyleWrapper>
                )}
              </>
            )}
            showChatIcon={process.env.REACT_APP_ENV_NAME !== "NETWELL"}
            title={t("Announcements")}
            openChatMethod={() => window.pureChat()}
            footerText={t(footerText)}
          />
        </Grid>
        <DialogPopup
          isShow={isShow}
          popupData={popupData}
          handleClose={handleClose}
          handleOk={handleOk}
        />
       {open && <VideoPopup isOpen={handleOpen} />}
       {sideBarObj?.component && sideBarObj.component}
      </Grid>
    );
  }
  if(sideBarObj?.component){
    const Comp = sideBarObj.component
    sideBarObj.component = React.cloneElement(Comp,{onClick:()=>closePopup()})
  }
  function closePopup(){
    setSideBarObj(null)
   };
  return (
    <>
      <UpdatesCard
        image={getNotifcationsData.image}
        loading={loading}
        errorMessage={data.errorMessage}
        title={t("Announcements")}
        viewBtnDisplay={true}
        href={getRoutePaths.announcements}
        Component={() => (
          <StyleWrapper>
            <InteractiveList
              onClick={handleClick}
              list={data.notificationsList}
            />
          </StyleWrapper>
        )}
      />
      <DialogPopup
        isShow={isShow}
        popupData={popupData}
        handleClose={handleClose}
        handleOk={handleOk}
      />
       {sideBarObj?.component && sideBarObj.component}
       {open && <VideoPopup isOpen={handleOpen} />}
    </>
  );
};

export default Announcements;
