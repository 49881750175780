import React, {
  Component,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TableContainer,
  TextField,
} from "@mui/material";
import {
  Dialog,
  Loader,
  ProgramInfoFooter,
  useCommonLoader,
} from "carynhealth-component-library";
import {
  Button,
  CustomButtonProps,
} from "carynhealth-component-library/dist/cjs/components/Button";
import html2canvas from "html2canvas";

import Text from "../../config/constants/messages";
import { HipaaAuthQues } from "./data";
import SampleTextField from "../../components/TextField/sampleTextField";
import StyleWrapper from "./style";
import SignatureCanvas from "react-signature-canvas";
import TextSignature from "./TextSignature";
import {
  getEnrolledMemberDataAPI,
  getGateWayToken,
  getHippaAuth,
  getHippaAuthById,
  getHippaEsignPdf,
  getIpAddress,
  getUploadImage,
  SetSaveHippaPrivacyAuth,
} from "./handlers";
import moment from "moment";
import { checkType, MemberDataType } from "./interface";
import { AuthContext } from "../../context/AuthenticateContext";
import messages from "../../config/constants/messages";
import config from "../../config/constants/config";
const Platform = require("react-platform-js");

const relationList = ["PARENT", "LEGAL GUARDIAN"];

export const HipaaAuth = () => {
  const [signatureValue, setSignatureValue] = React.useState(
    "Use stylized script"
  );
  const [value, setValue] = React.useState("");
  const [checked, setChecked] = React.useState<any>({
    check0: false,
    check1: false,
    check2: false,
    check3: false,
  });
  const { check0, check1, check2, check3 } = checked;
  const signPad = React.useRef<any>();
  const [canvas, setCanvas] = useState<string | undefined>(undefined);
  const [fullName, setFullname] = useState("");
  const [relation, setRelation] = useState("");
  const [memberData, setMemberData] = useState<MemberDataType>();
  const { setLoading, unSetLoading } = useCommonLoader();
  const [isHippaError, setIsHippaError] = useState(false);
  const [msgBody, setMsgBody] = useState("");
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [canvasChanged, setCanvasChanged] = useState(true);
  const [otherHealthRecord, setOtherHealthRecord] = useState("");
  const [reqFlag, setReqFlag] = useState(true);
  let [imgUrl, setImgUrl] = useState("");
  const [showForm, setShowForm] = useState(true);
  const auth = useContext(AuthContext);

  React.useEffect(() => {
    getUserData();
  }, []);
  const getUserData = () => {
    getGateWayToken().then(() => {
      let url = window.location.href;
      if (url.split("HipaaAuthorization?").length > 1) {
        let queryString = url.split("HipaaAuthorization?")[1];
        let queryParams: any = new URLSearchParams(queryString);
        if (queryParams.has("state")) {
          let getState = decodeURI(queryParams?.get("state"));
          let data = JSON.parse(atob(getState));
          setMemberData(data);
          const authInfo = {
            ...auth.authenticatedInfo,
            email: data.email,
            name: data.memberName,
            isAuthenticated: false,
            throughEmail: true,
          };
          auth.updateAuthenticateInfo(authInfo || auth.authenticatedInfo);
          Request(data);
        }
      }
    });
  };

  const Request = (data: any) => {
    setLoading();
    try {
      getEnrolledMemberDataAPI(data?.primaryId?.toString()).then((values) => {
        let newRes =
          values?.apiResponse?.length &&
          values?.apiResponse?.filter(
            (res: { memberId: any }) => res.memberId == data?.memberId
          );
        if (newRes[0]?.status == "TE") {
          setIsHippaError(true);
          setShowForm(false);
          setMsgBody(`${Text.netwell.hipaaTerminated}`);
        } else {
          hipaaAuthData(data);
        }
      });
    } catch (error: any) {
      unSetLoading();
    }
  };

  const hipaaAuthData = (obj: any) => {
    getHippaAuthById(obj?.id).then((res) => {
      if (res?.HippaAuthData?.status == "Completed") {
        setMsgBody(
          `${Text.netwell.hipaaAlreadySubmitted} ${moment(
            res?.HippaAuthData?.hippaPrivacyAuthorization?.time
          ).format("MMMM DD YYYY")}`
        );
        setIsHippaError(true);
        setShowForm(false);
      } else {
        setIsHippaError(false);
        setMsgBody("");
      }
      unSetLoading();
    });
  };
  const nameChangeHandler = (val: any) => {
    setFullname(val);
  };

  const changeTextFieldHandler = useCallback((val: any) => {
    setRelation(val);
  }, []);

  const signatureHandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSignatureValue((event.target as HTMLInputElement).value);
    setCanvas("");
    setFullname("");
  };

  const HandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherHealthRecord("");
    setValue((event.target as HTMLInputElement).value);
    let temp = {
      check0: false,
      check1: false,
      check2: false,
      check3: false,
    };
    setChecked({ ...temp });
  };

  const checkHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const clearData = () => {
    signPad?.current.clear();
    setCanvasChanged(true);
    setCanvas("");
  };

  const handleSignDone = () => {
    let url = signPad?.current?.getTrimmedCanvas().toDataURL("image/png");
    setCanvasChanged(true);
    setCanvas(url);
    nameChangeHandler(fullName);
    return url;
  };

  const enableSubmit = () => {
    let disable = true;
    if (value !== "") {
      if (value === "2") {
        let allCheck = Object.values(checked).some((x) => x === true);
        if (allCheck) {
          disable = false;
          if (checked?.check3 && !otherHealthRecord) {
            return true;
          }
        } else {
          return true;
        }
      } else {
        disable = false;
      }
      if (
        ((memberData?.dependentNames != undefined ? relation != "" : true) && fullName)
         || (signatureValue === "Let me draw" &&
        canvas) 
      ) {
        disable = false;
      } else {
        disable = true;
      }
    }
    return disable;
  };

  const textSignatureToImage = () => {
    let node: any = document.getElementById("signTextContent");
    return html2canvas(node).then((canvas) => {
      return canvas.toDataURL("image/png");
    });
  };
  const dataURItoBlob = (dataURI: any) => {
    let byteString = atob(dataURI.split(",")[1]);

    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };
  const uploadImage = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file, `${new Date().getTime()}.png`);

    return await getUploadImage(formData).then((res: any) => {
      setImgUrl(res?.apiResponse?.response);
      return res?.apiResponse?.response;
    });
  };
  const saveHipaaAuth = async () => {
    setLoading();
    let extendAuth = {
      mental: checked.check0,
      communicable: checked.check1,
      alcohol: checked.check2,
      other: otherHealthRecord,
    };

    const date = new Date();
    const dateAsString: any = date?.toString();
    const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
    var matches = timezone.match(/\b(\w)/g);
    var abbreviations = matches?.join("");
    let ipAddress = null;

    await getIpAddress().then((res: any) => {
      console.log(res?.Data.IPv4);
      ipAddress = res?.Data.IPv4;
    });
    let imageUrl: any = "";

    if (signatureValue === "Use stylized script") {
      const img = await textSignatureToImage();
      imageUrl = await uploadImage(dataURItoBlob(img));
    } else {
      imageUrl = await uploadImage(dataURItoBlob(handleSignDone()));
    }

    let data = {
      name: memberData?.memberName,
      memberId: memberData?.memberId,
      authorizationName: "",
      signature: imageUrl,
      ipAddress,
      browser: Platform.default.Browser,
      device: Platform.default.DeviceType || "computer",
      os: Platform.default.OS,
      timestamp: abbreviations,
      relationship: relation,
      extendOfAuthorization: JSON.stringify(extendAuth),
      hippaAuthId: memberData?.id,
    };

    console.log("datatatata - -", data);
    SetSaveHippaPrivacyAuth(data).then((res) => {
      if (res.apiResponse.code == 200) {
        getHippaEsignPdf(memberData?.id)
          .then((res) => {
            setIsHippaError(true);
            setMsgBody(`${Text.netwell.hipaaSubmited}`);
            unSetLoading();
          })
          .catch((err) => {
            setIsHippaError(true);
            setMsgBody(`${Text.netwell.hipaaSubmited}`);
            unSetLoading();
          });
      } else {
        setIsHippaError(true);
        setMsgBody(`${Text.netwell.hipaaSavedAlready}`);
        unSetLoading();
      }
    });
  };

  const handleMessageModal = () => {
    setMsgBody("");
    setIsHippaError(false);
    window.open("", "_self", "");
    window.close();
  };

  return (
    <StyleWrapper>
      <header className="hipaaHeader">
        <div>
          <img
            className="hipaa_netwell_logo"
            src={require("../../assests/images/netwell/logo.png")}
          />
        </div>
      </header>
      <Loader />
      <div className="progInfoContainer" style={{ paddingTop: "101px" }}>
        <div className="commonWrap">
          <h2 className="progInfoTitle" style={{ color: "rgb(22, 34, 66)" }}>
            {Text.netwell.hippaTitle}
          </h2>
          <text>{Text.netwell.hipaaText}</text>
          {showForm && (
            <Box sx={{ width: "100%" }}>
              <div className="enrolledTableClass">
                <Paper className="tableContainer">
                  <TableContainer style={{ maxHeight: "52vh" }}>
                    <div style={{ overflow: "hidden" }}>
                      <div className="maindiv">
                        <text className="title">
                          1. {HipaaAuthQues.questionList[0].title}
                        </text>
                        <div>
                          <text
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: HipaaAuthQues.questionList[0].descipation,
                            }}
                          />
                        </div>
                      </div>
                      <div className="maindiv">
                        <text className="title">
                          2. {HipaaAuthQues.questionList[1].title}
                        </text>
                        <div>
                          <text
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: HipaaAuthQues.questionList[1].descipation,
                            }}
                          />
                        </div>
                      </div>
                      <div className="maindiv">
                        <text className="title">
                          3. {HipaaAuthQues.questionList[2].title}
                        </text>
                        <div>
                          <text
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: HipaaAuthQues.questionList[2].descipation,
                            }}
                          />

                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={HandleChange}
                              value={value}
                            >
                              {HipaaAuthQues?.questionList[2]?.subDescipation?.map(
                                (item: any, index) => (
                                  <>
                                    {index === 1 && (
                                      <div className="displayOr">OR</div>
                                    )}
                                    <FormControlLabel
                                      value={item.id}
                                      control={<Radio />}
                                      label={item.describe}
                                    />
                                  </>
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                          <FormGroup className="checkBoxDiv">
                            {HipaaAuthQues?.questionList[2]?.subDescipation[1]?.options?.map(
                              (label: string, index) => {
                                let checkKey = `check${index}`;
                                return (
                                  <div className="checkbox-Text">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={`check${index}`}
                                          onChange={checkHandleChange}
                                          checked={checked[checkKey]}
                                        />
                                      }
                                      label={label}
                                      disabled={value !== "2"}
                                    />
                                    {index === 3 && (
                                      <TextField
                                        sx={{
                                          ".MuiInputBase-root": {
                                            borderRadius: "unset !important",
                                          },
                                          ".MuiFilledInput-root:after": {
                                            borderBottom: "2px solid #3f51b5",
                                          },
                                          width: 300,
                                        }}
                                        id="filled-basic"
                                        label=""
                                        multiline
                                        maxRows={4}
                                        variant="filled"
                                        onChange={(e) => {
                                          setOtherHealthRecord(e.target.value);
                                        }}
                                        value={otherHealthRecord}
                                        disabled={!check3}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </FormGroup>
                        </div>
                      </div>
                      <div className="maindiv">
                        <text className="title">4. </text>
                        <text
                          className="desc"
                          style={{ marginLeft: "0" }}
                          dangerouslySetInnerHTML={{
                            __html: HipaaAuthQues.questionList[3].descipation,
                          }}
                        />
                      </div>
                      <div className="maindiv">
                        <text className="title">5. </text>
                        <text
                          className="desc"
                          style={{ marginLeft: "0" }}
                          dangerouslySetInnerHTML={{
                            __html: HipaaAuthQues.questionList[4].descipation,
                          }}
                        />
                      </div>
                      <div className="maindiv">
                        <text className="title">6. </text>
                        <text
                          className="desc"
                          style={{ marginLeft: "0" }}
                          dangerouslySetInnerHTML={{
                            __html: HipaaAuthQues.questionList[5].descipation,
                          }}
                        />
                      </div>
                      <Grid container xs={5.3} style={{ marginBottom: "20px" }}>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            backgroundColor: "rgb(244, 244, 244)",
                            ".MuiInputBase-root": {
                              borderRadius: "4px !important",
                            },
                            width: "100%",
                          }}
                          disabled
                          label={`Authorization for ${
                            memberData?.dependentNames || memberData?.memberName
                          }`}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid
                        container
                        xs={5.3}
                        className="FullNameInput"
                        style={
                          memberData?.dependentNames
                            ? { marginBottom: "20px" }
                            : { marginBottom: "50px" }
                        }
                      >
                        <SampleTextField
                          setChild={(val) => nameChangeHandler(val)}
                          name={"FullName"}
                          reqFlag={true}
                          label={
                            "Authorize this form by typing in your full name"
                          }
                          value={fullName}
                          disable={false}
                          length={50}
                          errMsg={"Enter valid full name"}
                          helperMsg={""}
                          fieldType={"holderName"}
                        />
                      </Grid>

                      {memberData?.dependentNames && (
                        <Grid
                          container
                          xs={5.3}
                          className="FullNameInput"
                          style={{ marginBottom: "50px" }}
                        >
                          <SampleTextField
                            fieldType={"select"}
                            setChild={(val) => changeTextFieldHandler(val)}
                            name={"relation"}
                            reqFlag={true}
                            label={"Relationship to Dependent"}
                            value={relation}
                            List={relationList}
                            disable={false}
                            length={50}
                            helperMsg={""}
                          />
                        </Grid>
                      )}

                      <div className="maindiv">
                        <text className="title">
                          {HipaaAuthQues.questionList[6].title}{" "}
                        </text>
                      </div>
                      <Grid container xs={5.3} className="signatureInput">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={signatureHandleChange}
                            value={signatureValue}
                          >
                            {HipaaAuthQues?.questionList[6]?.subDescipation[0]?.options?.map(
                              (item: any) => (
                                <>
                                  <FormControlLabel
                                    value={item}
                                    control={<Radio />}
                                    label={item}
                                  />
                                </>
                              )
                            )}
                          </RadioGroup>
                        </FormControl>
                        {signatureValue === "Let me draw" ? (
                          <>
                            <SignatureCanvas
                              canvasProps={{
                                width: 485,
                                height: 100,
                                className: "sigCanvas",
                              }}
                              ref={signPad}
                              onEnd={() => setCanvasChanged(false)}
                            />
                            <Stack
                              className="bottomBtn"
                              direction="row"
                              spacing={2}
                            >
                              <Button
                                label={"CLEAR"}
                                disabled={canvasChanged}
                                onClick={() => clearData()}
                              />
                              <Button
                                label={"DONE"}
                                disabled={canvasChanged}
                                onClick={() => handleSignDone()}
                              />
                            </Stack>
                            {/* {canvas && (
                              <img
                                width={"20%"}
                                height={"20%"}
                                src={canvas}
                                alt="signature"
                              />
                            )} */}
                          </>
                        ) : (
                          <TextSignature text={fullName} />
                        )}
                      </Grid>
                    </div>
                  </TableContainer>
                </Paper>
              </div>
            </Box>
          )}
        </div>
        <ProgramInfoFooter
          label={""}
          leftSectionbuttons={[
            {
              label: "SUBMIT",
              size: "large",
              variant: "text",
              type: "button",
              className: "SUBMIT",
              disabled: enableSubmit(),
              disableRipple: isDisableSubmit,
            } as CustomButtonProps,
          ]}
          rightSectionText={messages.netwell.NEEDHELP_MSG}
          handleCallback={(data: any) => {
            console.log(data);
            saveHipaaAuth();
          }}
        />
      </div>
      {isHippaError && (
        <Dialog
          brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
          show={true}
          title={"Message"}
          body={() => <p>{msgBody}</p>}
          CloseIcon={true}
          buttons={[
            {
              label: "OK",
              size: "large",
              variant: "text",
              type: "button",
              className: "OK",
            } as CustomButtonProps,
          ]}
          titleBackgroundColor={{ backgroundColor: "rgb(51, 175, 176)" }}
          handleCallback={(data: any) => {
            handleMessageModal();
          }}
        />
      )}
    </StyleWrapper>
  );
};
