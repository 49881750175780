import React from "react";
import { Grid } from "@mui/material";
import MyNeeds from "../../UI/MyNeeds";

const MyNeedsPage = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10}>
        <MyNeeds />
      </Grid>
    </Grid>
  );
};

export default MyNeedsPage;
