import React, { useCallback, useContext } from "react";
import { RightTabs as Tabs } from "carynhealth-component-library";
import HealthActive from "../../../assests/images/uhf/my_health_icon.png";
import MyHealth from "../../../assests/images/uhf/my_health.svg";
import myHealthicon from '../../../assests/images/curamlife/member_apps_icon.svg'
import { AuthContext } from "../../../context/AuthenticateContext";
import Style from "../style";
import Title from "../../../config/constants/titles";
import config from "../../../config/constants/config";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import {
  getIdCardDetails,
  getPlainIData,
  getRxSimpleShareData,
} from "../handler";
import { RightDrawer } from "../RightDrawer";
import { PopupContant } from "../PopupContant";
import Text from "../../../config/constants/messages";
import { useLocation } from "react-router-dom";
import routePaths, {
  getRoutePaths,
} from "../../../config/constants/routePaths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];
const isCuramlife = config.APP_NAME === config.BRAND_CURAMLIFE;

export const RightTabs = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [yellowPopup, setYellowPopup] = React.useState(true);
  const [planId, setPlanId] = React.useState("");
  const [pharmaShow, setPharmaShow] = React.useState(false);
  const { t } = useTranslation();
  const lang = getLocalStorage('Language')

  React.useEffect(() => {
    !isCuramlife && setPopupValue();
  }, [auth.authenticatedInfo.isAuthenticated, location.pathname]);
  React.useEffect(() => {
    console.log("===", location.pathname, getRoutePaths.aiChat);
  }, []);

  const setPopupValue = async () => {
    const email: string | any = getLocalStorage("userMail");
    await getIdCardDetails(email).then((res) => {
      const planId = res.apiResponse.memberIdCardList[0].planId;
      setLocalStorage("planId", planId);
      setPlanId(planId);
      if (!getLocalStorage("popupShow1")) {
        if (getPlainIData(planId)) {
          setYellowPopup(false);
          setLocalStorage("popupShow1", false);
        } else {
          setYellowPopup(true);
          setLocalStorage("popupShow1", true);
        }
      }

      setYellowPopup(getLocalStorage("popupShow1") == "true" ? true : false);

      getSimpleShareData(res.apiResponse.memberIdCardList[0].memberSSN);
    });
  };

  const getSimpleShareData = (memberSSN: any) => {
    let data = {
      memberSSN: memberSSN,
      type: "family",
    };
    getRxSimpleShareData(data).then((res) => {
      if (res.apiResponse && Array.isArray(res.apiResponse)) {
        res.apiResponse.map((value) => {
          if (value.addon === "UHS RxSimpleShare" && value.status === "AC") {
            setPharmaShow(true);
          }
        });
      } else {
        setPharmaShow(false);
      }
    });
  };

  const toggleMemberServices = (data: any) => {
    if (location.pathname == getRoutePaths.aiChat)
      navigate(getRoutePaths.dashboard);
    else navigate(getRoutePaths.aiChat);
  };

  if (!auth.authenticatedInfo.isAuthenticated || config.APP_NAME == "NETWELL")
    return null;

  return (
    <Style key={Math.random()}>
      <Tabs
        data={[
          {
            image: myHealthicon,
            text: t('healthWellness'),
            Component: <RightDrawer pharmaShow={pharmaShow} planID={planId} />,
            style: {
              backgroundColor:
                yellowPopup || pharmaShow ? "#ff8d6d" : "#989897",
              pointerEvents: yellowPopup || pharmaShow ? "auto" : "none",
              height:"16vw",
            },
          }
        ]}
        callBack={(data: any) => toggleMemberServices(data)}
      />
    </Style>
  );
};
