import { apiService } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import messages from "../../../config/constants/messages";

export const getHealthqnInfo = async (id: number) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getHeathInfoPopup,
      brand: config.APP_NAME,
      data: { subscriberIdSource: id },
    };
    const { data: apiResponse } = await apiService.default.post(request);
    return {
      apiResponse,
      errorMessage: apiResponse.response ? "" : messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};
