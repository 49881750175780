import { ReturenTransString } from "../../../ReturenTransString";

export const AnnouncementInfo = {
    CURAMLIFE:{
        notificationDetails:[
            {
                "id": 9999999,
                "title": "getAnnouncemetDetails.title04",
                "message": "getAnnouncemetDetails.message04",
                "type": ReturenTransString('NOTICE'),
                "listType":'NOTICE',
                "createdDate": "2023-10-10T05:30:00.000+00:00",
                "updated_date": "2023-10-10T14:58:06.000+00:00"
            },
            {
                "id": 9999999,
                "title": "ViewOnboardingVideo",
                "message": "ViewOnboardingVideo",
                "type": ReturenTransString('NOTICE'),
                "listType":'NOTICE',
                "createdDate": "2023-10-10T05:30:00.000+00:00",
                "updated_date": "2023-10-10T14:58:06.000+00:00"
            }
        ]
        
      }
}