import { getLocalStorage, setLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import Style from "./style";
import Text from "../../config/constants/messages";
import { useTranslation } from "react-i18next";

export const PopupContant = ({ close }: any) => {
  const { t } = useTranslation();
  const lang = getLocalStorage('Language')

  console.log(close);
  function setClose() {
    setLocalStorage("popupShow1", false);
    close();
  }
  return (
    <Style>
      <div className="popupContant">
        <div className="web_checkoutboldtext" style={lang==='en'?{fontSize:'1.42vw',lineHeight:'1.5'}:{}}>
          {/* {Text.uhf.checkoutboldtext} */}
          {t('checkoutboldtext')}
          </div>
        <div
          className="web_tooltip_2nd_text"
          dangerouslySetInnerHTML={{
            __html: t('tooltipText') //Text.uhf.tooltipText,
          }}
          style={lang==='en'?{fontSize:'1.2vw'}:{}}
        />
        <button className="yellow_popup_caption_button" style={lang==='en'?{fontSize:'.87vw',padding: "1em",top:'48%'}:{}} onClick={setClose}>
          {t('CLOSE')}
        </button>
      </div>
    </Style>
  );
};
