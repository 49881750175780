import React, { useEffect, useState, useCallback, SyntheticEvent } from "react";
import {
  InteractiveList,
  StorageHelpers,
  UpdatesCard,
} from "carynhealth-component-library";
import {
  getDocuments,
  getDocumentsDataByID,
  getDocumentTileDetails,
  getWelcomeBookletLink,
} from "./handlers";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import config from "../../../config/constants/config";
import UHFDocuments from "../../../assests/images/uhf/documents.svg";
import CuramlifeDocuments from "../../../assests/images/curamlife/documents.svg";
import NetwellDocuments from "../../../assests/images/netwell/documents.svg";
import titles from "../../../config/constants/titles";
import { Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import routePaths from "../../../config/constants/routePaths";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

const brand = config.APP_NAME;
const routes = routePaths[config.APP_NAME as keyof typeof routePaths];
const isCuramLife = config.APP_NAME === config.BRAND_CURAMLIFE;

const StyleWrapper = styled("div")(() => {
  return {
    ".list-wrapper": {
      height: "52vh",
      overflow: "scroll",
      ".only-description": {
        fontSize: "4.1vw",
      },
    },
    ".card_errorMessage": {
      display: "flex",
      justifyContent: "center",
      paddingTop: 130,
    },
  };
});
const Documents = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const planId: any = getLocalStorage("planId");
  const [error, setError] = useState("");
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const getDocumentsData = async () => {
    setLoading(true);
    if (brand === config.BRAND_CURAMLIFE) {
      let response = getDocuments({
        clientId: "1",
        disableUHFWelcome: false,
        disableUHFSharing: false,
      });
      setData(response);
    } else {
      await getDocumentsDataByID(planId)
        .then((res) => {
          setData(res.apiResponse);
          setError(res.errorMessage);
        })
        .catch((error) => console.log(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    getDocumentsData();
  }, []);

  const openLink = useCallback((event: SyntheticEvent, docInfo: any) => {
  const lang = getLocalStorage("Language");

    if (lang === "es" && isCuramLife) {
      docInfo.activeFlag &&
        docInfo.SPDocument &&
        window.open(docInfo.SPDocument);
    } else {
      docInfo.activeFlag && docInfo.document && window.open(docInfo.document);
    }
  }, []);

  return (
    <>
      {showText && location.pathname === routes.documents ? (
        <StyleWrapper>
          {data.length > 0 ? (
            <InteractiveList onClick={openLink} list={data} />
          ) : (
            <span className="card_errorMessage">{error}</span>
          )}
        </StyleWrapper>
      ) : (
        <UpdatesCard
          image={
            brand === config.BRAND_NETWELL
              ? NetwellDocuments
              : brand === config.BRAND_CURAMLIFE
              ? CuramlifeDocuments
              : UHFDocuments
          }
          loading={loading}
          title={t("Documents")}
          Component={() => (
            <>
              {data.length > 0 ? (
                <InteractiveList onClick={openLink} list={data} />
              ) : (
                <p className="card_errorMessage">{error}</p>
              )}
            </>
          )}
        />
      )}
    </>
  );
};

export default Documents;
