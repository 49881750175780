import React, { useCallback, useContext, useEffect, useState } from "react";
import StyleWrapper from "./style";
import {
  ProgramInfoFooter,
  useCommonLoader,
} from "carynhealth-component-library";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getEnrolledMemberDataAPI,
  getHippaAuth,
  updateHippaStatus,
} from "./handlers";
import Text from "../../config/constants/messages";
import Sample from "../../components/TextField/sampleTextField";
import { AuthContext } from "../../context/AuthenticateContext";
import config from "../../config/constants/config";
import { Dialog } from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { useTranslation } from "react-i18next";

const HippaCard = () => {
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.up("md"));
  const { setLoading, unSetLoading } = useCommonLoader();
  const [memberData, setHipaaMemberData] = useState<any[]>([]);
  const auth = useContext(AuthContext);
  const [hippaAuthData, setHippaAuthData] = useState<any[]>([]);
  const [adultMembers, setAdultMembers] = useState<any[]>([]);
  const [childMembers, setChildMembers] = useState<any[]>([]);
  const [adultSelected, setAdultSelected] = useState<any>(null);
  const [adultValue, setAdultValue] = useState<any>(null);
  const [enteredChildEmail, setEnteredChildEmail] = useState<string>("");
  const [isHippaSent, setIsHippaSent] = useState<boolean>(false);
  const [msgBody, setMsgBody] = useState("");
  const {t} =useTranslation()

  useEffect(() => {
    if (memberData.length == 0) {
      Request();
    }
  }, [isHippaSent]);

  const Request = async () => {
    setLoading();
    try {
      Promise.all([
        getEnrolledMemberDataAPI(getLocalStorage("sourceid")),
        getHippaAuth(auth.authenticatedInfo.email),
      ]).then(function (values) {
        values[0]?.apiResponse?.length &&
          setHipaaMemberData(values[0]?.apiResponse);
        values[1]?.HippaAuthData?.length &&
          setHippaAuthData(values[1]?.HippaAuthData);
        mapMemberData(values[0]?.apiResponse, values[1]?.HippaAuthData);
      });
    } catch (error: any) {
      unSetLoading();
    }
  };

  const mapMemberData = (mData: Array<object>, hippaData: any) => {
    let newData = mData.map((item: any) => {
      let hippaObj: any = hippaData.find(
        (x: any) => x.memberId === item.memberId
      );
      let temp: any = {
        age: getAge(item.dateOfBirth),
        hipaaStatus: hippaObj ? hippaObj.status : "PENDING",
        authorizedBy: hippaObj ? hippaObj.authorizedBy : "Self",
        isValidMemberEmail: true,
      };

      if (hippaObj) {
        temp["email"] = hippaObj.email;
        temp["id"] = hippaObj.id;
      }
      return Object.assign({}, item, temp);
    });

    let filtered: Array<object> = newData.filter(
      (member) =>
        member.status !== "TE" ||
        (member.status === "TE" && member.hipaaStatus === "Completed")
    );

    let groupByList: any = groupBy(filtered, "age");
    let temp = hippaData.filter((el: any) => el.authorizedBy !== "Self");
    let lastIndex: any = temp.length && Object.assign({}, temp[0]);

    if (groupByList?.child?.length && lastIndex) {
      groupByList.child[0].hipaaStatus = lastIndex.status;
      groupByList.child[0]["email"] = lastIndex.email;
      groupByList.child[0].memberName = lastIndex.memberName;
      groupByList.child[0].authorizedBy = lastIndex.authorizedBy;
      groupByList.child[0].id = lastIndex.id;
      setEnteredChildEmail(lastIndex.email || "");
      setAdultSelected(lastIndex);
    }

    groupByList?.adult && setAdultMembers(groupByList.adult);
    groupByList?.child && setChildMembers(groupByList.child);
    unSetLoading();
  };

  const getAge = (dateString: number) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  function groupBy(objectArray: Array<object>, property: any) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj[property] >= 18 ? "adult" : "child";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const changeTextFieldHandler = useCallback((val: any, list: any) => {
    let result = list.find((ele: any) => val.includes(ele.firstName));
    result["name"] = `${result?.firstName} ${result?.lastName}`;
    setAdultValue(val);
    setAdultSelected(result);
    setEnteredChildEmail(result.email || "");
  }, []);

  const updateChildMail = (val: string) => {
    setEnteredChildEmail(val);
  };

  const getTimeZone = () => {
    const date: any = new Date().toString();
    const timezone = date.match(/\(([^\)]+)\)$/)[1];
    var matches = timezone.match(/\b(\w)/g);
    return matches.join("");
  };

  const updateAdultStatus = async (member: any) => {
    setLoading();
    let saveData = {
      name: member?.firstName + " " + member?.lastName,
      memberId: member?.memberId,
      authorizedBy: member?.authorizedBy,
      email: member?.email,
      status: "In Progress",
      timestamp: getTimeZone(),
      primaryEmail: member.primaryEmail || memberData[0]?.email,
    };

    if (member.hipaaStatus == "In Progress") {
      sendAdultHippaAuthentication(saveData, member);
    } else {
      let resp = await updateHippaStatus(
        saveData,
        config.URLS.saveHippaAuthentication
      );
      if (resp?.apiResponse?.code === 200) {
        member["id"] = resp.apiResponse.response.id;
        sendAdultHippaAuthentication(saveData, member);
      }
    }
  };

  const sendAdultHippaAuthentication = async (saveData: any, member: any) => {
    let urlData = {
      memberName: member.firstName + " " + member.lastName,
      id: member.id,
      primaryId: memberData[0]?.memberIdSource,
      ...saveData,
    };
    let data = {
      url: `${config.URLS.netwellMemberRefactor}${
        config.URLS.hippaAuthorization
      }${btoa(JSON.stringify(urlData))}`,
      selfAuthorization: true,
      dependentNames: "",
      ...urlData,
    };
    let resp = await updateHippaStatus(
      data,
      config.URLS.sendHippaAuthentication
    );
    if (resp?.apiResponse?.code === 200) {
      setIsHippaSent(true);
      setMsgBody(`${Text.netwell.hipaaSend} ${saveData.email}`);
    } else {
      setIsHippaSent(true);
      setMsgBody(`${Text.netwell.hipaaNotFound}`);
    }
    unSetLoading();
  };

  const updateChildStatus = async () => {
    setLoading();
    let depedentNames: Array<string> = [];
    let depedentIds: Array<string> = [];
    childMembers.forEach((child) => {
      depedentNames.push(`${child?.firstName} ${child?.lastName}`);
      depedentIds.push(child.memberId);
    });
    let saveData: any = {
      name: depedentNames.toString(),
      memberId: depedentIds.toString(),
      authorizedBy: adultSelected && adultSelected?.name,
      email: enteredChildEmail,
      status: "In Progress",
      timestamp: getTimeZone(),
      primaryEmail: memberData[0]?.email,
      primaryId: memberData[0]?.memberIdSource,
      dependentNames: depedentNames.toString(),
    };

    if (childMembers[0].hipaaStatus == "In Progress") {
      saveData["memberName"] = adultSelected?.name;
      saveData["memberId"] = adultSelected?.memberId;
      saveData["id"] = adultSelected?.id || childMembers[0]?.id;
      sendChildHippaAuthentication(saveData);
    } else {
      let resp = await updateHippaStatus(
        saveData,
        config.URLS.saveHippaAuthentication
      );
      if (resp?.apiResponse?.code === 200) {
        saveData["memberName"] = resp?.apiResponse?.response?.name;
        saveData["memberId"] = resp?.apiResponse?.response?.memberId;
        saveData["id"] = resp?.apiResponse?.response?.id;
        sendChildHippaAuthentication(saveData);
      }
    }
  };

  const sendChildHippaAuthentication = async (saveData: any) => {
    let urlData = {
      ...saveData,
    };
    let data = {
      url: `${config.URLS.netwellMemberRefactor}${
        config.URLS.hippaAuthorization
      }${btoa(JSON.stringify(urlData))}`,
      selfAuthorization: false,
      ...urlData,
    };
    let resp = await updateHippaStatus(
      data,
      config.URLS.sendHippaAuthentication
    );
    if (resp?.apiResponse?.code === 200) {
      setIsHippaSent(true);
      setMsgBody(`${Text.netwell.hipaaSend} ${saveData.email}`);
    } else {
      setIsHippaSent(true);
      setMsgBody(`${Text.netwell.hipaaNotFound}`);
    }
    unSetLoading();
  };

  const updateAdultEmail = (val: string, index: number) => {
    let tempAdults = adultMembers;
    tempAdults[index].email = val;
    setAdultMembers([...tempAdults]);
  };

  function disableChildButton() {
    if (enteredChildEmail == "") {
      return true;
    }
    return false;
  }

  function handleCloseClick() {
    setMsgBody("");
    setIsHippaSent(false);
    Request();
  }

  return (
    <StyleWrapper>
      <div className="progInfoContainer">
        <span className="Back_page_netwell">
          <Link href="/dashboard">
            <ArrowBackIcon
              style={{
                width: 24,
                height: 24,
                color: "#4782c4",
                marginRight: 5,
              }}
            />
            {t('BACK')}
          </Link>
        </span>

        <div className="commonWrap">
          <h2 className="progInfoTitle" style={{ color: "rgb(22, 34, 66)" }}>
            {Text.netwell.hippaTitle}
          </h2>
          <text>{Text.netwell.hipaaText}</text>
          <Box sx={{ width: "100%" }}>
            <div className="enrolledTableClass">
              <Paper className="tableContainer">
                <TableContainer>
                  <Table
                    className="mainTable "
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                    stickyHeader
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box>
                            <TableRow className="MuiTableCellclass">
                              <TableCell
                                align="left"
                                className="innerTh"
                                style={{ width: "581px" }}
                              >
                                NAME
                              </TableCell>
                              <TableCell
                                align="left"
                                className="innerTh"
                                style={{ width: "900px" }}
                              >
                                AUTHORIZED BY
                              </TableCell>
                              <TableCell
                                align="left"
                                className="innerTh"
                                style={{ width: "300px" }}
                              >
                                EMAIL
                              </TableCell>
                              <TableCell
                                align="left"
                                className="innerTh"
                                style={{ width: "1000px" }}
                              >
                                STATUS
                              </TableCell>
                            </TableRow>
                            <TableBody>
                              {Array.from(adultMembers).map((member, index) => {
                                return (
                                  <TableRow
                                    key={index}
                                    className="MuiTableCellclass"
                                  >
                                    <TableCell className="TableCell">
                                      {`${member.firstName} ${member.lastName}`}
                                    </TableCell>
                                    <TableCell className="TableCell">
                                      {member.authorizedBy}
                                    </TableCell>
                                    {member.hipaaStatus == "PENDING" &&
                                    index != 0 ? (
                                      <TableCell className="TableCell">
                                        <Sample
                                          setChild={(val) =>
                                            updateAdultEmail(val, index)
                                          }
                                          name={"memberEmail"}
                                          isValidFullName={
                                            member.isValidMemberEmail
                                          }
                                          reqFlag={true}
                                          label={"Email Address"}
                                          value={member.email}
                                          disable={false}
                                          // className="textFieldTable"
                                          length={120}
                                          fieldType={"email"}
                                          errMsg={"Enter valid email"}
                                          helperMsg={""}
                                          // parentDetails={{
                                          //   name: "memberEmail",
                                          // }}
                                        ></Sample>
                                      </TableCell>
                                    ) : (
                                      <TableCell className="TableCell">
                                        {member.email}
                                      </TableCell>
                                    )}

                                    <TableCell className="TableCell">
                                      <Grid
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          width: !showText ? "280px" : "",
                                        }}
                                      >
                                        <text
                                          className={
                                            member.hipaaStatus === "PENDING"
                                              ? "statusCell pending"
                                              : member.hipaaStatus ===
                                                "In Progress"
                                              ? "statusCell inProgress"
                                              : "statusCell completed"
                                          }
                                        >
                                          {member?.hipaaStatus?.toUpperCase()}
                                        </text>
                                        <div>
                                          {member?.hipaaStatus !=
                                            "Completed" && (
                                            <button
                                              disabled={
                                                member?.email === "" ||
                                                member?.email === null
                                              }
                                              style={{
                                                color:
                                                  member?.email === "" ||
                                                  member?.email === null
                                                    ? "#e8e8e8"
                                                    : "#4782c4",
                                                borderColor:
                                                  member?.email === "" ||
                                                  member?.email === null
                                                    ? "#e8e8e8"
                                                    : "#4782c4",
                                                fontSize: "15px",
                                                cursor: "pointer",
                                                padding: "5px",
                                              }}
                                              type="button"
                                              onClick={() =>
                                                updateAdultStatus(member)
                                              }
                                              className="programInfoButton"
                                            >
                                              SEND HIPAA FORM
                                            </button>
                                          )}
                                        </div>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {childMembers.length != 0 && (
                                <TableRow className="MuiTableCellclassChid">
                                  <TableCell className="TableCell">
                                    {childMembers.map((member, index) => {
                                      return (
                                        <div>
                                          {member.firstName} {member.lastName}
                                          <br />
                                        </div>
                                      );
                                    })}
                                  </TableCell>
                                  <TableCell className="DropDown TableCell">
                                    {childMembers[0]?.hipaaStatus ===
                                    "PENDING" ? (
                                      <Sample
                                        setChild={(val) =>
                                          changeTextFieldHandler(
                                            val,
                                            adultMembers
                                          )
                                        }
                                        name={"adultSelected"}
                                        label={"Select Adult"}
                                        value={adultValue}
                                        disable={false}
                                        fieldType={"select"}
                                        helperMsg={""}
                                        List={adultMembers}
                                        // parentDetails={{ label: 'adultSelected' }}
                                        required={true}
                                      />
                                    ) : (
                                      <TableCell className="TableCell">
                                        {childMembers[0]?.authorizedBy}
                                      </TableCell>
                                    )}
                                  </TableCell>
                                  {childMembers[0]?.hipaaStatus ===
                                  "PENDING" ? (
                                    <TableCell className="TableCell">
                                      {adultSelected &&
                                      adultSelected?.email &&
                                      adultSelected?.email !== "" ? (
                                        <TableCell className="TableCell">
                                          {adultSelected?.email}
                                        </TableCell>
                                      ) : (
                                        <Sample
                                          setChild={(val) =>
                                            updateChildMail(val)
                                          }
                                          name={"adultEmail"}
                                          isValidFullName={true}
                                          reqFlag={true}
                                          label={"Email Address"}
                                          //value={adultSelected?.email || enteredAdultEmail}
                                          disable={!adultSelected}
                                          length={120}
                                          fieldType={"email"}
                                          errMsg={"Enter valid email"}
                                          helperMsg={""}
                                          // parentDetails={{ name: 'adultEmail' }}
                                        ></Sample>
                                      )}
                                    </TableCell>
                                  ) : (
                                    <TableCell className="TableCell">
                                      {adultSelected?.email}
                                    </TableCell>
                                  )}
                                  <TableCell className="TableCell">
                                    <Grid
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        width: !showText ? "280px" : "",
                                      }}
                                    >
                                      <text
                                        className={
                                          childMembers[0]?.hipaaStatus ===
                                          "PENDING"
                                            ? "statusCell pending"
                                            : childMembers[0]?.hipaaStatus ===
                                              "In Progress"
                                            ? "statusCell inProgress"
                                            : "statusCell completed"
                                        }
                                      >
                                        {childMembers[0]?.hipaaStatus.toUpperCase()}
                                      </text>
                                      <div>
                                        {childMembers[0]?.hipaaStatus !==
                                          "Completed" && (
                                          <button
                                            disabled={disableChildButton()}
                                            style={{
                                              color: disableChildButton()
                                                ? "#e3e3e3"
                                                : "#4782c4",
                                              borderColor: disableChildButton()
                                                ? "#e3e3e3"
                                                : "#4782c4",
                                              fontSize: "15px",
                                              cursor: "pointer",
                                              padding: "5px",
                                            }}
                                            type="button"
                                            onClick={() => updateChildStatus()}
                                            className="programInfoButton"
                                          >
                                            SEND HIPAA FORM
                                          </button>
                                        )}
                                      </div>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </Box>
        </div>
        {showText ? (<ProgramInfoFooter
          label={""}
          rightSectionText={Text.netwell.NEEDHELP_MSG}
        />) : null}
      </div>
      {isHippaSent && (
        <Dialog
          brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
          show={true}
          title={"Message"}
          body={() => <p>{msgBody}</p>}
          CloseIcon={true}
          buttons={[
            {
              label: "Close",
              size: "large",
              variant: "text",
              type: "button",
              className: "Close",
            } as CustomButtonProps,
          ]}
          titleBackgroundColor={{ backgroundColor: "rgb(51, 175, 176)" }}
          handleCallback={(data: any) => {
            handleCloseClick();
          }}
        />
      )}
    </StyleWrapper>
  );
};

export default HippaCard;
