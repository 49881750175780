import React, { useEffect, useState } from "react";
import { encryptMemberId } from "./handlers";
import { useLocation } from "react-router-dom";
import config from "../../config/constants/config";

const IDcard = () => {
  const [modalIdUrl, setModalIdUrl] = useState("");
  const location = useLocation();
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (location.pathname === "/HealthToolCard") showHealthToolCard();
    else showIDCard();
  }, []);
  const showIDCard = async () => {
    let token = localStorage.getItem("bearerToken");
    var response = await encryptMemberId(null);
    setModalIdUrl(
      process.env.REACT_APP_IDCARD_URL +
        `?query=${encodeURIComponent(response)}&token=${token}`
    );
  };
  const showHealthToolCard = async () => {
    let token = localStorage.getItem("bearerToken");
    var response = await encryptMemberId(null);
    setModalIdUrl(
      process.env.REACT_APP_HEALTHTOOL_URL +
        `?query=${encodeURIComponent(response)}&token=${token}`
    );
  };
  return (
    <div>
      {modalIdUrl && (
        <iframe
          style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
          src={modalIdUrl}
        ></iframe>
      )}
    </div>
  );
};

export default IDcard;
