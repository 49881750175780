const titles = Object.freeze({
  Dashboard: "Dashboard",
  Documents: "Documents",
  MyNotifications: "My Notifications",
  Announcements: "Announcements & Notices",
  ProgramInformation: "PROGRAM INFORMATION",
  myTransaction: "MY TRANSACTIONS",
  recentTransaction: "RECENT TRANSACTIONS",
  paymentInfo: "PAYMENT INFORMATION",
  healthQuestionary: "Health Questionnaire",
  hippa: "HIPAA PRIVACY AUTHORIZATION",
  myNeeds: "My Needs",
  shareRequests: "Share Requests",
  summary: "Summary",
  visitFees: "Visit Fees",
  sharingLimits: "Sharing Limits",
  enrolledMembers: "Enrolled Members",
  discountPrograms: "Discount Programs",
  consultationFees: "Consultation Fees",
  expenseLimits: "Expense Limits",
  addOns: "Add-Ons",
  currentPaymentMethod: "Current Payment Method",
  memberApps: "Member Apps",
  aiAssistant: "AI Assistant",
  memberServices: "Member services",
  contact: "Contact Information",
  uhfCuram: "UHF / Curam Wellness Center™",
  // Dashboard - contact info
  PRE_NOTIFY_SERVICE: "For Pre-notification or Customer Service call:",
  TELE_MEDICINE: "For Telemedicine call:",
  SEND_NEEDS: "Send needs to:",
  PRE_NOTIFY_CALL: "For Pre-notification call:",
  CUSTOMER_CALL: "For Customer Service call:",
  PRE_NOTIFY: "For Pre-notification:",
  YOUR_AGENT_DETAILS: "Your Agent Details:",
  MembershipCard: "Membership Card",
  HealthToolCard: "HealthTool Card",
  ViewDocuments: "VIEW DOCUMENTS",
  AiAssistantTitle: "YOUR AI-POWERED ASSISTANT",
  someThingWentWrong: "Something went wrong",
  Ok: "OK",
  Message:'Message'
});

export default titles;
