import { apiService } from "carynhealth-component-library";
import config from "../../config/constants/config";
import i18next from "i18next";

export const encryptMemberId = async (encodeString: any) => {
  let obj = {
    memberId: `memberid=${localStorage.getItem(
      "sourceid"
    )}&type=&email=${localStorage.getItem("userMail")}&lang=${localStorage.getItem("Language")}`,
  };
  console.log(i18next.dir());

  let request = {
    URL: config.URLS.baseAPI + "v1/csrportal/idencryption",
    data: obj,
    brand: config.APP_NAME,
  };
  try {
    var response = await apiService.default.post(request);
    return response.data;
  } catch (err) {
    console.log("Internal server error.");
  }
};
