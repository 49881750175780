import React, { useMemo, useState } from "react";
import { Summary } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import { styled } from "@mui/system";
import { SummaryProps } from "carynhealth-component-library/dist/cjs/components/ProgramInformation/Summary";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getPlainIData } from "../../RightTopMemberTabs/handler";
import messages from "../../../config/constants/messages";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import VideoPopup from "../../../UI/dashboard/VideoPopup";

const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
const isCuramLife = config.APP_NAME === config.BRAND_CURAMLIFE;
interface SummaryType {
  programName: ProgramName;
  headerSumary: SummaryDetails;
  shareSummary: SummaryDetails;
  changeProgram: { [key: string]: string } | null;
}

export interface SummaryDetails {
  title: string;
  subTitle: string;
  progress: number;
  met: number;
  remaining: number;
}

export interface ProgramName {
  title: string;
  subTitle: string;
}

const SummaryStyle = styled("section")(({ theme }) => {
  return {
    margin: "23px 5px 10px 3px",
    ".MuiButtonBase-root": {
      background: "initial !important",
    },
    ".pi-summary_title": {
      fontSize: 40,
    },
    ".pi-summary_description": {
      width: "auto",
    },
    ".title": {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 7,
    },
    ".subList": {
      color: "#212529",
      fontWeight: 300,
      lineHeight: 1.3,
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    ".linkView": {
      cursor: "pointer",
      color: " rgb(65, 181, 194)",
      fontWeight: "400",
      marginBottom: 26,
    },
    [theme.breakpoints.down(426)]: {
      ".pi-summary_description": {
        width: "auto",
      },
    },
  };
});

const ProgramInfoSummary: React.FC<{ data: any }> = (props) => {
  const { t,i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const setDesc = (name: string) => {
    let programName = name?.replace(/[0-9]/g, "");
    switch (programName) {
      case "UHS":
        return {
          description: t("PROGRAM_DESCRIPTION.UHSStandardPrograms"),
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-Standard-PHCS-1-6.pdf",
        };
      case "UHS SmartShare":
        return {
          description: t("PROGRAM_DESCRIPTION.UHSSmartShare"),
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Sharing-Program-Brochure-Grid-SmartShare.pdf",
        };
      case "THS":
        return {
          description: t("PROGRAM_DESCRIPTION.UHSThriveHealthShare"),
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Thrive-Program-Grid.pdf",
        };
      case "HL":
        return {
          description: t("PROGRAM_DESCRIPTION.UHSHealthyLife"),
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Healthy-Life-Program-Grid.pdf",
        };
      case "ES":
        return {
          description: t("PROGRAM_DESCRIPTION.UHSEssentialShare"),
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-EssentialShare-Program-Grid.pdf",
        };
      case "CHS":
        return {
          description: t("PROGRAM_DESCRIPTION.UHSCommunityHealthShare"),
          URL: "https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-Community-HealthShare-Program-Grid.pdf",
        };
      default:
        return { description: "", URL: "" };
    }
  };

  const prgrmData = useMemo(() => {
    const { data } = props;
    const planID: any = getLocalStorage("planId");
    const removeHeader =
      ["smartshare50", "smartshare25"].includes(
        localStorage.getItem("NETWORK_NAME") || ""
      ) ||
      isNetwell ||
      isCuramLife;
    getPlainIData(planID);
    const removeShare = !data.acsmmet && !data.acsmremain && !data.acsmTotal;
    const removeChangeProgram = isNetwell || isCuramLife;
    return {
      programName: isCuramLife
        ? {
            title: data[0]?.packageName,
            subTitle: "",
            description: t(data[0]?.packageName),
          }
        : {
            title: data.programname,
            subTitle: data.programname2,
            description: setDesc(data.programname).description,
            URL: setDesc(data.programname).URL,
          },
      headerSumary: removeHeader
        ? undefined
        : {
            title: t("summary"),
            subTitle: t("NonSharableAmount"),
            progress: data.barRange,
            met: data.nsamet,
            remaining: data.nsaremain,
            style: { backgroundColor: "rgba(235,87,87,.5)" },
            indicatorColor: "#eb5757",
          },
      shareSummary: removeShare
        ? undefined
        : {
            title: "",
            subTitle: isNetwell ? t("NonSharableAmount") : t("AnnualCoShare"),
            progress: isNaN(data.barRangeYellow) ? 0 : data.barRangeYellow,
            met: data.acsmmet,
            remaining: data.acsmremain,
            style: { backgroundColor: "rgba(254,196,101,.5)" },
            indicatorColor: "#fec465",
          },
      changeProgram: removeChangeProgram
        ? null
        : {
            url: config.URLS.baseAPI + config.URLS.caseCreation,
            Subject: localStorage.getItem("sourceid") || "",
            Origin: "External",
            External_Application_Name__c: "Member Portal",
            Status: "New",
            Type: "Account Update",
            SuppliedEmail: localStorage.getItem("userMail") || "",
            Description: "Request to change",
          },
    };
  }, [props,i18n.language]);

  const handleOpen = () => {
    setOpen(false);
  };

  return (
    <SummaryStyle id="pi-summary">
      <Summary {...(prgrmData as SummaryProps)} />
      {isCuramLife && (
        <div className="linkView" onClick={() => setOpen(true)}>
          {t("ViewOnboardingVideo")}
        </div>
      )}
      {open && <VideoPopup isOpen={handleOpen} />}
      {isCuramLife && <div className="title">{t("pakageBenefits")}</div>}
      {isCuramLife &&
        props &&
        props?.data[0]?.packageBenefits?.map((value: any, index: any) => (
          <div className="subList" key={index}>
            {t(value)}
            {index !== props.data[0].packageBenefits.length - 1 ? ", " : ""}
          </div>
        ))}
      
    </SummaryStyle>
  );
};

export default ProgramInfoSummary;
