import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    ".popupContant": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    ".web_checkoutboldtext": {
      fontSize: "1.2vw",
      color: "black",
      lineHeight: 1.3,
      fontWeight: "500",
    },
    ".web_tooltip_2nd_text": {
      fontSize: "1vw",
      height: "1.2vw",
      color: "black",
      fontWeight: "400",
      paddingLeft: "26px",
      paddingRight: "14px",
      paddingBottom: "19px",
      lineHeight: 1.5,
      a: {
        color: "inherit",
        textDecoration: "none",
        ":hover": {
          color: "#fff",
        },
      },
    },
    ".yellow_popup_caption_button": {
      position: "absolute",
      borderRadius: "20px",
      top: "60%",
      padding: ".8em",
      backgroundColor: "#eb5757",
      color: "#fff",
      fontWeight: "500",
      fontSize: ".7rem",
      cursor: "pointer",
      border: "none",
      textAlign: "center",
      boxShadow: "0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)",
    },
    ".popupDiv": {
      top: "6px",
      position: "relative",
    },
    [theme.breakpoints.down(770)]: {
      ".web_checkoutboldtext": {
        fontSize: 16,
        alignSelf: "flex-start",
        marginLeft: 15,
      },
      ".web_tooltip_2nd_text": {
        fontSize: 16,
        alignSelf: "flex-start",
        paddingLeft: 6,
      },
      ".yellow_popup_caption_button": {
        top: "68%",
      },
    },
    [theme.breakpoints.down(430)]: {
      ".web_tooltip_2nd_text": {
        fontSize: 16,
        alignSelf: "flex-start",
        paddingLeft: 0,
      },
    },
  };
});
