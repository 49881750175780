import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { IdleTimerProvider } from "react-idle-timer";
import axios from "axios";
import { Dialog, LoaderUI } from "carynhealth-component-library";
import config from "../../config/constants/config";
import { useTranslation } from "react-i18next";

export const IdleSessionHandle: React.FC<any> = (props) => {
  const timeoutInMinutes = 15;
  const timeInMilliseconds = 1000 * 60 * timeoutInMinutes;
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const { t } = useTranslation();
  
  useEffect(() => {
    let checkForSessionIntervalTime = 1000 * 60 * 2;
    let checkForSessionInterval = setInterval(() => {
      if (sessionStorage.getItem("USER_ACTIVE_SESSION") === "true") {
        axios
          .get(
            process.env.REACT_APP_NEW_BASE_URL_NOTIFICATION +
              "getSessionDetails/" +
              localStorage.getItem("userMail") +
              "/" +
              sessionStorage.getItem("TYPE")
          )
          .then((response) => {
            console.log(response);
            if (response.data.code === 200) {
              if (!response.data.response.activeflag) {
                appLogout();
              }
            } else {
              appLogout();
            }
          });
      } else if (sessionStorage.getItem("USER_ACTIVE_SESSION") === "false") {
        appLogout();
      } else {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            console.log("user available", user);
          })
          .catch((err) => {
            console.log("no session available", err);
            appLogout();
          });
      }
    }, checkForSessionIntervalTime);
    return () => clearInterval(checkForSessionInterval);
  }, []);

  const handleClose = () => {
    sessionStorage.clear();
    setOpen(false);
    setLoader(false);
    props?.onCallback?.("SignOut");
  };

  const handleOnIdle = () => {
    appLogout();
    console.log("app is idle now");
  };

  const appLogout = () => {
    setOpen(true);
    if (sessionStorage.getItem("USER_ACTIVE_SESSION") === "true") {
      let obj = {
        email: localStorage.getItem("userMail"),
        type: sessionStorage.getItem("TYPE"),
        activeflag: false,
      };
      axios
        .post(
          process.env.REACT_APP_NEW_BASE_URL_NOTIFICATION + "updateSession",
          obj
        )
        .then((response) => {
          localStorage.clear();
          sessionStorage.clear();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Auth.signOut();
      localStorage.clear();
      sessionStorage.clear();
    }
  };
  return (
    <div>
      {loader ? <LoaderUI /> : null}
      <IdleTimerProvider
        timeout={timeInMilliseconds}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <Dialog
        brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
        buttons={[
          {
            label: "Okay",
            onClick: handleClose,
          },
        ]}
        handleCallback={handleClose}
        show={open}
        title="Session Expired"
        key="Session Expired"
        body={() => (
          <p>{t('sessionExpired')}</p>
        )}
      />
    </div>
  );
};
