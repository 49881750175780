import config from "../../../config/constants/config";
import messages from "../../../config/constants/messages";
import { apiService } from "carynhealth-component-library";
import { CuramLifeCardobject } from ".";

export const getCuramLife = async (obj: CuramLifeCardobject) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getCuramLife,
      brand: config.APP_NAME,
      data: obj,
    };
    const { data: apiResponse } = await apiService.default.post(request);
    return {
      apiResponse,
      errorMessage: apiResponse.response ? "" : messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};
