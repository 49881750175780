import { Grid } from "@mui/material";
import React from "react";
import { ComponentWrapper } from "./style";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SampleTextField from "../TextField/sampleTextField";
import { useTranslation } from "react-i18next";

export const LifeStyleQuestion = (props: any) => {
  const { t } = useTranslation();
  const [questionData, setQuestionData] = React.useState<any[]>([]);

  React.useEffect(() => {
    getQuestionData();
  }, []);

  const getQuestionData = () => {
    props.questionList[0].activeFlag = false;
    const filterValue = "LIFESTYLE";
    const result = props.questionList.filter((val: any) =>
      val.questionTypCode.includes(filterValue)
    );
    setQuestionData(result);

    checkDisable(result);
  };
  const checkDisable = async (result: any) => {
    if (!props?.ViewMode) {
      let disableProp = await setDisableNext(result);
      props.disableNext(disableProp);
    }
  };
  const setDisableNext = (result: any) => {
    let allRsp = result.some((x: any) => x.response == "");
    if (!allRsp) {
      if (result[0].response.toLowerCase() == "yes") {
        let relatedrep = result[0].reltdQstnID.some(
          (x: any) => !x.response || x.response == ""
        );
        if (relatedrep) {
          return true;
        }
      }
      return false;
    }
    return true;
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    questionData[index].response = (event.target as HTMLInputElement).value;
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };

  const nameChangeHandler = (val: any, index: number) => {
    questionData[0].reltdQstnID[index].response = val;
    questionData[0].reltdQstnID[index].id =
      questionData[0].reltdQstnID[index].id || "";
    setQuestionData([...questionData]);
    checkDisable(questionData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={7}>
        <div className="custom-subtitle">{t(props?.instData?.title)}</div>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {t(questionData[0]?.questionDesc)}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionData[0]?.response}
                onChange={(event) => handleChange(event, 0)}
              >
                {questionData[0]?.options.map((item: any) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.option}
                    control={
                      <Radio
                        disabled={props.ViewMode}
                        checked={questionData[0]?.response === item.option}
                      />
                    }
                    label={item.option}
                    disabled={props.ViewMode}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {questionData[0]?.response === "Yes" && (
              <Grid container className="alcoholDiv" spacing={2}>
                <Grid item xs={12}>
                  <SampleTextField
                    setChild={(val) => nameChangeHandler(val, 0)}
                    reqFlag={true}
                    label={questionData[0]?.reltdQstnID[0]?.questionDesc}
                    value={questionData[0]?.reltdQstnID[0]?.response}
                    disable={props.ViewMode}
                    length={50}
                    errMsg={questionData[0]?.reltdQstnID[0]?.questionDesc}
                    helperMsg={""}
                    fieldType="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <SampleTextField
                    setChild={(val) => nameChangeHandler(val, 1)}
                    reqFlag={true}
                    label={questionData[0]?.reltdQstnID[1]?.questionDesc}
                    value={questionData[0]?.reltdQstnID[1]?.response}
                    disable={props.ViewMode}
                    length={50}
                    errMsg={questionData[0]?.reltdQstnID[1]?.questionDesc}
                    helperMsg={""}
                    fieldType="text"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={5}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {questionData[1]?.questionDesc}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionData[1]?.response?.toString()}
                onChange={(event) => handleChange(event, 1)}
              >
                {questionData[1]?.options.map((item: any) => (
                  <FormControlLabel
                    value={item.option}
                    control={
                      <Radio
                        disabled={props.ViewMode}
                        checked={questionData[1]?.response === item.option}
                      />
                    }
                    label={item.option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={7}>
            <FormControl>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                className="form-label"
              >
                {questionData[2]?.questionDesc}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={questionData[2]?.response.toString()}
                onChange={(event) => handleChange(event, 2)}
              >
                {questionData[2]?.options.map((item: any) => (
                  <FormControlLabel
                    value={item.option}
                    control={
                      <Radio
                        disabled={props.ViewMode}
                        value={item.option}
                        checked={questionData[2]?.response === item.option}
                      />
                    }
                    label={item.option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={5}>
        <Grid item xs={10}>
          <div className="custom-subtitle" style={{ marginTop: "10px" }}>
            {" "}
            {t('whyNeedThis')}
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: t(props.instData?.description),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
