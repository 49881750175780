import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Authentication } from "carynhealth-component-library";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthenticationType } from "carynhealth-component-library/dist/cjs/components/Authentication/interface";
import { authenticationProps } from "./data";
import StyleWrapper from "./style";
import config from "../../config/constants/config";
import { getRoutePaths } from "../../config/constants/routePaths";
import { AuthContext, AuthInfoType } from "../../context/AuthenticateContext";
import i18n from "../../i18n/i18n";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getMemberDetailsById } from "../../UI/dashboard/handler";
import { useTranslation } from "react-i18next";

const brand = config.APP_NAME;

const names = [
  { name: "English", value: "en" },
  { name: "Spanish", value: "es" },
];

const Login: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const auth = useContext(AuthContext);
  const [currentLanguage, setCurrentLanguage] = useState<"en" | "es">("en");

  const authenticate = useCallback(
    async (authenticateInfo: { authenticatedUser: AuthInfoType }) => {
      await getMemberDetails();
      auth.updateAuthenticateInfo(
        { ...authenticateInfo?.authenticatedUser, isAuthenticated: true } ||
          auth.authenticatedInfo
      );

      navigate(getRoutePaths.dashboard);
    },
    []
  );

  const getMemberDetails = async () => {
    const memberId = getLocalStorage("member-id") || "CURM100061";
    let data = await getMemberDetailsById(memberId);
    console.log(data);
    setLocalStorage(
      "curamLifeMemberPackages",
      JSON.stringify(data.curamLifeMemberPackages)
    );
    setLocalStorage("packageName", data.curamLifeMemberPackages[0].packageName);
    setLocalStorage("fullName", `${data.firstName} ${data.lastName}`);
    setLocalStorage("memebr-details", JSON.stringify(data));
  };

  const authenticateDetails = useMemo(
    () => authenticationProps({ isSmallScreen }),
    [isSmallScreen]
  );

  useEffect(() => {
    let lang: any = getLocalStorage("Language");
    if (lang) {
      setCurrentLanguage(lang);
    } else {
      if (i18n.language === "en-US") {
        setLocalStorage("Language", "en");
        setCurrentLanguage('en');
      } else {
        setLocalStorage("Language", i18n.language);
        lang = i18n.language
        setCurrentLanguage(lang);
      }
    }
  }, []);

  const onOptionChangeHandler = (event: SelectChangeEvent) => {
    let lang : any = event.target.value as string
    setLocalStorage("Language", lang);
    i18n.changeLanguage(lang);
    setCurrentLanguage(lang);
  };

  return (
    <StyleWrapper className={`login-${brand}`}>
      <div className="languageSelect">
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">
          {t('SelectYourLanguage')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentLanguage}
            label={t('SelectYourLanguage')}
            onChange={onOptionChangeHandler}
          >
            {names.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Authentication
        {...(authenticateDetails as AuthenticationType)}
        authCallback={authenticate}
      />
    </StyleWrapper>
  );
};

export default Login;
