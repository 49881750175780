import { apiService } from "carynhealth-component-library";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";

export const getPlainIData = (planId: string) => {
  let id = ["10051", "10052", "10053", "10061", "10062", "10063"];
  return id.includes(planId);
};

export const getIdCardDetails = async (email: string) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getMemberIdCard,
      brand: config.APP_NAME,
      data: { email: email },
    };
    const { data: apiResponse } = await apiService.default.post(request);
    if (localStorage.getItem("SOURCE") === "NEO") {
      localStorage.setItem(
        "Member_EMPID",
        apiResponse?.memberIdCardList?.[0]?.memberId || ""
      );
    }
    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const getRxSimpleShareData = async (data: any) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getCSRAddOns,
      brand: config.APP_NAME,
      data: data,
    };
    const { data: apiResponse } = await apiService.default.post(request);
    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};
