import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    // UHF
    paddingBottom: 15,
    "&.login-UHF": {
      ".languageSelect": {
        display: "grid",
        justifyContent: "end",
      },
      backgroundColor: "white",
      "#banner": {
        position: "relative",
        maxWidth: 1017,
        overflow: "hidden",
        margin: "0px auto",
        ".banner_hero-banner": {
          height: 400,
          marginTop: 15,
        },
        ".banner_brand-logo": {
          width: "auto",
          position: "absolute",
          right: 0,
          height: 68,
        },
      },
    },

    "&.login-NETWELL": {
      backgroundColor: "white",
      "#banner": {
        overflow: "hidden",
        ".banner_hero-banner": {
          height: "45vh",
          objectPosition: "top",
          objectFit: "cover",
        },
        ".banner_brand-logo": {
          margin: "47px 10px 19px 61px",
          width: "13%",
        },
      },
    },
    "&.login-CURAMLIFE": {
      "section form input": {
        fontSize: "1rem",
      },
      ".languageSelect": {
        display: "grid",
        justifyContent: "end",
        height:20,
        ".MuiFormControl-root": {
          width: 200,
          marginTop: 10,
        },
      },
      backgroundColor: "white",
      "#banner": {
        overflow: "hidden",
        ".banner_hero-banner": {
          height: 400,
          marginTop: 15,
        },
        ".banner_brand-logo": {
          margin: "0px 10px 0px 61px",
          width: "15.71%",
        },
      },
    },
    ".Popup-content": {
      ".signatureInput": {
        ".MuiRadio-root": {
          backgroundColor: "transparent",
          color: "#420045",
        },
        ".MuiFormControlLabel-label": {
          fontSize: "13px !important",
        },
      },
      ".sigCanvas": {
        backgroundColor: "#e1e1e3",
        marginBottom: 10,
        marginTop: 8,
      },
      ".FullNameInput": {
        div: {
          width: "100%",
        },
        " .MuiFilledInput-root": {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
          borderTopLeftRadius: "4px !important",
          borderTopRightRadius: "4px !important",
        },
        ".MuiFormControl-root": {
          width: "100%",
        },
        ".MuiSelect-select,.MuiInputBase-input ": {
          padding: "25px 12px 8px 12px",
          width: "485px",
        },
      },
      ".myStyle": {
        fontFamily: "Kalam",
        width: "100%",
        minHeight: 100,
        height: "max-content",
        background: "#e1e1e3",
        padding: 10,
        marginBottom: 10,
        marginTop: 8,
      },
      ".forCuramlife": {
        height: "auto !important",
      },
      ".Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12) !important",
      },
      button: {
        backgroundColor: "#420045",
      },
    },
    ".acceptBtn": {
      width:'100%',
      display: "flex",
      justifyContent: "end",
      ".Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26) !important",
        border: "1px solid rgba(0, 0, 0, 0.26)",
      },
      button: {
        color: "#420045",
        border: "1px solid #420045",
      },
    },
    // Responsive

    // [theme.breakpoints.down(768)]: {

    // },

    [theme.breakpoints.down(768)]: {
      // UHF
      "&.login-UHF": {
        "#banner": {
          paddingTop: 60,
          ".banner_brand-logo": {
            position: "relative !important",
            margin: "0px auto",
            width: "65%",
            height: 74,
          },
          ".banner_hero-banner": {
            marginTop: 10,
            padding: 15,
            height: "auto !important",
          },
        },
      },
      // NETWELL
      "&.login-NETWELL": {
        "#banner": {
          paddingTop: 60,
          ".banner_brand-logo": {
            margin: "10px auto",
            width: "50%",
          },
        },
      },
      "&.login-CURAMLIFE": {
        ".languageSelect": {
          
          ".MuiFormControl-root": {
            marginTop: 40,
            zIndex:1
          },
        },
        "#banner": {
          paddingTop: 60,
          ".banner_brand-logo": {
            margin: "10px auto",
            width: "50%",
          },
        },
      },
    },
  };
});
